import { useState } from 'react';
// @mui
import { MenuItem, Stack } from '@mui/material';
// locales
import useLocales from 'modules/shared/hooks/useLocales';
// components
import Image from 'modules/shared/components/Image/Image';
// import { IconButtonAnimate } from 'modules/shared/components/animate';
import MenuPopover from 'modules/shared/components/MenuPopover/MenuPopover.component';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  // const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
  //   setOpenPopover(event.currentTarget);
  // };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang: string) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  return (
    <>
      {/* <IconButtonAnimate
        // onClick={() => ()}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
        }}
      > */}
        <Image disabledEffect src={currentLang.icon} alt={currentLang.label} />
      {/* </IconButtonAnimate> */}

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 180 }}>
        <Stack spacing={0.75}>
          {allLangs.map(option => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
