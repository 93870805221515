import { Meta } from 'modules/shared/interfaces/shared.interface';
import { subtractMonths, addMonths } from 'modules/requests/utils';
import { TypeModal } from 'modules/shared/components/modal/Modal.component';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { QueryParamsConfigurationEntity } from 'modules/setup-config/interfaces';
// yup
import * as yup from 'yup';
import { isDNIValid } from 'modules/employees/utils';

export interface RequestFormInterface {
  sendForm: boolean;
  loading: boolean;
  mode: any;
  handleAction: (action: string) => Promise<void>;
  availableActions: any;
}
export interface RequestStatusInterface {
  request_status_id: string;
  date: string;
  generatedBy: OperatorInterface[];
  status: StatusInterface;
  statusId: number;
}

export interface workFlowStepsInterface {
  workFlow_step_id: number;
  workflowId: number;
  stepId: number;
  next: NextInterface[];
}

export interface NextInterface {
  id: number;
  name: string;
  workFlowSteps: workFlowStepsInterface[];
}

export const createRequestStatus: StatusInterface = {
  id: 1,
  name: 'Pendiente',
};

export const approveRequestStatus: StatusInterface = {
  id: 2,
  name: 'Aceptada',
};

export const rejectRequestStatus: StatusInterface = {
  id: 3,
  name: 'Rechazada',
};

export const cancelRequestStatus: StatusInterface = {
  id: 4,
  name: 'Anulada',
};

export type RequestInterface = {
  id?: string;
  license: LicenseInterface;
  start_date: string | null;
  end_date: string | null;
  start_hour: string | null;
  end_hour: string | null;
  file?: FileList | null;
  operator: OperatorInterface;
  loadUser?: string;
  personInCharge?: OperatorInterface;
  notes: string;
  status?: StatusInterface;
  requestStatus: RequestStatusInterface[];
  currentStatus?: RequestStatusInterface;
  updated_at?: string;
  supporting_document: string;
  next?: NextInterface;
  creator?: OperatorInterface;
  supervisor?: SupervisorInterface;
  //
  kinship?: kinshipTypes; // parentesco wf3
  diagnosis?: string; // dianostico wf4
  date_of_birth?: string; // fecha de nacimiento wf5
  partner_document?: string; // cónyuge wf6
  maternity_license_type?: maternitylicenseTypes;
  partner_name?: string; // matrimonio cónyuge wf7
  reason?: string; // motivo wf8
  formal_comunication_sent?: boolean; // suspension wf9
  acknowledgement?: boolean; // suspension wf9
  //
  // percentage_to_pay: number;
};

export type CreateOrEditRequestInterface = {
  id?: string;
  license: LicenseInterface;
  start_date: string | null;
  end_date: string | null;
  file?: FileList | null;
  fileRequired?: boolean;
  operator: OperatorInterface;
  notes: string;
  supervisor?: SupervisorInterface;
  start_hour?: string | null; // wf6 new
  end_hour?: string | null; // wf6 new
  status?: StatusInterface; // todo: always pending?
  kinship?: kinshipTypes; // parentesco wf3
  diagnosis?: string; // dianostico wf4
  date_of_birth?: string; // fecha de nacimiento wf5
  partner_document?: string; // cónyuge wf6
  maternity_license_type?: maternitylicenseTypes;
  partner_name?: string; // matrimonio cónyuge wf7
  reason?: string; // motivo wf8
  formal_comunication_sent?: boolean; // suspension wf9
  acknowledgement?: boolean; // suspension wf9
};

export enum kinshipTypes {
  SPOUSE = 'SPOUSE',
  MOTHER_FATHER = 'MOTHER_FATHER',
  DAUGHTER_SON = 'DAUGHTER_SON',
  SISTER_BROTHER = 'SISTER_BROTHER',
  IN_LAWS = 'IN_LAWS',
  GRANDPARENTS = 'GRANDPARENTS',
}

export enum maternitylicenseTypes {
  MATERNITY = 'maternity',
  EXCEDENCE = 'excedence',
}

export const maternityOptions = [
  { id: maternitylicenseTypes.MATERNITY, name: 'Maternidad' },
  { id: maternitylicenseTypes.EXCEDENCE, name: 'Excedencia' },
];

export const kinshipOptions = [
  { id: kinshipTypes.DAUGHTER_SON, name: 'Cónyuge' },
  { id: kinshipTypes.MOTHER_FATHER, name: 'Padre/Madre' },
  { id: kinshipTypes.SISTER_BROTHER, name: 'Hermano/Hermana' },
  { id: kinshipTypes.DAUGHTER_SON, name: 'Hijo/Hija' },
  { id: kinshipTypes.IN_LAWS, name: 'Suegro/Suegra' },
  { id: kinshipTypes.GRANDPARENTS, name: 'Abuelo/Abuela' },
];

export type WorkflowOneEntity = Pick<
  CreateOrEditRequestInterface,
  'id' | 'license' | 'start_date' | 'end_date' | 'file' | 'operator' | 'notes' | 'status'
>;

export const emptySupervisorModel: SupervisorInterface = {
  id: '',
  names: '',
  surenames: '',
  is_admin: false,
  is_manager: false,
  is_both: false,
  team_names: '',
  full_name: '',
};

export const emptyOperatorModel: OperatorInterface = {
  id: '',
  names: '',
  surenames: '',
  dni: '',
  user_id: '',
};

export const emptyRequestModel: RequestInterface = {
  id: '',
  license: { id: '', name: '' },
  start_date: null,
  end_date: null,
  start_hour: null,
  end_hour: null,
  file: null,
  operator: { id: '', names: '', surenames: '' },
  loadUser: '',
  personInCharge: { id: '', names: '', surenames: '' },
  supervisor: undefined,
  notes: '',
  requestStatus: [],
  supporting_document: '',
  //
  partner_name: '',
};
export interface LicenseInterface {
  id: string;
  name: string;
  active?: boolean;
  workFlow?: WorkFlowInterface;
  configuratorlicense?: QueryParamsConfigurationEntity;
}

export const initialLicenseSelected: LicenseInterface = {
  id: '',
  name: '',
};

export interface WorkFlowInterface {
  id: number;
  name: string;
  previousAttachment: boolean;
}
export const emptyRequestModelForm: CreateOrEditRequestInterface = {
  id: '',
  license: {
    id: '',
    name: '',
    active: true,
  },
  start_date: null,
  end_date: null,
  start_hour: null,
  end_hour: null,
  file: null,
  operator: { id: '', names: '', surenames: '' },
  notes: '',
  status: { id: 1, name: 'Solicitud creada' },
  supervisor: undefined,
  kinship: undefined,
  partner_document: undefined,
  diagnosis: undefined,
  date_of_birth: undefined,
  maternity_license_type: undefined,
  partner_name: undefined,
  reason: undefined,
  formal_comunication_sent: undefined,
  acknowledgement: undefined,
};

export const emptyGremialPermissionForm: WorkflowOneEntity = {
  id: '',
  license: { id: '', name: '' },
  start_date: null,
  end_date: null,
  file: null,
  operator: { id: '', names: '', surenames: '' },
  notes: '',
  status: { id: 1, name: 'Solicitud creada' },
};

export interface StatusInterface {
  id: number | null;
  name: string;
  requiresAttachment?: boolean;
}

export interface OperatorInterface {
  id: string;
  name?: string;
  names?: string;
  surenames?: string;
  dni?: string;
  user_id?: string;
}

export interface SupervisorInterface extends OperatorInterface {
  is_admin: boolean;
  is_manager: boolean;
  is_both: boolean;
  team_names: string;
  full_name: string;
}

// api interfaces

export interface getRequestsResponseInterface {
  data: RequestInterface[];
  meta: Meta;
}

// filters

export interface FilterDateInterface {
  label: string;
  value: string;
}

export interface FilterIdsInterface {
  licenses: Array<string>;
  dates: FilterDateInterface;
  statuses: Array<string>;
  operators: Array<string>;
  creators: Array<string>;
  onBehalfs: Array<string>;
}

export interface FiltersInterface {
  column: string;
  condition: string;
  value: string;
}

export interface NewFilterIdsInterface {
  licenses: Array<string>;
  dates: FilterDateInterface;
  requests_status: Array<string>;
  operators: Array<string>;
  justify_status: Array<string>;
}

export enum TypeFilters {
  LICENSES = 'Tipo de novedad',
  OPERATORS = 'Empleado',
  DATES = 'Fecha',
  REQUEST_STATUS = 'Estado de la novedad',
  JUSTIFY_STATUS = 'Estado del certificado',
}

export interface FilterQueryObjectInterface {
  typeLicenses?: string;
  start_date?: string;
  end_date?: string;
  status?: string;
  page?: number;
  take?: number;
  operators?: string;
  creators?: string;
  onBehalfs?: string;
  section?: string;
  employeeId?: string;
}

// enums

export enum ConfigTabDisplay {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum typeOfSaves {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum RequestsViews {
  MISNOVEDADES = 'misNovedades',
  REVISIONES = 'revisiones',
  CARGADAS = 'cargadas',
}

export interface RenderFormInputInterface {
  workflowId: number;
}

export const RequestsStatuses = {
  REQUESTS_APPROVED: 'Solicitud aprobada',
  REQUEST_APPROVED_JUSTIFIED: 'Solicitud aprobada justificada',
  REQUEST_REVIEW: 'Revisión de solicitud',
  REQUEST_CANCELED: 'Solicitud anulada',
  JUSTIFICATION_ON_REVIEW: 'Justificativo en revisión',
  JUSTIFICATION_PEDING: 'Justificativo pendiente',
  REQUEST_APPROVED_NOT_JUSTIFIED: 'Solicitud aprobada injustificada',
  REQUESTS_REJECTED: 'Solicitud rechazada',
  REQUESTS_CREATED: 'Solicitud creada',
  JUSTIFICATION_REJECTED: 'Justificativo rechazado',
  JUSTIFICATION_APPROVED: 'Justificativo aprobado',
  JUSTIFICATION_LOAD: 'Carga de justificativo',
};

export interface AvailableActions {
  edit: boolean;
  delete: boolean;
  cancel: boolean;
  create: boolean;
  uploadFile: boolean;
}

export enum ValidationMessages {
  REQUIRED = 'Campo requerido',
  LONG50 = 'El valor excede el largo máximo (50 caracteres)',
  LONG100 = 'El valor excede el largo máximo (100 caracteres)',
  LONG200 = 'El valor excede el largo máximo (200 caracteres)',
  LONG400 = 'El valor excede el largo máximo (400 caracteres)',
  LINE_JUMP = 'No se permiten saltos de línea',
  NOT_SPECIAL_CHARS = 'No se permiten caracteres especiales ($, %, #...)',
}

// const bulletsText = ['Buena calidad, enfocada y legible.', 'Formato PDF, JPG o PNG.', 'Peso máximo de 25MB.'];
// const validFileTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
// const errorMessage = 'Este campo es obligatorio';
export const dateMinError = 'La antigüedad no puede ser mayor a 6 meses';
export const startDateMaxError = 'La fecha de inicio no puede ser mayor a 2 años';
export const endDateMaxError = 'La fecha de fin no puede ser mayor a 4 años';
export const dateTypeError = 'Ingrese una fecha válida';
export const hourTypeError = 'Ingrese una hora válida';
export const dateEndError = 'La fecha de fin no puede ser anterior a la fecha de inicio';
export const hourEndError = 'La hora de finalización no puede ser anterior a la hora de inicio.';
export const requiredError = 'Este campo es requerido';
// const statusOptions = [createRequestStatus, approveRequestStatus, rejectRequestStatus];
export const minDate = subtractMonths(6);
export const maxStartDate = addMonths(24);
export const maxEndDate = addMonths(48);

const FIELD_INVALID_FORMAT = 'Formato inválido';
const FIELD_MAX_LENGTH = 'El valor supera el largo máximo ';

export const validationSchema = yup.object().shape({
  notes: yup
    .string()
    .required(requiredError)
    .max(400, ValidationMessages.LONG400)
    .test('notSpecialChars', ValidationMessages.NOT_SPECIAL_CHARS, value =>
      value ? /^[.a-zA-ZÀ-ÿ0-9,¡!¿?()"'/ \u00f1\u00d1]*$/.test(value) : true,
    )
    .test('notEnter', ValidationMessages.LINE_JUMP, value => (value ? /^[^#\r\n].*/.test(value) : true)),
  start_date: yup
    .date()
    .required()
    .typeError(dateTypeError)
    .min(minDate, dateMinError)
    .max(maxStartDate, startDateMaxError),
  end_date: yup
    .date()
    .required()
    .typeError(dateTypeError)
    .max(maxEndDate, endDateMaxError)
    .test('endDateTest', dateEndError, function(value: any) {
      return value && this.parent.start_date <= value;
    }),
  operator: yup
    .object({
      id: yup.string(),
      names: yup.string(),
      surenames: yup.string(),
    })
    .default({ id: '', names: '', surenames: '' })
    .nullable()
    .required(requiredError),
  supervisor: yup
    .object({
      id: yup.string(),
      names: yup.string(),
      surenames: yup.string(),
    })
    .default(undefined)
    .required(requiredError),
});

export const validationSchemaWf3 = yup.object().shape({
  ...validationSchema.fields,
  kinship: yup.string().required(requiredError),
});

export const validationSchemaWf4 = yup.object().shape({
  ...validationSchema.fields,
  diagnosis: yup
    .string()
    .required(requiredError)
    .max(100, ValidationMessages.LONG100),
});

export const validationSchemaWf5 = yup.object().shape({
  ...validationSchema.fields,
  maternity_license_type: yup.string().required(requiredError),
  date_of_birth: yup
    .date()
    .required(requiredError)
    .typeError(dateTypeError),
});

export const validationSchemaWf6 = yup.object().shape({
  ...validationSchema.fields,
  end_hour: yup
    .date()
    .required(requiredError)
    .typeError(hourTypeError)
    .test('hourEndTest', hourEndError, function(value) {
      const { start_hour } = this.parent;
      if (!start_hour || !value) {
        return true;
      }
      return start_hour <= value;
    }),

  start_hour: yup
    .date()
    .required(requiredError)
    .typeError(hourTypeError),
});

export const validationSchemaWf7 = yup.object().shape({
  ...validationSchema.fields,
  partner_name: yup
    .string()
    .required(requiredError)
    .max(50, ValidationMessages.LONG50),
  partner_document: yup
    .string()
    .test('dniFormat', FIELD_INVALID_FORMAT, (value: any) => isDNIValid(value || ''))
    .max(8, `${FIELD_MAX_LENGTH} (${8} caracteres).`),
});

export const validationSchemaWf8 = yup.object().shape({
  ...validationSchema.fields,
  reason: yup
    .string()
    .required(requiredError)
    .max(100, ValidationMessages.LONG100),
});

export const validationSchemaWf9 = yup.object().shape({
  ...validationSchema.fields,
  formal_comunication_sent: yup.boolean(),
  acknowledgement: yup.boolean(),
});

export interface DataModalInterface {
  isOpenModal: boolean;
  title: string;
  content: string;
  loadingBtnConfirm: boolean;
  typeModal: TypeModal;
  textBtnConfirm: string;
  hasError: boolean;
}

export type QuickActionsModalsTypes = 'delete' | 'update';

export interface RequestsContextType {
  workflowsFormRef: RefObject<HTMLButtonElement>;
  WorkflowsValidateForm: () => void;
  dataModal: DataModalInterface;
  setDataModal: Dispatch<SetStateAction<DataModalInterface>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  handleSave: (form: CreateOrEditRequestInterface, type: typeOfSaves) => void;
  selectedLicense: LicenseInterface;
  setSelectedLicense: Dispatch<SetStateAction<LicenseInterface>>;
  openCancelModal: () => void;
  closeCancelModal: () => void;
  openDeleteModal: () => void;
  selectedLicenseId: LicenseInterface | string;
  setSelectedLicenseId: Dispatch<SetStateAction<LicenseInterface | string>>;
  onChangeSelectedModalOptions: (e: SelectChangeEvent<unknown>) => void;
  prevSelectedLicense: string;
  setPrevSelectedLicense: Dispatch<SetStateAction<string>>;
  openModalToCreateNewRequest: boolean;
  setOpenModalToCreateNewRequest: Dispatch<SetStateAction<boolean>>;
  openNewRequestModal: () => void;
  operators: OperatorInterface[];
  setOperators: Dispatch<SetStateAction<OperatorInterface[]>>;
  workflowId: number;
  setWorkflowId: Dispatch<SetStateAction<number>>;
  setWorkflowForNewRequests: (license: LicenseInterface) => void;
  //filters
  filters: FiltersInterface;
  setFilters: Dispatch<SetStateAction<FiltersInterface>>;
  defaultValuesLoaded: boolean;
  setDefaultValuesLoaded: Dispatch<SetStateAction<boolean>>;
  getDefaultFilters: () => void;
  queryParams: FilterQueryObjectInterface;
  setQueryParams: Dispatch<SetStateAction<FilterQueryObjectInterface>>;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (value: number) => void;
  approveSelectedRequests: () => void;
  rejectSelectedRequests: () => void;
  selectedRequests: string[];
  setSelectedRequests: Dispatch<SetStateAction<string[]>>;
  onSelectRow: (id: string) => void;
  onSelectAllRows: (checked: boolean, cb: () => void) => void;
  areAllRequestsChecked: boolean;
  setAreAllRequestsChecked: Dispatch<SetStateAction<boolean>>;
  isConfirmQuickActionModalOpen: boolean;
  setIsConfirmQuickActionModalOpen: Dispatch<SetStateAction<boolean>>;
  handleConfirmQuickAction: () => void;
  currentModalType: QuickActionsModalsTypes;
  setCurrentModalType: Dispatch<SetStateAction<QuickActionsModalsTypes>>;
  getRequests: () => void;
  isSupervisor: boolean | null;
  setIsSupervisor:  Dispatch<SetStateAction<boolean | null>>;
}
