import { httpService } from 'modules/core/services/http.service';

export type IdCampaignType = string;

export interface InboundCalculationData {
  agents: number;
  raw_positions: number;
  service_level_percentage: number;
  occupancy: number;
  asa: number;
  answered_immediately_percentage: number;
  abandon_rate_percentage: number;
  highlighted?: boolean;
  name?: string;
}

export interface SaveCalculationParams {
  name: string;
  campaignId: string;
  incomingContactsInput: number;
  periodInMinutesInput: number;
  ahtInput: number;
  serviceLevelPercentageRequiredInput: number;
  targetTimeInput: number;
  agentsOutput: number;
  sContractionOutput: number;
  serviceLevelPercentageOutput: number;
  occupancyOutput: number;
  abandonRatePercentageOutput: number;
  answeredImmediatelyPercentageOutput: number;
  asaOutput: number;
  maxOccupacionInput?: number;
  reductoresInput?: number;
  avgWaitTimeInSecondsInput?: number;
  weeklyV1fteInHoursInput?: number;
}

export interface SavedCalculationData {
  maxOccupacionInput: number;
  reductoresInput: number;
  weeklyV1fteInHoursInput: number;
  reportsIntervalsInMinutesInput: number;
  avgWaitTimeInSecondsInput: number;
  created_by: string;
  updated_by: string;
  name: string;
  incomingContactsInput: number;
  periodInMinutesInput: number;
  ahtInput: number;
  serviceLevelPercentageRequiredInput: number;
  targetTimeInput: number;
  agentsOutput: number;
  sContractionOutput: number;
  serviceLevelPercentageOutput: number;
  occupancyOutput: number;
  abandonRatePercentageOutput: number;
  answeredImmediatelyPercentageOutput: number;
  asaOutput: number;
  campaignId: string;
  deleted_at: Date | null;
  deleted_by: Date | null;
  created_at: string;
  updated_at: string;
  id: string;
}

export interface Calculation {
  inbound_created_at: string;
  inbound_id: string;
  inbound_name: string;
  user_id: string;
  fullname: string;
}

export interface MetaDataGetCampaignCalculations {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemCount: number;
  page: number;
  pageCount: number;
  take: number;
}

interface getCampaingCalculations {
  data: Calculation[];
  meta: MetaDataGetCampaignCalculations;
}

interface CampaignIdDetail {
  created_at: string;
  updated_at: string;
  deleted_at: Date | null;
  created_by: string;
  updated_by: string;
  deleted_by: Date | null;
  id: string;
  name: string;
  platform_id: string;
  features: string;
  type: string;
  status: boolean;
  requirements: string;
  start_date: string;
}
interface CampaignId {
  id: string;
  campaignId: CampaignIdDetail;
}

export interface CalculationDetail {
  maxOccupacionInput: number;
  reductoresInput: number;
  weeklyV1fteInHoursInput: number;
  reportsIntervalsInMinutesInput: number;
  avgWaitTimeInSecondsInput: number;
  created_at: string;
  updated_at: string;
  deleted_at: Date | null;
  created_by: string;
  updated_by: string;
  deleted_by: Date | null;
  id: string;
  name: string;
  incomingContactsInput: number;
  periodInMinutesInput: number;
  ahtInput: number;
  serviceLevelPercentageRequiredInput: number;
  targetTimeInput: number;
  agentsOutput: number;
  sContractionOutput: number;
  serviceLevelPercentageOutput: number;
  occupancyOutput: number;
  abandonRatePercentageOutput: number;
  answeredImmediatelyPercentageOutput: number;
  asaOutput: number;
  campaignId: CampaignId;
}

const normalizePercentage = (value: number) => {
  return Number(value / 100).toFixed(3);
};

export const getInboundCalculation = async (
  incomingContacts: number,
  periodInMinutes: number,
  serviceLevelPercentage: number,
  targetTime: number,
  aht: number,
  maxOccupation: number,
  reducers: number,
  weeklyV1fteInHours: number,
  avgWaitTimeInSeconds: number,
) => {
  const queryParams = `incomingContacts=${incomingContacts}&periodInMinutes=${periodInMinutes}&serviceLevelPercentage=${normalizePercentage(
    serviceLevelPercentage,
  )}&targetTime=${targetTime}&aht=${aht}&maxOccupation=${normalizePercentage(
    maxOccupation,
  )}&reducers=${normalizePercentage(
    reducers,
  )}&weeklyV1fteInHours=${weeklyV1fteInHours}&avgWaitTimeInSeconds=${avgWaitTimeInSeconds}`;

  const res = await httpService.get({
    url: `forecasts/calculations?${queryParams}`,
  });

  return res.data.data as InboundCalculationData[];
};

export const postInboundCalculation = async (bodyParams: SaveCalculationParams) => {
  const payload: SaveCalculationParams = {
    ...bodyParams,
    serviceLevelPercentageRequiredInput: Number(normalizePercentage(bodyParams.serviceLevelPercentageRequiredInput)),
  };
  const res = await httpService.post({
    url: 'forecasts/inbound',
    payload,
  });

  return res.data as SavedCalculationData;
};

export const getCampaignCalculations = async (campaignId: string, page: number, take: number) => {
  const res = await httpService.get({
    url: `campaigns/${campaignId}/calculations?page=${page}&take=${take}`,
  });

  return res.data as getCampaingCalculations;
};

export const getCalculationDetail = async (inboundId: string) => {
  const res = await httpService.get({
    url: `forecasts/inbound/${inboundId}`,
  });

  return res.data as CalculationDetail;
};

export const deleteCalculationDetail = async (inboundId: string) => {
  const res = await httpService.delete({
    url: `forecasts/inbound/${inboundId}`,
  });

  return res.data?.affected > 0;
};
