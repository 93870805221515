import { AppDispatch } from 'store';
import { requestActions } from 'store/slices/requests.slices';
import {
  getRequests,
  getChargedRequests,
  getRequestsRevisions,
  getRequestById,
} from 'modules/requests/services/request.service';
import {
  RequestInterface,
  emptyRequestModel,
  getRequestsResponseInterface,
  FilterQueryObjectInterface,
} from 'modules/requests/interfaces/request.interface';

export const fetchRequests = (employeeId: string, queryParams?: FilterQueryObjectInterface) => {
  return async (dispatch: AppDispatch) => {
    try {
      let result: getRequestsResponseInterface;

      dispatch(requestActions.setLoading(true));

      if (queryParams) {
        result = await getRequests(employeeId, queryParams);
      } else {
        result = await getRequests(employeeId);
      }

      dispatch(requestActions.setRequests(result.data));
      dispatch(requestActions.setMeta(result.meta));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(requestActions.setLoading(false));
    }
  };
};

export const fetchRequestById = (campaignIdParam: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(requestActions.setLoading(true));
      const result: RequestInterface = await getRequestById(campaignIdParam);
      dispatch(requestActions.setSelectedRequest(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(requestActions.setLoading(false));
    }
  };
};

export const resetSelectedRequest = () => {
  return (dispatch: AppDispatch) => {
    dispatch(requestActions.setSelectedRequest(emptyRequestModel));
  };
};

export const fetchChargedRequests = (queryParams?: FilterQueryObjectInterface) => {
  return async (dispatch: AppDispatch) => {
    try {
      let result: getRequestsResponseInterface;

      dispatch(requestActions.setLoading(true));

      if (queryParams) {
        result = await getChargedRequests(queryParams);
      } else {
        result = await getChargedRequests();
      }

      dispatch(requestActions.setRequests(result.data));
      dispatch(requestActions.setMeta(result.meta));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(requestActions.setLoading(false));
    }
  };
};

export const fetchRequestRevisions = (queryParams?: FilterQueryObjectInterface) => {
  return async (dispatch: AppDispatch) => {
    try {
      let result: getRequestsResponseInterface;

      dispatch(requestActions.setLoading(true));

      if (queryParams) {
        result = await getRequestsRevisions(queryParams);
      } else {
        result = await getRequestsRevisions();
      }

      dispatch(requestActions.setRequests(result.data));
      dispatch(requestActions.setMeta(result.meta));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(requestActions.setLoading(false));
    }
  };
};
