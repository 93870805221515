import { format } from 'date-fns';

export const dateToString = (date?: string | null) => {
  if (!date) return '-';
  const parsedDate = new Date(date.replace(/-/g, '/'));
  if (isNaN(parsedDate.getTime())) {
    return 'Invalid Date';
  }

  const formattedDate = format(parsedDate, 'dd LLL yyyy');
  return formattedDate;
};
