import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  intervalsEmptyModel,
  BreaksFormInterface,
} from 'modules/setup-config/interfaces/configuratorBreaks.interfaces';
import { ShiftEntity, ShiftInterface } from 'modules/shifts/interfaces/shifts.interface';
import { emptyShiftModel, emptyShiftSliceModel } from 'modules/shifts/utils';

export const shiftSlices = createSlice({
  name: 'shifts',
  initialState: {
    loading: false,
    meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
    shifts: [emptyShiftSliceModel],
    createdShift: emptyShiftModel,
    selectedShift: emptyShiftModel,
    intervals: intervalsEmptyModel,
  },
  reducers: {
    setShifts(state, action: PayloadAction<ShiftEntity[]>) {
      state.shifts = action.payload;
    },
    setSelectedShift(state, action: PayloadAction<ShiftInterface>) {
      state.selectedShift = action.payload;
    },
    setCreatedShift(state, action: PayloadAction<ShiftInterface>) {
      state.createdShift = action.payload;
    },
    setIntervals(state, action: PayloadAction<BreaksFormInterface>) {
      state.intervals = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMeta(state, action: PayloadAction<any>) {
      state.meta = action.payload;
    },
    resetSelectedShift(state) {
      state.selectedShift = emptyShiftModel;
    },
  },
});

export const shiftsActions = shiftSlices.actions;
export default shiftSlices;
