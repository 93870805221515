import { formatDate, FormatDateOutputDash } from './formatDate';

export const downloadCSVFiles = (csv_data: any) => {
  Object.keys(csv_data).map(dataKey => {
    const dataObject = csv_data[dataKey as keyof typeof csv_data];
    const dataString = Array.isArray(dataObject) && dataObject[0];
    if (dataString) {
      const formattedDateString = formatDate(new Date().toISOString(), FormatDateOutputDash.YYYYMMDD);
      const CSVFile = new Blob(['\uFEFF' + csv_data], { type: 'text/csv; charset=utf-8' });
      const temp_link = document.createElement('a');
      temp_link.download = 'dataset-' + dataKey + formattedDateString + '.csv';
      const url = window.URL.createObjectURL(CSVFile);
      temp_link.href = url;
      temp_link.style.display = 'none';
      document.body.appendChild(temp_link);
      temp_link.click();
      document.body.removeChild(temp_link);
    }
  });
};
