import { useState, useEffect } from 'react';
import { Badge, BadgeProps, IconButton, Typography, Popover, Stack, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import Link from 'modules/shared/components/link/Link.component';

// service
import { deleteNotification, getNotifications } from '../../../../services';

// interfaces
import { Notification, PaginationProps } from '../../../../interfaces';
import { useAuth } from 'modules/auth/AuthProvider.component';
import moment from 'moment';

const Notifications = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [pagination, setPagination] = useState<PaginationProps>({
    hasNextPage: false,
    hasPreviousPage: false,
    itemCount: 0,
    page: 1,
    pageCount: 0,
    take: 25,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { user } = useAuth();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMetricName = (name: string) => {
    switch (name) {
      case 'callsvolume':
        return 'Cantidad de llamadas';
      case 'headcount':
        return 'Headcount';
      case 'effectivemanagement':
        return 'Gestiones Efectivas';
      default:
        return 'Métrica';
    }
  };

  const handleDeleteNotification = async (id: string) => {
    try {
      const deletedNotification = await deleteNotification(id);
      if (deletedNotification) {
        const filteredNotifications = notifications.filter(item => item.id !== id);
        setNotifications(filteredNotifications);
      }
    } catch (e) {
      enqueueSnackbar('Ha ocurrido un error al eliminar la notificación', { variant: 'error' });
    }
  };

  const getMoreNotifications = async () => {
    try {
      const data: any = await getNotifications({ page: pagination.page + 1, take: pagination.take });
      const notificationsArray = data.data;
      const notificationsMeta = data.meta;
      setNotifications([...notifications].concat(notificationsArray));
      setPagination({
        ...pagination,
        itemCount: notificationsMeta.itemCount,
        pageCount: notificationsMeta.pageCount,
        hasNextPage: notificationsMeta.hasNextPage,
        hasPreviousPage: notificationsMeta.hasPreviousPage,
        page: notificationsMeta.page,
      });
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error al obtener las notificaciones', { variant: 'error' });
    }
  };

  useEffect(
    function getDataNotifications() {
      if (user) {
        const getData = async () => {
          try {
            const data: any = await getNotifications({ page: pagination.page, take: pagination.take });
            const notificationsArray = data.data;
            const notificationsMeta = data.meta;
            setNotifications(notificationsArray);
            setPagination({
              ...pagination,
              itemCount: notificationsMeta.itemCount,
              pageCount: notificationsMeta.pageCount,
              hasNextPage: notificationsMeta.hasNextPage,
              hasPreviousPage: notificationsMeta.hasPreviousPage,
            });
          } catch (error) {
            enqueueSnackbar('Ha ocurrido un error al obtener las notificaciones', { variant: 'error' });
          }
        };
        getData();
      }
    },
    [user],
  );

  return (
    <>
      <StyledIconButton aria-label="notifications" onClick={handleClick}>
        <StyledBadge variant="dot" color="primary" aria-describedby={id} invisible={true}>
          <StyledNotificationsIcon sx={{ color: '#0e48a3' }} />
        </StyledBadge>
      </StyledIconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ maxHeight: '350px' }}
        onClose={handleClose}
      >
        <Stack sx={{ width: '520px' }}>
          <Typography
            variant="h6"
            sx={{
              ml: '16px',
              mt: '14px',
              mb: '14px',

              color: 'rgba(0, 0, 0, 0.6)',
              'letter-spacing': '0.15px',
            }}
          >
            Notificaciones
          </Typography>

          {notifications.length ? (
            <>
              {notifications.map(notification => (
                <Box
                  key={notification.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    pt: 2,
                    pb: 2,
                    pl: 4,
                    pr: 4,
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  }}
                >
                  <Box>
                    <Typography>
                      {getMetricName(notification.alertTypeCode)}
                      {'  '}
                      <Typography component="strong" sx={{ color: '#F72685' }}>
                        {notification.data.value < 0
                          ? `${notification.data.value * -1}% por debajo`
                          : `${notification.data.value}% por encima`}
                      </Typography>{' '}
                      del valor relativo esperado en el Plan de Vuelo{' '}
                      <Link href={`/campaigns/outbound/dashboard/${notification.data.outbound.id}`}>
                        {notification.data.outbound.name}
                      </Link>{' '}
                      .
                    </Typography>
                    <Typography sx={{ color: '#989898' }}>
                      {moment(notification.created_at).format('DD/MM HH:mm')}
                    </Typography>
                  </Box>

                  <IconButton onClick={() => handleDeleteNotification(notification.id)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}
              {notifications.length && pagination.hasNextPage && (
                <Button sx={{ margin: '0 auto' }} variant="text" onClick={() => getMoreNotifications()}>
                  Más notificaciones
                </Button>
              )}
            </>
          ) : (
            <Typography sx={{ textAlign: 'center', mb: 5, color: '#989898' }}>
              No hay notificaciones pendientes
            </Typography>
          )}
        </Stack>
      </Popover>
    </>
  );
};

const StyledIconButton = styled(IconButton)`
  &:hover {
    animation: shake 0.5s ease-in-out infinite;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-4px);
    }
    50% {
      transform: translateX(4px);
    }
    75% {
      transform: translateX(-4px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
const StyledNotificationsIcon = styled(NotificationsIcon)`
  &:hover {
    animation: shake 0.5s ease-in-out infinite;
  }

  @keyframes shake {
    0% {
      transform: translateX(0) rotate(0);
    }
    25% {
      transform: translateX(-2px) rotate(3deg);
    }
    50% {
      transform: translateX(2px) rotate(-3deg);
    }
    75% {
      transform: translateX(-1px) rotate(1.5deg);
    }
    100% {
      transform: translateX(0) rotate(0);
    }
  }
`;

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 5,
    border: `1px solid ${theme.palette.background.paper}`,
  },
}));

export default Notifications;
