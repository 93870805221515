import React, { Suspense, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'modules/auth/AuthProvider.component';
import { CircularProgress, styled } from '@mui/material';

interface ProtectedRouteProps {
  element: JSX.Element;
}

const RouteWrapper = ({ element }: ProtectedRouteProps) => {
  const { user, initialized } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && initialized && pathname !== '/change-password') {
      navigate('/sign-in', { replace: true });
    }
  }, [user, initialized, navigate]);

  if (!initialized) {
    return (
      <CircularProgress
        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        size={58}
        color="secondary"
      />
    );
  }

  return (
    <Suspense
      fallback={
        <Fallback>
          <CircularProgress
            sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            size={58}
            color="secondary"
          />
        </Fallback>
      }
    >
      {element}
    </Suspense>
  );
};

const Fallback = styled('div')({
  width: '100%',
  height: '50vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default RouteWrapper;
