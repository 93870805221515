import { httpService } from 'modules/core/services/http.service';
import { PaginationProps } from '../interfaces';

export const getNotifications = async (paginationProps: PaginationProps) => {
  const res = await httpService.get({
    url: 'notifications',
    params: paginationProps,
  });

  return res.data;
};

export const deleteNotification = async (notificationId: string) => {
  const res = await httpService.delete({
    url: `notifications/${notificationId}`,
  });
  return res;
};
