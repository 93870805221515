import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material';

interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean;
}

const Button = ({
  isLoading,
  fullWidth = false,
  disabled,
  onClick,
  variant,
  color,
  children,
  ...rest
}: ButtonProps) => {
  return (
    <MuiButton onClick={onClick} variant={variant} color={color} fullWidth={fullWidth} disabled={disabled} {...rest}>
      {isLoading ? <CircularProgress size={30} /> : children}
    </MuiButton>
  );
};

export default Button;
