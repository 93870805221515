import { useContext } from 'react';

import { AppBar, Divider, Menu, MenuItem, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import Profile from 'modules/layouts/app/components/header/components/profile/Profile.component';
import { MouseEvent, useState } from 'react';
import { useAuth } from 'modules/auth/AuthProvider.component';
import { AppBarProps } from '@mui/material';
import { HeaderContext } from '../../context/Header.context';
import LanguagePopover from './components/LanguagePopover.component';

interface HeaderProps {
  onMenuClick?: () => void;
  expand: boolean;
}
interface IAppBarProps extends AppBarProps {
  expand: boolean;
  matchSizeSmall: boolean;
}
const Header = ({ expand }: HeaderProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { headerRef } = useContext(HeaderContext);

  const { user, signOut } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    handleClose();
    signOut();
    navigate('/sign-in', { replace: true });
  };

  const handleMyProfile = () => {
    navigate('/my-profile');
  };

  return (
    <AppBarStyle position="sticky" elevation={0} expand={expand} ref={headerRef} matchSizeSmall={matchSizeSmall}>
      <AppBarContent>
        <LanguagePopover />

        <ProfileContainer>
          <Profile
            fullName={{
              firstName: user?.firstName || '',
              lastName: user?.lastName || '',
            }}
            picture=""
            status="online"
            onClick={handleClick}
          />
          <Menu
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuHead>
              <Typography variant="subtitle2" color="primary">
                @{user?.username.toLocaleLowerCase()}
              </Typography>
            </MenuHead>
            <StyledDivider />
            <StyledMenuItem onClick={handleMyProfile}>Mi perfil</StyledMenuItem>
            <StyledDivider />
            <StyledMenuItem onClick={handleSignOut}>
              <LogoutIcon /> Salir
            </StyledMenuItem>
          </Menu>
        </ProfileContainer>
      </AppBarContent>
    </AppBarStyle>
  );
};

const AppBarStyle = styled(AppBar, { shouldForwardProp: props => props !== 'expand' && props !== 'matchSizeSmall' })<
  IAppBarProps
>(({ theme, expand, matchSizeSmall }) => ({
  color: theme.palette.grey[100],
  left: expand && !matchSizeSmall ? 288 : 96,
  right: 0,
  top: '0.5rem',
  width: expand ? 'calc(100% - 288px)' : 'calc(100% - 96px)',
  borderRadius: 16,
  transition: 'all 250ms',
  padding: '0 !important',
  backgroundColor: 'transparent',
}));

const AppBarContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.palette.grey[100],
  padding: theme.spacing(4),
  height: 80,
}));

const ProfileContainer = styled('div')(() => ({
  color: 'black',
}));

const MenuHead = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(24),
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export default Header;
