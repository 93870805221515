import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { Meta } from 'modules/shared/interfaces/shared.interface';
import {
  OutboundForecasts,
  OutboundForecastForm,
  initialNewForecastStatus,
  initialSimulateForecastStatus,
  initialOutboundForecastForm,
  ForecastStatus,
  OutboundPlan,
  FieldTargetForm,
} from 'modules/outbound/interfaces/outbound.interface';
import { OperatorEntity, Meta, initialMeta } from 'modules/shared/interfaces/shared.interface';

type StatusForecastPayload = { value: boolean; from: string };
interface ForecastStoreInterface {
  forecast: {
    inbound: [];
    outbound: OutboundForecasts[];
  };
  fieldsTarget: FieldTargetForm[];
  formOutboundForecast: OutboundForecastForm;
  newForecastStatus: ForecastStatus;
  simulateForecastStatus: ForecastStatus;
  operators: OperatorEntity[];
  meta: Meta;
  forecastSimulation: OutboundForecasts | null;
  plan: OutboundPlan | null;
  selectedForecast: OutboundForecasts | null;
}

const initialState: ForecastStoreInterface = {
  forecast: {
    inbound: [],
    outbound: [],
  },
  fieldsTarget: [],
  formOutboundForecast: initialOutboundForecastForm,
  newForecastStatus: initialNewForecastStatus,
  simulateForecastStatus: initialSimulateForecastStatus,
  operators: [],
  meta: initialMeta,
  forecastSimulation: null,
  plan: null,
  selectedForecast: null,
};

export const forecastSlice = createSlice({
  name: 'forecast',
  initialState,
  reducers: {
    setForecastOutbound(state, action: PayloadAction<OutboundForecasts[]>) {
      state.forecast.outbound = action.payload;
    },
    setLoading(state, action: PayloadAction<StatusForecastPayload>) {
      if (action.payload.from === 'simulateForecast') {
        state.simulateForecastStatus.isLoading = action.payload.value;
      } else {
        state.newForecastStatus.isLoading = action.payload.value;
      }
    },
    setDisabled(state, action: PayloadAction<StatusForecastPayload>) {
      if (action.payload.from === 'simulateForecast') {
        state.simulateForecastStatus.isDisabled = action.payload.value;
      } else {
        state.newForecastStatus.isDisabled = action.payload.value;
      }
    },
    setShowResults(state, action: PayloadAction<StatusForecastPayload>) {
      if (action.payload.from === 'simulateForecast') {
        state.simulateForecastStatus.showResults = action.payload.value;
      } else {
        state.newForecastStatus.showResults = action.payload.value;
      }
    },
    setShowInfoForm(state, action: PayloadAction<StatusForecastPayload>) {
      if (action.payload.from === 'simulateForecast') {
        state.simulateForecastStatus.showInfoForm = action.payload.value;
      } else {
        state.newForecastStatus.showInfoForm = action.payload.value;
      }
    },
    setShowForm(state, action: PayloadAction<StatusForecastPayload>) {
      if (action.payload.from === 'simulateForecast') {
        state.simulateForecastStatus.showForm = action.payload.value;
      } else {
        state.newForecastStatus.showForm = action.payload.value;
      }
    },
    resetNewForecastStatus(state) {
      state.newForecastStatus = initialNewForecastStatus;
    },
    resetSimulateForecastStatus(state) {
      state.simulateForecastStatus = initialSimulateForecastStatus;
    },
    setOperators(state, action: PayloadAction<OperatorEntity[]>) {
      state.operators = action.payload;
    },
    resetOperators(state) {
      state.operators = [];
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
    setFormOutboundForecast(state, action: PayloadAction<OutboundForecastForm>) {
      state.formOutboundForecast = action.payload;
    },
    resetFormOutboundForecast(state) {
      state.formOutboundForecast = initialOutboundForecastForm;
    },
    setFieldsTarget(state, action: PayloadAction<FieldTargetForm[]>) {
      state.fieldsTarget = action.payload;
    },
    setForecastSimulation(state, action: PayloadAction<OutboundForecasts>) {
      state.forecastSimulation = action.payload;
    },
    resetForecastSimulation(state) {
      state.forecastSimulation = null;
    },
    setPlan(state, action: PayloadAction<OutboundPlan>) {
      state.plan = action.payload;
    },
    resetPlan(state) {
      state.plan = null;
    },
    setSelectedForecast(state, action: PayloadAction<OutboundForecasts>) {
      state.selectedForecast = action.payload;
    },
    resetSelectedForecast(state) {
      state.selectedForecast = null;
    },
    resetForecast(state) {
      state.forecast.outbound = [];
    },
  },
});

export const forecastActions = forecastSlice.actions;

export default forecastSlice;
