import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { RefObject, SetStateAction } from 'react';

export const handleDownloadPdf = async (
  componentRef: RefObject<HTMLDivElement>,
  setDisableButton: (setter: SetStateAction<boolean>) => void,
  pdfName = 'document',
) => {
  const element = componentRef.current;

  if (element) {
    setDisableButton(true);
    const canvas = await html2canvas(element, { scale: 2 });
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width + 200, canvas.height + 160],
      compress: true,
    });
    pdf.setFontSize(50);
    pdf.text('Synergy WFM', canvas.width - 100, 70);
    pdf.addImage(data, 'PNG', 100, 90, canvas.width, canvas.height, '', 'FAST');
    pdf.save(`${pdfName}.pdf`);
    if (canvas) {
      setDisableButton(false);
    }
  }
};
