import { ControllerProps } from 'react-hook-form';

export const commonRules: ControllerProps['rules'] = {
  minLength: {
    value: 1,
    message: 'Ingrese al menos 2 numeros',
  },
};

export const rulesContacts: ControllerProps['rules'] = {
  ...commonRules,
  pattern: {
    value: /^[0-9 ]*$/,
    message: 'Solo numero positivos',
  },
};

export const rulesTime: ControllerProps['rules'] = {
  ...commonRules,
  pattern: {
    value: /(^\d*\.?\d*[1-9]+\d*$)|(^[1-9]+\d*\.\d*$)/,
    message: 'Coloca un número',
  },
};

export const rulesWeeklyHours: ControllerProps['rules'] = {
  ...commonRules,
  pattern: {
    value: /^[-0-9 ]*$/,
    message: 'Coloca un número',
  },
};

export const rulesPercentage: ControllerProps['rules'] = {
  ...commonRules,
  max: {
    value: 100,
    message: 'El porcentaje no puede ser mayor a 100',
  },
};
export const rulesPercentage99: ControllerProps['rules'] = {
  ...commonRules,
  max: {
    value: 99.94,
    message: 'El porcentaje debe ser menor a 99.95',
  },
};
