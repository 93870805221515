import { Components } from '@mui/material';

export const MuiContainer: Components['MuiContainer'] = {
  styleOverrides: {
    root: {
      minWidth: '100%',
      '@media (min-width: 600px)': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
};
