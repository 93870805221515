import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { alpha, styled, Theme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SelectedRowData, useInboundCalculator } from 'modules/core/providers/InboundCalculator.provider';
import EnhancedTableHead, { HeadCell } from 'modules/shared/components/table/components/EnhancedTableHead.component';
import { useEffect, useState } from 'react';
import { getHeadCells, sortRows } from '../utils';

export interface TableRowProps {
  id: string | number;
  agents: number;
  agentsWithoutContraction: number;
  serviceLevel: number;
  occupation: number;
  asa: number;
  immediateResponse: number;
  abandonmentRate: number;
  isSuggested?: boolean;
}

interface VariationsAgentsTableProps {
  rows: TableRowProps[];
  campaignName: string;
  disableCheckbox?: boolean;
  disableTitle?: boolean;
  elevation?: number;
}

interface BodyTableRowProps {
  isSelected: boolean;
  isSuggested: boolean;
  theme?: Theme;
}

export const VariationsAgentsTable = ({
  rows,
  campaignName,
  disableCheckbox,
  disableTitle,
  elevation = 1,
}: VariationsAgentsTableProps) => {
  const [sortedRows, setSortedRows] = useState<TableRowProps[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<string | number | null>(null);
  const [tableHeader, setTableHeader] = useState<HeadCell[]>([]);
  const [sortBy, setSortBy] = useState<string>('agents');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const { setSelectedRow, selectedRowData } = useInboundCalculator();

  const handleSelectRow = (newRow: TableRowProps) => {
    setSelectedRowId(prevState => {
      let newSelectedRow: SelectedRowData | null = {
        agents: newRow.agentsWithoutContraction,
        raw_positions: newRow.agents,
        service_level_percentage: newRow.serviceLevel,
        occupancy: newRow.occupation,
        asa: newRow.asa,
        answered_immediately_percentage: newRow.immediateResponse,
        abandon_rate_percentage: newRow.abandonmentRate,
        name: campaignName,
      };
      const newValue = prevState !== newRow.id ? newRow.id : null;
      if (newValue == null) {
        newSelectedRow = null;
      }
      setSelectedRow(newSelectedRow);
      return newValue;
    });
  };

  const normalizePercentage = (value: number, decimals = 1) => {
    return parseFloat(Number(value * 100).toFixed(decimals));
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property.toString());
  };

  useEffect(() => {
    if (selectedRowData == null) {
      setSelectedRowId(null);
    }
  }, [selectedRowData]);

  useEffect(() => {
    setTableHeader(getHeadCells(!!disableCheckbox));
  }, [disableCheckbox]);

  useEffect(() => {
    if (!sortBy) {
      setSortedRows(rows);
    } else {
      const rowsBySortValues = sortRows(rows, sortBy, sortDirection);
      setSortedRows(rowsBySortValues);
    }
  }, [rows, sortBy, sortDirection]);

  return (
    <VariationsAgentsContainer elevation={elevation}>
      {!disableTitle && (
        <TitleContainer>
          <Typography variant="h6">Variaciones en el Número de Agentes</Typography>
          <InfoOutlined color="action" />
        </TitleContainer>
      )}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <EnhancedTableHead
            onRequestSort={handleRequestSort}
            order={sortDirection}
            orderBy={sortBy}
            headCells={tableHeader}
          />
          <TableBody>
            {sortedRows.map(row => (
              <BodyTableRow key={row.id} isSelected={row.id === selectedRowId} isSuggested={!!row.isSuggested}>
                {!disableCheckbox && (
                  <ActionBodyCell component="th">
                    <Checkbox
                      color="default"
                      checked={row.id === selectedRowId}
                      onChange={() => handleSelectRow(row)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </ActionBodyCell>
                )}
                <AgentsBodyCell component="th" scope="row">
                  <Typography variant="h6">{row.agents}</Typography>
                </AgentsBodyCell>
                <TableCell>{row.agentsWithoutContraction}</TableCell>
                <TableCell>{normalizePercentage(row.serviceLevel)}%</TableCell>
                <TableCell>{normalizePercentage(row.occupation)}%</TableCell>
                <TableCell>{parseFloat(Number(row.asa).toFixed(2))}</TableCell>
                <TableCell>{normalizePercentage(row.immediateResponse)}%</TableCell>
                <TableCell>{normalizePercentage(row.abandonmentRate, 2)}%</TableCell>
              </BodyTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </VariationsAgentsContainer>
  );
};

const VariationsAgentsContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(10, 12, 10, 14),
}));

const TitleContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  display: 'flex',
  gap: theme.spacing(2),
}));

const BodyTableRow = styled(TableRow, {
  shouldForwardProp: prop => prop !== 'isSelected' && prop !== 'isSuggested',
})(({ isSelected, isSuggested, theme }: BodyTableRowProps) => ({
  backgroundColor: isSelected
    ? alpha(theme?.palette.primary.main as string, 0.3)
    : isSuggested
    ? alpha(theme?.palette.secondary.main as string, 0.5)
    : 'transparent',
  '& th': {
    backgroundColor:
      isSuggested && !isSelected ? theme?.palette.secondary.main : alpha(theme?.palette.primary.main as string, 0.12),
  },
}));

const ActionBodyCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: 0,
}));

const AgentsBodyCell = styled(TableCell)(({ theme }) => ({
  padding: theme?.spacing(2, 4, 2, 2),
}));
