import { z } from 'zod';

export interface BreaksFormInterface {
  id: string;
  main: BreakInterface;
  intervals: IntervalInterface[];
}

export interface BreakInterface {
  id: string;
  name: string;
  duration: number;
  max_per_day: number;
  remunerative: boolean;
  remunerative_percentage?: number;
  subtype_id?: number;
}

export interface IntervalInterface extends Omit<BreakInterface, 'max_per_day'> {
  interval: number;
}

export const intervalsEmptyModel: BreaksFormInterface = {
  id: '',
  main: {
    id: '',
    name: '',
    duration: 0,
    max_per_day: 0,
    remunerative: false,
    remunerative_percentage: 0,
  },
  intervals: [
    {
      id: '',
      name: '',
      duration: 0,
      interval: 0,
      remunerative: false,
      remunerative_percentage: 0,
    },
  ],
};

const BreaksSchema = z.object({
  id: z.string(),
  name: z.string().min(1, 'Requerido'),
  duration: z.coerce
    .number({ invalid_type_error: 'Requerido' })
    .min(0, 'Incorrecto')
    .int(),
  max_per_day: z
    .number({ invalid_type_error: 'Requerido' })
    .min(1, 'Máximo por día debe ser mayor a 0')
    .int(),
  remunerative: z.boolean(),
  remunerative_percentage: z
    .number({ invalid_type_error: 'Requerido' })
    .min(0, 'Valor requerido')
    .max(100, 'Máximo 100%'),

  subtype_id: z.number().optional(),
});

const IntervalSchema = BreaksSchema.omit({
  max_per_day: true,
}).extend({
  interval: z
    .number({ invalid_type_error: 'Requerido' })
    .min(0, 'Intervalo requerido')
    .int(),
});

export const BreaksFormSchema = z.object({
  id: z.string(),
  main: BreaksSchema,
  intervals: z.array(IntervalSchema),
});
