import { styled } from '@mui/material';

const AuthFormAdornment = () => {
  return <AdornmentImage src="/images/login_image.png" alt="Adornment image login" />;
};

const AdornmentImage = styled('img')(() => ({
  maxWidth: '100%',
}));

export default AuthFormAdornment;
