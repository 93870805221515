import { PasswordChangeInterface } from 'modules/profile/interfaces/password.interface';
import { httpService } from 'modules/core/services/http.service';
import { getDataUserAdapter } from '../adapters/getDataUser.adapter';

export const getDataUser = async () => {
  const res = await httpService.get({ url: 'users/me' });

  return getDataUserAdapter(res.data);
};

export const changePassword = async (data: PasswordChangeInterface) => {
  const res = await httpService.put({
    url: `users/password`,
    payload: data,
  });

  return res.data;
};

export const refreshToken = async () => {
  const res = await httpService.get({ url: 'auth/refresh' });

  return res.data;
};
