/* eslint-disable @typescript-eslint/no-empty-function */
import { useAuth } from 'modules/auth/AuthProvider.component';
import { getFile, uploadFile } from 'modules/profile/services/UserImage.services';
import { useSnackbar } from 'notistack';
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';

interface EmployeesContextProps {
  selectedEmployeeImage: File | null;
  setSelectedEmployeeImage: Dispatch<SetStateAction<File | null>>;
  fetchImage: (paramId: string) => Promise<void>;
  updateImage: (userId: string, file: File) => Promise<void>;
  fileIsValid: boolean;
  fileSizeOnMbHelperText: string | boolean;
  setFileSizeOnMbHelperText: Dispatch<SetStateAction<string | boolean>>;
  setFileIsValid: Dispatch<SetStateAction<boolean>>;
  validFileTypes: string[];
}

export const EmployeesContext = createContext<EmployeesContextProps>({
  selectedEmployeeImage: null,
  setSelectedEmployeeImage: () => {},
  fetchImage: async () => {},
  updateImage: async () => {},
  fileIsValid: false,
  setFileIsValid: () => {},
  fileSizeOnMbHelperText: '',
  setFileSizeOnMbHelperText: () => {},
  validFileTypes: [],
});

interface EmployeesProviderProps {
  children: ReactNode;
}

export const useEmployeesContext = () => {
  const context = useContext(EmployeesContext);

  if (!context) throw new Error('useEmployeesContext must be use inside HomeDashboardsProvider');

  return context;
};

export const EmployeesProvider = ({ children }: EmployeesProviderProps) => {
  const [selectedEmployeeImage, setSelectedEmployeeImage] = useState<File | null>(null);
  const [fileIsValid, setFileIsValid] = useState(false);
  const [fileSizeOnMbHelperText, setFileSizeOnMbHelperText] = useState<string | boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();

  const fetchImage = useCallback(
    async (paramId: string) => {
      if (token) {
        const image = await getFile(paramId);
        setSelectedEmployeeImage(image);
      }
    },
    [token],
  );

  const updateImage = useCallback(async (userId: string, file: File) => {
    try {
      await uploadFile(userId, file);
      enqueueSnackbar('Imagen actualizada exitosamente', { variant: 'success' });
    } catch (error) {
      const errorMessage = (error as any)?.response?.data?.message || 'No se pudieron obtener los detalles';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  }, []);

  const memoizedValue = useMemo(() => {
    const validFileTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

    return {
      selectedEmployeeImage,
      setSelectedEmployeeImage,
      fetchImage,
      updateImage,
      fileIsValid,
      setFileIsValid,
      fileSizeOnMbHelperText,
      setFileSizeOnMbHelperText,
      validFileTypes,
    };
  }, [
    selectedEmployeeImage,
    setSelectedEmployeeImage,
    fetchImage,
    updateImage,
    fileIsValid,
    setFileIsValid,
    fileSizeOnMbHelperText,
    setFileSizeOnMbHelperText,
  ]);

  return <EmployeesContext.Provider value={memoizedValue}>{children}</EmployeesContext.Provider>;
};
