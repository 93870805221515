import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Employee, EmployeeEntity, emptyEmployeeEntityModel } from 'modules/employees/interfaces/employees.interface';
import { Meta } from 'modules/shared/interfaces/shared.interface';

interface SelectOption {
  id: string;
  name: string;
}
interface EmployeesStoreInterface {
  loading: boolean;
  employees: Employee[];
  selectedEmployee: EmployeeEntity;
  meta: Meta;
  businessName: SelectOption[];
  countries: SelectOption[];
  provinces: SelectOption[];
  employeeHistory: EmployeeEntity['labor'][];
}

const initialState: EmployeesStoreInterface = {
  loading: false,
  employees: [],
  selectedEmployee: emptyEmployeeEntityModel,
  meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
  businessName: [],
  countries: [],
  provinces: [],
  employeeHistory: [],
};

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setEmployees(state, action: PayloadAction<Employee[]>) {
      state.employees = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
    setSelectedEmployee(state, action: PayloadAction<EmployeeEntity>) {
      state.selectedEmployee = action.payload;
    },
    setBusinessNames(state, action: PayloadAction<any>) {
      state.businessName = action.payload;
    },
    setCountries(state, action: PayloadAction<any>) {
      state.countries = action.payload;
    },
    setProvinces(state, action: PayloadAction<any>) {
      state.provinces = action.payload;
    },
    setLaborData(state, action: PayloadAction<EmployeeEntity['labor']>) {
      state.selectedEmployee.labor = action.payload;
    },
    resetSelectedEmployee(state) {
      state.selectedEmployee = emptyEmployeeEntityModel;
    },
    setEmployeeHistory(state, action: PayloadAction<EmployeeEntity['labor'][]>) {
      state.employeeHistory = action.payload;
    },
    resetEmployeeHistory(state) {
      state.employeeHistory = [];
    },
  },
});

export const employeesActions = employeesSlice.actions;

export default employeesSlice;
