export interface Client {
  id: string;
  name: string;
  platformId?: string;
  tags: Tag[];
}
export type Tag = { name: string; id: string };

export type CreateClientForm = {
  name: string;
  platformId: string;
  tags: Tag[];
};
export const emptySelectedClientModel: Client = {
  id: '',
  name: '',
  platformId: '',
  tags: [],
};
export const emptyClientFormModel: CreateClientForm = {
  name: '',
  platformId: '',
  tags: [],
};
