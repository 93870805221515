import cubejs from '@cubejs-client/core';
import { useAuth } from 'modules/auth/AuthProvider.component';
import { useEffect, useState } from 'react';

const UseCubeJSApi = () => {
  const [apiKey, setApiKey] = useState('');
  const { token } = useAuth();
  const cubeapi = process.env.REACT_APP_CUBE_API;
  useEffect(() => {
    if (token) {
      setApiKey(token);
    }
  }, [token]);

  const cubeJSApi = cubejs(apiKey || '', { apiUrl: cubeapi || '' });

  return {
    cubeJSApi,
  };
};

export default UseCubeJSApi;
