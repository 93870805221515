import { HeadCell } from 'modules/shared/components/table/components/EnhancedTableHead.component';
import { TableRowProps } from './components/VariationsAgentsTable';

export const getHeadCells = (disableCheckbox: boolean) => {
  const tableHead: HeadCell[] = [
    {
      id: 'agents',
      label: 'Agentes',
      numeric: false,
      sortable: true,
    },
    {
      id: 'agentsWithoutContraction',
      label: 'Agente s/ contracción',
      numeric: false,
      sortable: true,
    },
    {
      id: 'serviceLevel',
      label: 'Nivel Servicio',
      numeric: false,
      sortable: true,
    },
    {
      id: 'occupation',
      label: 'Ocupación',
      numeric: false,
      sortable: true,
    },
    {
      id: 'asa',
      label: 'ASA (S)',
      numeric: false,
      sortable: true,
    },
    {
      id: 'immediateResponse',
      label: 'Respuesta Inmediata',
      numeric: false,
      sortable: true,
    },
    {
      id: 'abandonmentRate',
      label: 'Tasa Abandono',
      numeric: false,
      sortable: true,
    },
  ];
  if (!disableCheckbox) {
    tableHead.unshift({
      id: 'check',
      label: '',
      numeric: false,
      sortable: false,
    });
  }
  return tableHead;
};

export const sortRows = (rows: TableRowProps[], sortBy: string, sortDirection: string): TableRowProps[] => {
  const descendingComparator = (a: any, b: any, orderBy: keyof typeof a | keyof typeof b) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  return [
    ...rows.sort((a, b) => {
      return sortDirection === 'desc' ? descendingComparator(a, b, sortBy) : -descendingComparator(a, b, sortBy);
    }),
  ];
};
