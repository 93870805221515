import { TagEntity } from 'modules/tags/interfaces';

export interface Campaign {
  name: string;
  id: string;
  platform_id?: string;
  start_date?: string;
}
export interface TagCampaign {
  id: string;
  name: string;
}

export interface TagUser {
  name: string;
  id: string;
}
export type FieldsForm =
  | 'daysOfCampaign'
  | 'amountTotalDataPerBase'
  | 'tmk'
  | 'effectiveManagement'
  | 'reSwept'
  | 'presenteeism';

export type FieldTargetForm = {
  id: string;
  name: string;
  type: number;
  minLength: number;
  maxLength: number;
  minValue: number;
  maxValue: number;
  isOptional: boolean;
};
export interface TargetEntity {
  id: string;
  name: string;
  fields: FieldTargetForm[];
}

export type Target = Pick<TargetEntity, 'id' | 'name'>;
export interface ErrorsPropsForm {
  'campaign.id': string;
  'target.id': string;
}

export type EffectiveManagementData = {
  day: string;
  calls: number;
  originalDate: string;
};

export interface OutboundForecastEntity {
  id: number;
  nameForecast: string;
  deviation: number;
  days: number;
  agentHours: number;
  agentCalls: number;
  agentSale: number;
  TimeLogin: string;
  campaign: Campaign;
  // supervisors: Supervisor[];
  tagsCampaign: TagCampaign[];
  target: Target;
  hours: number;
  dateStart: string;
  dateEnd: string;
  daysOfCampaign: string;
  tmk: number;
  reSwept: number;
  presenteeism: number;
  effectiveManagement: number;
  amountTotalDataPerBase: string;
  reducers: number;
  objectiveResult: number;
  callTime: string;
  breakTime: string;
  pauseTime: string;
  acwTime: string;
  managementByContact: number;
  effectiveCalls: number;
  tmo: number;
  weights?: {
    reductores?: number;
    effectiveness?: number;
    agent_tmk_hs?: number;
    agent_per_llamadas?: number;
    tmo?: number;
    presentismo?: number;
    dias?: number;
    tmk?: number;
    calls?: number;
  };
  qtd_of_success_calls_per_day: EffectiveManagementData[];
  qtd_of_calls_per_agent: [
    {
      agent: string;
      calls: number;
      agent_name: string;
    },
  ];
  qtd_of_calls_per_day: [
    {
      day: string;
      calls: number;
    },
  ];
  qtd_of_calls_per_hour: [
    {
      hour: number;
      calls: number;
    },
  ];
  qtd_of_TMO_per_day: [{ day: string; tmo: number }];
  qtd_of_TMO_per_hour: [{ hour: number; tmo: number }];
}

export type OutboundForecasts = Pick<
  OutboundForecastEntity,
  | 'id'
  | 'nameForecast'
  | 'effectiveManagement'
  | 'deviation'
  | 'days'
  | 'agentHours'
  | 'agentCalls'
  | 'agentSale'
  | 'TimeLogin'
  | 'reducers'
  | 'objectiveResult'
  | 'presenteeism'
  | 'reSwept'
  | 'tmk'
  | 'callTime'
  | 'breakTime'
  | 'pauseTime'
  | 'acwTime'
  | 'qtd_of_success_calls_per_day'
  | 'qtd_of_calls_per_agent'
  | 'qtd_of_calls_per_day'
  | 'qtd_of_calls_per_hour'
  | 'qtd_of_TMO_per_day'
  | 'qtd_of_TMO_per_hour'
  | 'tmo'
  | 'effectiveCalls'
  | 'weights'
>;

export type OutboundForecastForm = {
  campaign: Campaign;
  // supervisors: Supervisor[];
  tagsCampaign: TagEntity[] | TagCampaign[];
  target: Target;
  hours: string;
  dateStart: string;
  dateEnd: string;
  daysOfCampaign: string;
  tmk: string;
  reSwept: string;
  presenteeism: string;
  effectiveManagement: string;
  amountTotalDataPerBase: string;
  'campaign.id'?: string;
  'target.id'?: string;
};

export interface outboundQueryParams {
  campaignId: string;
  tmk?: number;
  hours: number;
  effectiveness?: number;
  days?: number;
  reSwept: number;
  agentCalls?: number;
  presenteeism?: number;
  target: string;
  totalData?: number;
  effectiveCalls?: number;
}
export type OutboundForecastConfigForm = Pick<
  OutboundForecastEntity,
  'agentCalls' | 'reducers' | 'presenteeism' | 'managementByContact'
>;

export const initialOutboundForecastForm: OutboundForecastForm = {
  campaign: { name: '', id: '' },
  // supervisors: [],
  tagsCampaign: [],
  target: { id: '', name: '' },
  hours: '',
  dateStart: '',
  dateEnd: '',
  daysOfCampaign: '',
  tmk: '',
  reSwept: '',
  presenteeism: '',
  effectiveManagement: '',
  amountTotalDataPerBase: '',
};

export type ForecastStatus = {
  isLoading: boolean;
  isDisabled: boolean;
  showResults: boolean;
  showInfoForm: boolean;
  showForm: boolean;
};

export const initialNewForecastStatus: ForecastStatus = {
  isLoading: false,
  isDisabled: false,
  showResults: false,
  showInfoForm: false,
  showForm: true,
};

export const initialSimulateForecastStatus: ForecastStatus = {
  isLoading: false,
  isDisabled: false,
  showResults: false,
  showInfoForm: true,
  showForm: true,
};
export interface MetricProps {
  mainValue: string | number;
  infoText: string;
  chipValue: string | number;
  showSeparator?: boolean;
  infoHelperText?: string;
}
export interface TmoItem {
  day: string;
  tmo: number;
}
export interface CallsAgentItem {
  agent_name: string;
  calls: number;
}
export interface CallsDayItem {
  day: string;
  calls: number;
}
export interface CallsTmoItem {
  valXAxis: number;
  tmoValue: number;
  totalValue: number;
}

export interface OutboundPlan {
  id: string;
  name: string;
  dateStart: string;
  dateEnd: string;
  objectiveResult: number;
  reducers: number;
  effectiveness: number;
  benchmark: string;
  sales: number;
  deviation: number;
  callTime: string;
  presenteeism: number;
  days: number;
  reSwept: number;
  agentHours: number;
  tmk: number;
  tmo: number;
  agentCalls: number;
  breakTime: string;
  agentSell: number;
  pauseTime: string;
  TimeLogin: string;
  acwTime: string;
  qtd_of_success_calls_per_day: EffectiveManagementData[];
  qtd_of_calls_per_agent: { agent: string; calls: number; agent_name: string }[];
  qtd_of_calls_per_day: { day: string; calls: number }[];
  qtd_of_calls_per_hour: { hour: number; calls: number }[];
  qtd_of_TMO_per_day: { day: string; tmo: number }[];
  qtd_of_TMO_per_hour: { hour: number; tmo: number }[];
  campaign: {
    id: string;
    name: string;
    campaignId: {
      created_at: string;
      updated_at: string | null;
      created_by: string;
      updated_by: string;
      deleted_by: string | null;
      id: string;
      name: string;
      platform_id: string;
      features: string;
      type: string;
      status: boolean;
      requirements: string;
      start_date: string;
    };
  };
  // supervisors: Supervisor[];
  tagsCampaign: TagCampaign[];
  target: { id: string; name: string };
  fields: Field[];
  settings: {
    id: string;
    numberOfCalls: boolean;
    numberOfProcedures: boolean;
    tmoCompliance: boolean;
    talkingTime: boolean;
    callDetails: boolean;
    effectiveManagement: boolean;
    numberOfAgents: boolean;
    operatorsTable: boolean;
  };
  hours: number;
  daysOfCampaign: number;
}
export type Field = {
  outboundToFieldId: number;
  outboundId: string;
  fieldId: string;
  value: number;
};
export enum FieldsId {
  PRESENTEEISM_ID = '16e64f28-7512-4ebf-a24b-8d4c8d992248',
  DAYS_OF_CAMPAIGN_ID = '2e93a07f-369b-4eaf-8da0-6cb64f4e412e',
  TMK_ID = '98350158-bc0a-4a60-922c-46d311aaa2d9',
  RESWEPT_ID = 'c09e8514-1695-4622-a43c-bdf85a87604f',
  EFFECTIVE_MANAGEMENT_ID = 'e9a29f23-bb0c-49c2-9f9e-4f65e654e0d0',
  AMOUNT_TOTAL_DATA_PER_BASE_ID = 'ec57cffc-3584-446b-8285-e7dfc743c707',
}
export interface PlanUpdateData {
  name: string;
  campaignId: string;
  startDate: Date;
  endDate: Date;
  objectiveResult: number;
  reducers: number;
  effectiveness: number;
  benchmark: string;
  hours: number;
  // supervisors: { id: string }[];
  tags: { id: string }[];
  targetId: { id: string };
  fields: { fieldId: string; value: number }[];
  settings: {
    id: string;
    numberOfCalls: boolean;
    numberOfProcedures: boolean;
    tmoCompliance: boolean;
    talkingTime: boolean;
    callDetails: boolean;
    effectiveManagement: boolean;
    numberOfAgents: boolean;
    operatorsTable: boolean;
  };
  deviation: number;
  callTime: string;
  presenteeism: number;
  days: number;
  reSwept: number;
  agentHours: number;
  tmk: number;
  agentCalls: number;
  break: string;
  agentSell: number;
  loginTime: string;
  pause: string;
  acw: string;
  tmo: number;
  qtd_of_success_calls_per_day: EffectiveManagementData[];
  qtd_of_calls_per_agent: { agent: string; calls: number }[];
  qtd_of_calls_per_day: { day: string; calls: number }[];
  qtd_of_calls_per_hour: { hour: number; calls: number }[];
}

export enum TargetName {
  DATOS_DIAS = 'Objetivo Datos/Dias',
  GESTIONS_DIAS = 'Objetivo Gestiones/Días',
  TMK_DIA = 'Objetivo Tmk/Dia',
  DATOS_TMK = 'Objetivo Datos/Tmk',
  GESTIONES_TMK = 'Objetivo Gestiones/Tmk',
}

export enum TargetTypes {
  TMK_DIA = 'Tmk/Dia',
  VENTAS_DIAS = 'Ventas/Dias',
  VENTAS_TMK = 'Ventas/Tmk',
  DATOS_DIAS = 'Datos/Dias',
  DATOS_TMK = 'Datos/Tmk',
}
export type OutboundInfoData = {
  campaign: Campaign;
  dateStart: string;
  dateEnd: string;
  // supervisors: Supervisor[];
  tagsCampaign: TagCampaign[];
  target: Target;
  hours: string;
  daysOfCampaign: string;
  effectiveManagement: string;
  tmk: string;
  reSwept: string;
  presenteeism: string;
  amountTotalDataPerBase: string;
};
