import { httpService } from 'modules/core/services/http.service';
import { ServiceQueryStringParamsType } from 'modules/shared/interfaces/shared.interface';
import { getRolesAdapter } from '../adapters';

export const getRoles = async (queryStringsParams?: ServiceQueryStringParamsType) => {
  const res = await httpService.get({
    url: 'role',
    params: queryStringsParams,
  });
  return getRolesAdapter(res.data);
};

export const createRole = async (data: any) => {
  const res = await httpService.post({
    url: 'role',
    payload: data,
  });

  return res.data;
};

export const updateRole = async (data: any, roleId: string) => {
  const res = await httpService.put({
    url: `role/${roleId}`,
    payload: data,
  });

  return res.data;
};

export const getRoleById = async (roleId: string) => {
  const res = await httpService.get({
    url: `role/${roleId}`
  });
  return getRolesAdapter(res.data);
};

export const getPermissions = async (queryStringsParams?: ServiceQueryStringParamsType) => {
  const res = await httpService.get({
    url: 'permissions/tree',
    params: queryStringsParams,
  });
  return getRolesAdapter(res.data);
};