import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from 'modules/shared/interfaces/shared.interface';
import { UserInterface, UserSelectedInterface, emptyUserSelectedModel } from 'modules/users/interfaces/users.interface';

interface UsersStoreInterface {
  loading: boolean;
  users: UserInterface[];
  selectedUser: UserSelectedInterface;
  meta: Meta;
}

const initialState: UsersStoreInterface = {
  loading: false,
  users: [],
  selectedUser: emptyUserSelectedModel,
  meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<UserInterface[]>) {
      state.users = action.payload;
    },
    setSelectedUser(state, action: PayloadAction<UserSelectedInterface>) {
      state.selectedUser = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
    setResetUsers(state) {
      state.users = [];
    },
  },
});

export const usersActions = usersSlice.actions;

export default usersSlice;
