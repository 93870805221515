import { Components } from '@mui/material';
import FiraSansExtraBold from 'fonts/FiraSans-ExtraBold.ttf';
import FiraSansSemiBold from 'fonts/FiraSans-SemiBold.ttf';
import FiraSansRegular from 'fonts/FiraSans-Regular.ttf';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  defaultProps: {},
  styleOverrides: `
    @font-face {
     font-family: 'Fira Sans';
     font-style: normal;
     font-display: swap;
     font-weight: 400;
     src: local('Fira Sans'), local('Fira Sans'), url(${FiraSansRegular}) format('truetype');
   }

    @font-face {
     font-family: 'Fira Sans';
     font-style: normal;
     font-display: swap;
     font-weight: 600;
     src: local('Fira Sans'), local('Fira Sans'), url(${FiraSansSemiBold}) format('truetype');
   }

     @font-face {
     font-family: 'Fira Sans';
     font-style: normal;
     font-display: swap;
     font-weight: 800;
     src: local('Fira Sans'), local('Fira Sans'), url(${FiraSansExtraBold}) format('truetype');
   }
  `,
};
