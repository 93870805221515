import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';
import { Slide, Fade } from '@mui/material';
import AuthFormAdornment from './components/adornment/Adornment.component';

interface SemanticOnlyFormProps {
  children: React.ReactNode;
}

const SemanticOnlyForm = ({ children }: SemanticOnlyFormProps) => (
  <form onSubmit={e => e.preventDefault()}>{children}</form>
);

const AuthFormLayout = () => {
  const [initiateTransitions, setInitiateTransitions] = useState(false);

  useEffect(() => {
    setInitiateTransitions(true);
  }, []);

  return (
    <AuthFormContainer>
      <SemanticOnlyForm>
        <FormContainer>
          <Slide in={initiateTransitions} timeout={400} direction="up">
            <FormWrapper>
              <Outlet />
            </FormWrapper>
          </Slide>
        </FormContainer>
      </SemanticOnlyForm>

      <Fade in={initiateTransitions} timeout={1500}>
        <AuthFormAdornmentContainer>
          <AuthFormAdornment />
        </AuthFormAdornmentContainer>
      </Fade>
    </AuthFormContainer>
  );
};

const AuthFormContainer = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: '4fr 6fr',
  },
}));

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[50],
  height: '100vh',
}));

const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(5),
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
  width: 504,
}));

const AuthFormAdornmentContainer = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default AuthFormLayout;
