import { httpService, HttpError } from 'modules/core/services/http.service';
import { RequestInterface } from 'modules/requests/interfaces/request.interface';
import { Meta } from 'modules/shared/interfaces/shared.interface';

export type Error = HttpError;

export const getRequests = async (employeeId: string, queryStringsParams?: any) => {
  const res = await httpService.get({
    url: `requests/ownRequests/${employeeId}`,
    params: queryStringsParams,
  });

  const { data }: { data: { data: RequestInterface[]; meta: Meta } } = res;
  return { data: data.data, meta: data.meta };
};

export const getRequestById = async (newsId: string) => {
  const res = await httpService.get({
    url: `requests/${newsId}`,
  });

  const { data }: { data: RequestInterface } = res;
  return data;
};

export const createRequest = async (request: any) => {
  const res = await httpService.post({
    url: 'requests',
    payload: request,
  });
  const { data }: { data: RequestInterface } = res;
  return data;
};

export const updateRequest = async (id: string, payload: any) => {
  const res = await httpService.put({
    url: `requests/${id}`,
    payload: payload,
  });

  const { data }: { data: RequestInterface } = res;
  return data;
};

export const deleteRequest = async (id: string) => {
  const res = await httpService.delete({
    url: `requests/${id}`,
  });

  const { data }: { data: RequestInterface } = res;
  return data;
};

export const uploadFile = async (id: string, fileList: FileList) => {
  const formData = new FormData();
  formData.append('file', fileList[0]);
  const res = await httpService.post({
    url: `requests/${id}/file`,
    payload: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });

  const { data }: { data: RequestInterface } = res;
  return data;
};

export const uploadMassiveFile = async (fileList: FileList) => {
  const formData = new FormData();
  formData.append('file', fileList[0]);
  const res = await httpService.post({
    url: `requests/import`,
    payload: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });

  return res.data;
};

const getFileURL = process.env.REACT_APP_API_URL;

export const getFile = async (name: string, token: string) => {
  const fileData = await fetch(`${getFileURL}/requests/file/${name}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
    }),
  });
  const fileBlob = await fileData.blob();
  if (fileBlob) {
    const url = window.URL.createObjectURL(fileBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
};

export const updateRequestStatus = async (id: string, statusId: string) => {
  const res = await httpService.patch({
    url: `requests/${id}/status/${statusId}`,
  });

  const { data }: { data: RequestInterface } = res;
  return data;
};
export const updateAllRequestsStatusSelected = async (id: string[], statusId: string) => {
  const res = await Promise.all(
    id.map(async requestId => {
      const data = await httpService.patch({
        url: `requests/${requestId}/status/${statusId}`,
      });
      return data;
    }),
  );
  return res;
};

export const getRequestsRevisions = async (queryStringsParams?: any) => {
  const res = await httpService.get({
    url: 'requests/revisions',
    params: queryStringsParams,
  });

  const { data }: { data: { data: RequestInterface[]; meta: Meta } } = res;
  return { data: data.data, meta: data.meta };
};

export const getChargedRequests = async (queryStringsParams?: any) => {
  const res = await httpService.get({
    url: 'requests/charged',
    params: queryStringsParams,
  });

  const { data }: { data: { data: RequestInterface[]; meta: Meta } } = res;
  return { data: data.data, meta: data.meta };
};

export const cancelRequest = async (id: string) => {
  const res = await httpService.patch({
    url: `requests/${id}`,
  });

  const { data }: { data: RequestInterface } = res;
  return data;
};

export const getSupervisorsByEmployee = async (id: string) => {
  const res = await httpService.get({
    url: `/requests/supervisors/${id}`,
  });

  const { data }: { data: any[] } = res;
  return data;
};
