import { ReactNode } from 'react';
import { styled, Theme, alpha } from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InsightsIcon from '@mui/icons-material/Insights';
import PersonIcon from '@mui/icons-material/Person';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

interface YourResultProps {
  necessaryAgents: number | string;
  shrinkagePercentageIncluded: number | string;
  serviceLevelPercentage: number | string;
  responseTime: number | string;
  averageResponseSpeed: number | string;
  occupationPercentage: number | string;
  totalCalls: number | string;
  elevation?: number;
}

interface StatisticItemProps {
  colored: boolean;
  value?: number | string;
  caption?: string;
  icon?: ReactNode;
  theme?: Theme;
}

const StatisticItem = ({ value, caption, icon, colored }: StatisticItemProps) => (
  <StatisticItemContainer colored={colored}>
    <Typography variant="h3" color={colored ? 'secondary' : 'inherit'} fontWeight="inherit">
      {value}
    </Typography>
    <StatisticCaption>
      {icon}
      <Typography variant="caption" color="inherit">
        {caption}
      </Typography>
    </StatisticCaption>
  </StatisticItemContainer>
);

export const YourResult = ({
  necessaryAgents,
  shrinkagePercentageIncluded,
  serviceLevelPercentage,
  responseTime,
  averageResponseSpeed,
  occupationPercentage,
  totalCalls,
  elevation = 1,
}: YourResultProps) => {
  return (
    <ResultContainer elevation={elevation}>
      <IconContainer>
        <InsightsIcon fontSize="inherit" color="secondary" />
      </IconContainer>
      <ResultContent>
        <Typography variant="h6" color="secondary">
          TU RESULTADO
        </Typography>
        <Typography color="textSecondary">
          El número de agentes necesarios es de {necessaryAgents} agentes, incluído el {shrinkagePercentageIncluded}% de
          contracción.
          <br />
          Esto da un Nivel de Servicio del {serviceLevelPercentage}% contestado en {responseTime} segundos con una
          Velocidad Media de Respuesta (ASA) de {averageResponseSpeed} segundos.
        </Typography>
        <StatisticsContainer>
          <StatisticItem
            colored={true}
            caption="AGENTES"
            value={necessaryAgents}
            icon={<PersonIcon fontSize="small" color="inherit" />}
          />
          <Divider orientation="vertical" flexItem />
          <StatisticItem
            colored={false}
            caption="NIVEL SERVICIO"
            value={`${serviceLevelPercentage}%`}
            icon={<HeadsetMicIcon fontSize="small" color="inherit" />}
          />
          <Divider orientation="vertical" flexItem />
          <StatisticItem
            colored={false}
            caption="OCUPACIÓN"
            value={`${occupationPercentage}%`}
            icon={<DataUsageIcon fontSize="small" color="inherit" />}
          />
          <Divider orientation="vertical" flexItem />
          <StatisticItem
            colored={false}
            caption="LLAMADAS"
            value={totalCalls}
            icon={<LocalPhoneIcon fontSize="small" color="inherit" />}
          />
        </StatisticsContainer>
      </ResultContent>
    </ResultContainer>
  );
};

const ResultContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  maxWidth: 1448,
  padding: theme.spacing(7.5, 26, 12, 10.5),
}));

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  fontSize: 44,
}));

const ResultContent = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(1),
}));

const StatisticsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(8),
  width: '100%',
  alignSelf: 'center',
}));

const StatisticItemContainer = styled('div', {
  shouldForwardProp: prop => prop != 'colored',
})(({ colored, theme }: StatisticItemProps) => ({
  fontWeight: 'normal',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: colored ? theme?.palette.secondary.dark : alpha(theme?.palette.common.black as string, 0.6),
  '& svg': {
    color: colored ? theme?.palette.secondary.dark : alpha(theme?.palette.common.black as string, 0.87),
  },
}));

const StatisticCaption = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  color: 'inherit',
}));
