import { httpService } from 'modules/core/services/http.service';
import { getCampaignByIdAdapter } from '../adapters';

export type IdCampaignType = string;

export const getCampaignById = async (idCampaign: IdCampaignType) => {
  const res = await httpService.get({
    url: `campaigns/${idCampaign}`,
  });

  return getCampaignByIdAdapter(res.data);
};
