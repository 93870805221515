import { ArrowCircleUp } from '@mui/icons-material';
import { Box, Drawer, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import Link from 'modules/shared/components/link/Link.component';
import { Dispatch, SetStateAction, useRef } from 'react';
import LogoTransition from './components/logoTransition/LogoTransition.component';
import MenuList from './components/menuList/MenuList.component';

interface AppMenuProps {
  expand: boolean;
  setExpand: Dispatch<SetStateAction<boolean>>;
  onSelection: () => void;
  onArrowClick: () => void;
}

const AppMenu = ({ expand, onSelection, onArrowClick, setExpand }: AppMenuProps) => {
  const theme = useTheme();
  const matchSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef<null | HTMLDivElement>(null);

  return (
    <Drawer
      variant="permanent"
      open={expand}
      sx={{
        '& .MuiDrawer-paper': {
          borderRadius: '0',
          bottom: '0',
          height: 'auto',
          backgroundColor: '#4A90FF',
        },
      }}
    >
      <MenuContent
        ref={ref}
        matchSizeSmall={matchSizeSmall}
        expand={expand}
        sx={
          matchSizeSmall
            ? {
                maxWidth: '180px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '20px',
                overflow: 'hidden',
              }
            : {
                overflow: 'hidden',
              }
        }
      >
        <Box>
          <StyledLink href="/">
            <LogoTransition expand={expand} />
          </StyledLink>
          <MenuList onSelection={onSelection} expand={expand} setExpand={setExpand} />
        </Box>
        {matchSizeSmall && (
          <Box
            sx={{ display: 'flex', gap: '10px', paddingBottom: '30px', paddingLeft: '15px' }}
            onClick={() => {
              ref.current?.scrollIntoView({ behavior: 'auto' });

              onArrowClick();
            }}
          >
            <ArrowCircleUp />
            <Typography>subir</Typography>
          </Box>
        )}
      </MenuContent>
    </Drawer>
  );
};

const MenuContent = styled('div', { shouldForwardProp: props => props !== 'expand' && props !== 'matchSizeSmall' })(
  ({ expand, matchSizeSmall }: { expand: boolean; matchSizeSmall: boolean }) => ({
    paddingTop: !matchSizeSmall ? 0 : 80,
    transition: 'all 250ms',
    width: expand ? 256 : matchSizeSmall ? 1 : 64,
  }),
);

export const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
});

export default AppMenu;
