import { Theme } from '@mui/material';
import { Avatar as MuiAvatar, Badge, styled, Typography, IconButton } from '@mui/material';
import Notifications from '../Notifications.component';
import FlechaAbajo from 'assets/icons/navbar/FlechaAbajo.svg';
import Settings from '../settings/Settings.component';
import { useAuth } from 'modules/auth/AuthProvider.component';
import Iconify from 'modules/shared/components/iconify';
interface FullName {
  firstName: string;
  lastName: string;
}

interface ProfileProps {
  picture: string;
  status: 'online' | 'without-activity' | 'disconnected';
  fullName: FullName;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Profile = ({ fullName, onClick, /* picture, */ status = 'online' }: ProfileProps) => {
  const name = `${fullName.lastName}, ${fullName.firstName}`;
  const initials = `${fullName.lastName.slice(0, 1)}${fullName.firstName.slice(0, 1)}`;
  const { user, userImage } = useAuth();
  const { configurator_access } = user?.role || {};

  return (
    <ProfileContainer role="button" tabIndex={0}>
      {configurator_access && <Settings />}
      <Notifications />
      <AvatarBadge
        status={status}
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
        {!userImage ? (
          <Avatar alt={name}>{initials}</Avatar>
        ) : (
          <Avatar src={userImage ? URL.createObjectURL(userImage) : ''} alt={user?.firstName}>
            <Iconify icon="solar:user-bold" width="100%" />
          </Avatar>
        )}
      </AvatarBadge>

      <Typography variant="subtitle2">{name}</Typography>
      <IconButton onClick={onClick} aria-label="dropdown" sx={{ padding: '0px' }}>
        <img src={FlechaAbajo} />
      </IconButton>
    </ProfileContainer>
  );
};

const ProfileContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const AvatarBadge = styled(Badge)(({ theme, status }: { theme?: Theme; status: ProfileProps['status'] }) => {
  const badgeColor =
    status === 'online'
      ? theme?.palette.success.main
      : status === 'without-activity'
      ? theme?.palette.warning.main
      : theme?.palette.grey[500];
  return {
    cursor: 'pointer',
    borderLeft: '1px solid #D8D8D8',
    paddingLeft: '1rem',
    '& .MuiBadge-badge': {
      backgroundColor: badgeColor,
      color: badgeColor,
      boxShadow: `0 0 0 2px ${theme?.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        border: '1px solid currentColor',
        content: '""',
      },
    },
  };
});

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  backgroundColor: theme.brandColors.oceanBlue,
}));

export default Profile;
