import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SchedulableEmployee, SchedulerInterface } from 'modules/scheduler/interfaces/Scheduler.interface';
import { Meta } from 'modules/shared/interfaces';
import { ShiftInterface } from 'modules/shifts/interfaces/shifts.interface';
import { EventInput } from '@fullcalendar/core';
import { AssistedSchedulerData } from 'modules/scheduler/interfaces/AssistedScheduler.interface';

export interface Planner {
  id: string;
  names: string;
  surenames: string;
}
export interface SchedulableData {
  employees: SchedulableEmployee[];
  shifts: ShiftInterface[];
}

export interface SchedulableDataResponse {
  scheduler: SchedulerInterface;
  shifts: ShiftInterface[];
}
interface SchedulerSlice {
  loading: boolean;
  schedulers: SchedulerInterface[];
  selectedScheduler: SchedulableDataResponse;
  schedulableData: SchedulableData;
  assistedSchedulerData: AssistedSchedulerData;
  events: EventInput[];
  planners: Planner[];
  meta: Meta;
}

const initialState: SchedulerSlice = {
  loading: false,
  meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
  schedulers: [],
  selectedScheduler: { scheduler: {} as SchedulerInterface, shifts: [] },
  schedulableData: {} as SchedulableData,
  assistedSchedulerData: {} as AssistedSchedulerData,
  events: [],
  planners: [],
};

export const schedulerSlices = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
    setSchedulers(state, action: PayloadAction<SchedulerInterface[]>) {
      state.schedulers = action.payload;
    },
    setSelectedScheduler(state, action: PayloadAction<SchedulableDataResponse>) {
      state.selectedScheduler = action.payload;
    },
    setSchedulableEmployees(state, action: PayloadAction<SchedulableData>) {
      state.schedulableData = action.payload;
    },
    setAssistedSchedulerData(state, action: PayloadAction<AssistedSchedulerData>) {
      state.assistedSchedulerData = action.payload;
    },
    createUpdateEvents(state, action: PayloadAction<EventInput[]>) {
      state.events = action.payload;
    },
    setPlanners(state, action: PayloadAction<Planner[]>) {
      state.planners = action.payload;
    },
    resetAssistedSchedulerData(state) {
      state.assistedSchedulerData = {} as AssistedSchedulerData;
    },
    resetSelectedScheduler(state) {
      state.selectedScheduler = { scheduler: {} as SchedulerInterface, shifts: [] };
    },
  },
});

export const schedulersActions = schedulerSlices.actions;

export default schedulerSlices;
