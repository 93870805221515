import { CheckboxProps } from '@mui/material';
import { CheckboxIcon, CheckboxCheckedIcon, CheckboxIndeterminateIcon } from './CustomIcons';
import { Components } from '@mui/material';

export const MuiCheckbox: Components['MuiCheckbox'] = {
  defaultProps: {
    icon: <CheckboxIcon />,
    checkedIcon: <CheckboxCheckedIcon />,
    indeterminateIcon: <CheckboxIndeterminateIcon />,
  },

  styleOverrides: {
    root: ({ ownerState }: { ownerState: CheckboxProps }) => ({
      padding: '8px',
      ...(ownerState.size === 'small' && {
        '& svg': { width: 20, height: 20 },
        padding: '5px',
      }),
      ...(ownerState.size === 'medium' && {
        '& svg': { width: 24, height: 24 },
      }),
    }),
  },
};
