import colors from './colors';
import typography from './typography';
import zIndices from './z-index';

const foundations = {
  zIndices,
  colors,
  typography,
};

export default foundations;
