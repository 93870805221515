export const getDataUserAdapter = (data: any) => {
  // TODO: switch between dataSanitized and data in order to see changes field user selection. REMOVE when back is already
  const dataSanitized = data;
  dataSanitized.ability.push({
    id: 'f914e922-9429-46eb-826e-bd94696fc796FF',
    name: 'charge_on_behalf',
    action: 'charge_on_behalf',
    PermissionObject: {
      id: 'c9e32aa0-34c8-4779-bd82-ab926f0109deFF',
      name: 'license',
    },
  });
  return dataSanitized;
  // return data;
};
