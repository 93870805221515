import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import ToolTipFormCalculator from './ToolTipFormCalculator';

interface PropsFormInputCalculator {
  hideBlockInput?: boolean;
  labelTextInformation: string;
  textAfterInput: string;
  textToolTip?: string;
  children: React.ReactNode;
}

const FormInputCalculator = ({
  hideBlockInput,
  labelTextInformation,
  textAfterInput,
  textToolTip = '',
  children,
}: PropsFormInputCalculator) => {
  return (
    <>
      {!hideBlockInput && (
        <ContainerBlockInput>
          <GroupInformationBlockInput>
            <Typography sx={{ fontWeight: 'bold' }}>{labelTextInformation}</Typography>
            <ToolTipFormCalculator text={textToolTip} />
          </GroupInformationBlockInput>

          <GroupBlockInput>
            {children}
            <TextThirBlockInput>{textAfterInput}</TextThirBlockInput>
          </GroupBlockInput>
        </ContainerBlockInput>
      )}
    </>
  );
};

export default FormInputCalculator;

const TextThirBlockInput = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));

const ContainerBlockInput = styled('div')(() => ({
  display: 'flex',
  gap: '84px',
  width: '37%',
}));

const GroupBlockInput = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
}));

const GroupInformationBlockInput = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  width: '178px',
}));
