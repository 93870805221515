/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import {
  CampaignInScheduler,
  PopupProps,
  QuantityDaysProps,
  SchedulerContextType,
  SchedulerTypes,
  SelectedDaysProps,
} from 'modules/scheduler/interfaces/Scheduler.interface';
import { popupFormModel, quantityDaysModel, selectedDayModel } from 'modules/scheduler/utils';
import { CampaignInterface } from 'modules/shared/interfaces';
import { ConflictInterface } from 'modules/scheduler/interfaces/Conflict.interface';

export const SchedulerContext = createContext<SchedulerContextType>({
  popupForm: { name: '', end_date: '', start_date: '', type: SchedulerTypes.MANUAL, campaign: {} as CampaignInterface },
  setPopupForm: () => {},
  quantityDays: {
    daysToSchedule: 0,
    historicalDays: 0,
  },
  setQuantityDays: () => {},
  resetPopupForm: () => {},
  selectedDays: {
    firstDay: '',
    lastDay: '',
    counterForLastSaturday: 0,
    totalDaysToSchedule: 0,
    mondayOfTheWeek: new Date(),
    indexFirstDay: 0,
    indexLastDay: 0,
    weeksBeingScheduled: 0,
  },
  setSelectedDays: () => {},
  selectedCampaign: {} as CampaignInterface,
  setSelectedCampaign: () => {},
  conflicts: [],
  setConflicts: () => {},
  resetSelectedDays: () => {},
  employeeCounts: [],
  setEmployeeCounts: () => {},
});

export const useSchedulerContext = () => {
  const context = useContext(SchedulerContext);

  if (!context) throw new Error('useSchedulerContext must be use inside SchedulerContextProvider');
  return context;
};

export const SchedulerProvider = ({ children }: { children: React.ReactNode }) => {
  const [popupForm, setPopupForm] = useState<PopupProps>(popupFormModel);
  const [quantityDays, setQuantityDays] = useState<QuantityDaysProps>(quantityDaysModel);
  const [selectedDays, setSelectedDays] = useState<SelectedDaysProps>(selectedDayModel);
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignInScheduler>({} as CampaignInScheduler);
  const [conflicts, setConflicts] = useState<ConflictInterface[]>([]);
  const [employeeCounts, setEmployeeCounts] = useState<number[]>([]);

  const resetPopupForm = useCallback(() => {
    setPopupForm(popupFormModel);
  }, []);

  const resetSelectedDays = useCallback(() => {
    setSelectedDays(selectedDayModel);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      popupForm,
      setPopupForm,
      quantityDays,
      setQuantityDays,
      resetPopupForm,
      selectedDays,
      setSelectedDays,
      selectedCampaign,
      setSelectedCampaign,
      conflicts,
      setConflicts,
      resetSelectedDays,
      employeeCounts,
      setEmployeeCounts,
    }),
    [
      resetPopupForm,
      popupForm,
      setPopupForm,
      quantityDays,
      setQuantityDays,
      selectedDays,
      setSelectedDays,
      selectedCampaign,
      setSelectedCampaign,
      conflicts,
      setConflicts,
      resetSelectedDays,
      employeeCounts,
      setEmployeeCounts,
    ],
  );
  return <SchedulerContext.Provider value={memoizedValue}>{children}</SchedulerContext.Provider>;
};
