import React, { createContext, RefObject, useRef, ReactNode } from 'react';

interface HeaderContextProps {
  headerRef: RefObject<HTMLDivElement> | null;
}

export const HeaderContext = createContext<HeaderContextProps>({
  headerRef: null,
});

interface HeaderProviderProps {
  children: ReactNode;
}

export const HeaderProvider = ({ children }: HeaderProviderProps) => {
  const headerRef = useRef<HTMLDivElement>(null);

  return <HeaderContext.Provider value={{ headerRef }}>{children}</HeaderContext.Provider>;
};

export default HeaderProvider;
