import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface LinkProps extends MuiLinkProps {
  children: React.ReactNode;
  href: string;
}

const Link = ({ children, href, ...rest }: LinkProps) => {
  return (
    <MuiLink component={RouterLink} to={href} {...rest}>
      {children}
    </MuiLink>
  );
};

export default Link;
