import { httpService } from 'modules/core/services/http.service';

export const uploadFile = async (id: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const res = await httpService.post({
    url: `employees/${id}/image`,
    payload: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });

  return res;
};

export const getFile = async (name: string) => {
  try {
    // Ensure the responseType is set to 'blob' to handle binary data
    const response = await httpService.get({
      url: `employees/${name}/image`,
      config: {
        responseType: 'blob',
      },
    });

    // Check if the response is successful
    if (!response || response.status !== 200) {
      throw new Error(`Failed to fetch image: ${response.status}`);
    }

    const blob = response.data;

    // Create a File object from the blob
    const file = new File([blob], name, { type: blob.type });

    return file;
  } catch (error) {
    throw new Error(`Error fetching image: ${error}`);
  }
};
