import {
  IconButton,
  Backdrop,
  Modal as MuiModal,
  Fade,
  Typography,
  ModalProps as MuiModalProps,
  styled,
  Box,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import Button from '../button/Button.component';

export enum TypeModal {
  SUCCESS = 'success',
  ERROR = 'error',
}
interface ModalProps extends Partial<MuiModalProps> {
  isLoadingBtnConfirm: boolean;
  open: boolean;
  showBtnClose?: boolean;
  customBackground?: string;
  showFooter?: boolean;
  onConfirm: () => void;
  onClose: () => void;
  type?: TypeModal;
  textBtnConfirm?: string;
  confirmIsDisabled?: boolean;
  hasError?: boolean;
  title: string;
  formatedTitle?: React.ReactNode;
}

const Modal = ({
  type = TypeModal.SUCCESS,
  textBtnConfirm = 'Confirmar',
  isLoadingBtnConfirm,
  confirmIsDisabled,
  customBackground,
  showBtnClose,
  showFooter = true,
  hasError = false,
  open,
  onConfirm,
  onClose,
  title,
  children,
  formatedTitle,
  ...props
}: ModalProps) => {
  return (
    <ModalStyled
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      {...props}
    >
      <Fade in={open}>
        <Paper sx={{ backgroundColor: customBackground ? customBackground : '#fff' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h5" component="h2">
              {formatedTitle ? formatedTitle : title}
            </Typography>
            {showBtnClose && (
              <IconButton aria-label="cerrar modal" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>

          <ModalContent>{children}</ModalContent>
          {showFooter && (
            <ModalActions>
              <Button variant="outlined" color="info" sx={{ minWidth: '137px' }} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                isLoading={isLoadingBtnConfirm}
                disabled={confirmIsDisabled}
                variant="contained"
                sx={{ minWidth: '137px' }}
                color={type === 'success' ? 'info' : 'warning'}
                onClick={hasError ? onClose : onConfirm}
              >
                {textBtnConfirm}
              </Button>
            </ModalActions>
          )}
        </Paper>
      </Fade>
    </ModalStyled>
  );
};

const Paper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  padding: theme.spacing(6),
  borderRadius: 16,
  [theme.breakpoints.up('md')]: {
    minWidth: 854,
  },
}));

const ModalContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
}));

const ModalActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(4),
  marginTop: '40px !important',
}));

const ModalStyled = styled(MuiModal)(() => ({
  '.MuiBackdrop-root ~ div': {
    '.MuiBox-root': {
      button: {
        backgroundColor: '#4A90FF',
        color: 'white',
        width: '21.344px',
        height: '21.333px',
        svg: {
          padding: '3.5px',
        },
      },
    },
  },
}));

export default Modal;
