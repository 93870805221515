import { splitEvery } from 'ramda';

import { NavigateFunction } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { HeadCell } from 'modules/shared/components/table/components/EnhancedTableHead.component';
import { BodyCell } from 'modules/shared/components/table/Table.component';

// interfaces
import {
  OutboundForecasts,
  FieldsForm,
  FieldTargetForm,
  TmoItem,
  CallsTmoItem,
  CallsDayItem,
  OutboundPlan,
  OutboundForecastForm,
  OutboundForecastConfigForm,
  outboundQueryParams,
  TargetTypes,
} from './interfaces/outbound.interface';
import { OperatorEntity, Charts } from 'modules/shared/interfaces/shared.interface';

// utils
import { convertMsToTime, formatDate, FormatDateOutputDash } from 'utils';
import { DeeplyReadonly, Query } from '@cubejs-client/core';
import { SimulationQueryParams } from './services';
import { getCubeJSData, NO_RESULTS } from './adapters/getCubeJSData.adapter';
import { Dispatch, SetStateAction } from 'react';

interface ForecastListProps {
  outboundForecast: OutboundForecasts[];
  navigate: NavigateFunction;
  targetName: TargetTypes;
}
interface DashboardListProps {
  operators: OperatorEntity[];
  handleOpenModal: (id: string) => void;
}

export enum GroupBy {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum Mode {
  DETAIL = 'detail',
  SUM = 'sum',
}

export const forecastToHeadCell = (forecasts: OutboundForecasts[], targetName: string): HeadCell[] => {
  let dataByTarget = {
    name: '',
    id: '',
  };
  switch (targetName) {
    case 'Datos/Dias':
    case 'Datos/Tmk':
    case 'Tmk/Dia':
      dataByTarget = {
        name: 'Gestiones Efectivas',
        id: 'effectiveCalls',
      };
      break;
    case 'Ventas/Dias':
      dataByTarget = {
        name: 'TMK',
        id: 'tmk',
      };
      break;
    case 'Ventas/Tmk':
      dataByTarget = {
        name: 'Días',
        id: 'days',
      };
      break;
    default:
      dataByTarget = {
        name: 'Gestiones Efectivas',
        id: 'effectiveCalls',
      };
  }

  const availableData: string[] = [];
  let headers = [
    {
      id: 'nameForecast',
      label: '',
      numeric: false,
      sortable: false,
    },
    {
      id: dataByTarget.id,
      label: dataByTarget.name,
      numeric: false,
      sortable: false,
    },
    {
      id: 'deviation',
      label: 'Desviación',
      numeric: false,
      sortable: false,
    },
    {
      id: 'days',
      label: 'Días',
      numeric: false,
      sortable: false,
    },
    {
      id: 'tmk',
      label: 'TMK',
      numeric: false,
      sortable: false,
    },
    {
      id: 'agentHours',
      label: 'Atendidas/Horas',
      numeric: false,
      sortable: false,
    },
    {
      id: 'agentCalls',
      label: 'Atendidas/Llamadas',
      numeric: false,
      sortable: false,
    },
    {
      id: 'agentSale',
      label: 'Gestiones/Atendidas',
      numeric: false,
      sortable: false,
    },
    {
      id: 'TimeLogin',
      label: 'Tiempo Login',
      numeric: false,
      sortable: false,
    },
    {
      id: 'reducers',
      label: 'Reductores',
      numeric: false,
      sortable: false,
    },
    {
      id: 'actions',
      label: '',
      numeric: false,
      sortable: false,
    },
  ];

  if (forecasts[0]) {
    const selectedForecast = forecasts[0];
    Object.keys(selectedForecast).map((key: string) => {
      if (selectedForecast[key as keyof typeof selectedForecast] !== undefined) {
        availableData.push(key);
      }
    });
  }

  headers = headers.filter(header => availableData.some(d => d === header.id) || header.id === 'actions');

  if (targetName === 'Ventas/Tmk') {
    headers.splice(3, 1);
  }
  if (targetName === 'Ventas/Dias') {
    headers.splice(4, 1);
  }

  return headers;
};
export const headCellsOperators: HeadCell[] = [
  {
    id: 'operators',
    label: 'Operadores',
    numeric: false,
    sortable: false,
  },
  {
    id: 'calls',
    label: 'Llamadas',
    numeric: false,
    sortable: false,
  },
  {
    id: 'callsHour',
    label: 'Llamadas/Hora',
    numeric: false,
    sortable: false,
  },
  {
    id: 'effectiveManagement',
    label: 'Gestiones efectivas',
    numeric: false,
    sortable: false,
  },
  {
    id: 'timeLogin',
    label: 'Tiempo Login',
    numeric: false,
    sortable: false,
  },
  {
    id: 'reducers',
    label: 'Reductores',
    numeric: false,
    sortable: false,
  },
  {
    id: 'contacts',
    label: 'Contactos',
    numeric: false,
    sortable: false,
  },
  {
    id: 'talkingTime',
    label: 'Talking Time',
    numeric: false,
    sortable: false,
  },
  {
    id: 'action',
    label: '',
    numeric: false,
    sortable: false,
  },
];

export const operators: OperatorEntity[] = [
  {
    id: '1',
    firstname: 'Ana',
    lastname: 'Berrios',
    calls: 436,
    callsHour: 12,
    effectiveManagement: 124,
    timeLogin: 135050,
    reducers: 156050,
    contacts: 75,
    talkingTime: 735240,
  },
  {
    id: '2',
    firstname: 'Ana',
    lastname: 'Berrios',
    calls: 436,
    callsHour: 12,
    effectiveManagement: 124,
    timeLogin: 135050,
    reducers: 156050,
    contacts: 75,
    talkingTime: 735240,
  },
  {
    id: '3',
    firstname: 'Ana',
    lastname: 'Berrios',
    calls: 436,
    callsHour: 12,
    effectiveManagement: 124,
    timeLogin: 135050,
    reducers: 156050,
    contacts: 75,
    talkingTime: 735240,
  },
  {
    id: '4',
    firstname: 'Ana',
    lastname: 'Berrios',
    calls: 436,
    callsHour: 12,
    effectiveManagement: 124,
    timeLogin: 135050,
    reducers: 999999,
    contacts: 75,
    talkingTime: 735240,
  },
  {
    id: '5',
    firstname: 'Ana',
    lastname: 'Berrios',
    calls: 436,
    callsHour: 12,
    effectiveManagement: 124,
    timeLogin: 135050,
    reducers: 156050,
    contacts: 75,
    talkingTime: 735240,
  },
  {
    id: '6',
    firstname: 'Ana',
    lastname: 'Berrios',
    calls: 436,
    callsHour: 12,
    effectiveManagement: 124,
    timeLogin: 135050,
    reducers: 156050,
    contacts: 75,
    talkingTime: 735240,
  },
  {
    id: '7',
    firstname: 'Ana',
    lastname: 'Berrios',
    calls: 436,
    callsHour: 12,
    effectiveManagement: 124,
    timeLogin: 135050,
    reducers: 156050,
    contacts: 75,
    talkingTime: 735240,
  },
  {
    id: '8',
    firstname: 'Ana',
    lastname: 'Berrios',
    calls: 436,
    callsHour: 12,
    effectiveManagement: 124,
    timeLogin: 135050,
    reducers: 156050,
    contacts: 75,
    talkingTime: 735240,
  },
  {
    id: '9',
    firstname: 'Ana',
    lastname: 'Berrios',
    calls: 436,
    callsHour: 12,
    effectiveManagement: 124,
    timeLogin: 135050,
    reducers: 156050,
    contacts: 75,
    talkingTime: 735240,
  },
  {
    id: '10',
    firstname: 'Ana',
    lastname: 'Berrios',
    calls: 436,
    callsHour: 12,
    effectiveManagement: 124,
    timeLogin: 135050,
    reducers: 156050,
    contacts: 75,
    talkingTime: 735240,
  },
];

export const OperatorsToBodyCell: ({ operators, handleOpenModal }: DashboardListProps) => BodyCell[][] = ({
  operators,
  handleOpenModal,
}: DashboardListProps) => {
  const response: BodyCell[][] = operators.map((operator: OperatorEntity) => {
    const parsedRequest: BodyCell[] = [
      {
        displayType: 'text',
        text: `${operator.firstname} ${operator.lastname}`,
      },
      {
        displayType: 'text',
        text: String(operator.calls),
      },
      {
        displayType: 'text',
        text: convertMsToTime(operator.callsHour),
      },
      {
        displayType: 'text',
        text: String(operator.effectiveManagement),
      },
      {
        displayType: 'text',
        text: convertMsToTime(operator.timeLogin),
      },
      {
        displayType: 'text',
        text: convertMsToTime(operator.reducers),
      },
      {
        displayType: 'text',
        text: String(operator.contacts),
      },
      {
        displayType: 'text',
        text: convertMsToTime(operator.talkingTime),
      },
      { displayType: 'iconButton', icon: <VisibilityIcon />, action: () => handleOpenModal(operator.id) },
    ];
    return parsedRequest;
  });
  return response;
};

export const forecastToBodyCell: ({ outboundForecast, navigate, targetName }: ForecastListProps) => BodyCell[][] = ({
  outboundForecast,
  navigate,
  targetName,
}: ForecastListProps) => {
  let _outboundForecast = outboundForecast;

  const decimalToPercentage = (decimal: number): string => {
    const percentage: number = decimal * 100;
    const formattedPercentage: string = percentage.toFixed(3);
    return `${formattedPercentage}%`;
  };

  const getTargetField = (forecast: OutboundForecasts) => {
    switch (targetName) {
      case 'Datos/Dias':
      case 'Datos/Tmk':
      case 'Tmk/Dia':
        return forecast.effectiveCalls;
      case 'Ventas/Dias':
        return forecast.tmk;
      case 'Ventas/Tmk':
        return forecast.days;
      default:
        return forecast.effectiveCalls;
    }
  };

  const sortBy = (currentItem: OutboundForecasts, nextItem: OutboundForecasts, order: 'asc' | 'desc', key: 'tmk') => {
    const currentValue = currentItem[key];
    const nextValue = nextItem[key];

    if (order === 'asc') {
      if (currentValue < nextValue) {
        return -1;
      }
      if (currentValue > nextValue) {
        return 1;
      }

      return 0;
    } else {
      if (currentValue > nextValue) {
        return -1;
      }
      if (currentValue < nextValue) {
        return 1;
      }

      return 0;
    }
  };

  // VENTAS_DIAS = Gestiones efectivas
  if (targetName === TargetTypes.VENTAS_DIAS) {
    _outboundForecast = [..._outboundForecast].sort((currentItem: OutboundForecasts, nextItem: OutboundForecasts) =>
      sortBy(currentItem, nextItem, 'asc', 'tmk'),
    );
  }
  const response: BodyCell[][] = _outboundForecast.map((forecast: OutboundForecasts) => {
    const parsedRequest: BodyCell[] = [
      {
        displayType: 'text',
        text: String(forecast.nameForecast),
      },
      {
        displayType: 'text',
        text: String(getTargetField(forecast)),
        backgroundColor: '#0063CB4D',
      },
      {
        displayType: 'text',
        text: forecast.deviation ? `${getFloatString(forecast.deviation, 2)}%` : '0%',
      },
      {
        displayType: 'text',
        text: forecast.days !== 0 ? String(forecast.days) : '-',
      },
      { displayType: 'text', text: forecast.tmk !== 0 ? String(forecast.tmk) : '-' },
      {
        displayType: 'text',
        text: String(forecast.agentHours),
      },
      {
        displayType: 'text',
        text: decimalToPercentage(forecast.agentCalls),
      },
      {
        displayType: 'text',
        text: decimalToPercentage(forecast.agentSale),
      },
      {
        displayType: 'text',
        text: String(forecast.TimeLogin),
      },
      {
        displayType: 'text',
        text: decimalToPercentage(forecast.reducers),
      },
      {
        displayType: 'textButton',
        text: 'simular',
        color: 'primary',
        variant: 'contained',
        action: () => navigate(`/campaigns/outbound/simulation/${forecast.id}`),
      },
    ];

    if (targetName === 'Ventas/Tmk') {
      parsedRequest.splice(3, 1);
    }

    if (targetName === 'Ventas/Dias') {
      parsedRequest.splice(4, 1);
    }

    return parsedRequest.filter(r => r.text !== 'undefined');
  });
  return response;
};

export const getHelperText = (fieldName: string) => {
  let textToReturn = '';
  if (fieldName.toLowerCase() === 'dias de campaña') textToReturn = 'Cantidad de días hábiles que trabaja la campaña';
  if (fieldName.toLowerCase() === 'cantidad de datos totales por base')
    textToReturn = 'Cantidad de datos totales disponibles por campaña';
  if (fieldName.toLowerCase() === 'tmk') textToReturn = 'Cantidad de operadores asignados para esta campaña';
  if (fieldName.toLowerCase() === 'gestiones efectivas') textToReturn = 'Gestiones efectivas pronosticadas';

  if (fieldName === 'Gestiones efectivas')
    textToReturn = 'Cantidad de gestiones efectivas necesarias para llegar al objetivo';
  if (fieldName.toLowerCase() === 'rebarrido') textToReturn = 'Promedio de cantidad de toques por registro';
  if (fieldName.toLowerCase() === 'presentismo') textToReturn = 'Ratio entre 0 y 1 del reductor correspondiente';
  if (fieldName.toLowerCase() === 'desviación') textToReturn = '';
  if (fieldName.toLowerCase() === 'atendidas/horas') textToReturn = 'Llamadas atendidas por hora totales';
  if (fieldName.toLowerCase() === 'atendidas/llamadas')
    textToReturn = 'Llamadas atendidas sobre llamadas realizadas totales';
  if (fieldName.toLowerCase() === 'gestiones/atendidas')
    textToReturn = 'Gestiones efectivas sobre cantidad de llamadas atendidas totales';
  if (fieldName.toLowerCase() === 'reductores') textToReturn = 'Reductores considerados para hacer el cálculo';
  if (fieldName.toLowerCase() === 'tiempo login') textToReturn = 'Sumatoria de tiempo de logueo neto por operador';
  if (fieldName.toLowerCase() === 'tiempo llamada')
    textToReturn = 'Sumatoria de tiempo de conversación neto por operador';
  if (fieldName.toLowerCase() === 'break') textToReturn = 'Sumatoria de este reductor';
  if (fieldName.toLowerCase() === 'pausa') textToReturn = 'Sumatoria de este reductor';
  if (fieldName.toLowerCase() === 'acw') textToReturn = 'Sumatoria de este reductor';

  return textToReturn;
};

export const getNameField = (fieldName: string): FieldsForm => {
  let nameFieldToReturn: FieldsForm = 'daysOfCampaign';
  if (fieldName === 'Dias de Campaña') nameFieldToReturn = 'daysOfCampaign';
  if (fieldName === 'Cantidad de datos totales por base') nameFieldToReturn = 'amountTotalDataPerBase';
  if (fieldName === 'Tmk') nameFieldToReturn = 'tmk';
  if (fieldName === 'Gestiones efectivas') nameFieldToReturn = 'effectiveManagement';
  if (fieldName === 'Rebarrido') nameFieldToReturn = 'reSwept';
  if (fieldName === 'Presentismo') nameFieldToReturn = 'presenteeism';

  return nameFieldToReturn;
};

export const fieldExists = (
  keyField: 'daysOfCampaign' | 'amountTotalDataPerBase' | 'tmk' | 'effectiveManagement' | 'reSwept' | 'presenteeism',
  fieldsTarget: FieldTargetForm[],
): boolean => {
  const map = {
    daysOfCampaign: 'Dias de campaña',
    amountTotalDataPerBase: 'Cantidad de datos totales por base',
    tmk: 'Tmk',
    reSwept: 'Rebarrido',
    effectiveManagement: 'Gestiones efectivas',
    presenteeism: 'Presentismo',
  };

  const result = fieldsTarget.findIndex(field => field.name.toLowerCase() === map[keyField].toLowerCase());

  if (result !== -1) {
    return true;
  } else {
    return false;
  }
};

export const getCallsTMOdata = (callsData: CallsDayItem[], tmoData: TmoItem[]): CallsTmoItem[] => {
  const finalArray: CallsTmoItem[] = [];
  callsData.map((callsPerDayData, i) => {
    finalArray.push({
      valXAxis: i + 1,
      tmoValue: tmoData[i].tmo,
      totalValue: callsPerDayData.calls,
    });
  });
  return finalArray;
};

export const dataTimeAdapter = (data: CallsTmoItem[], groupBy: GroupBy, mode: Mode): CallsTmoItem[] => {
  let dataToReturn: CallsTmoItem[] = [];
  switch (groupBy) {
    case GroupBy.DAY: {
      dataToReturn = data.map((item, index: number) => ({
        valXAxis: index + 1,
        tmoValue: item.tmoValue,
        totalValue: item.totalValue,
      }));
      break;
    }
    case GroupBy.WEEK: {
      const dividedData = splitEvery(7, data);
      dataToReturn = dividedData.reduce(
        (prev: CallsTmoItem[], current: CallsTmoItem[], index: number): CallsTmoItem[] => {
          const item: CallsTmoItem = { valXAxis: index + 1, tmoValue: 0, totalValue: 0 };
          current.map(cItem => {
            item.tmoValue += cItem.tmoValue;
            item.totalValue += cItem.totalValue;
          });
          prev.push(item);
          return prev;
        },
        [],
      );
      break;
    }
    case GroupBy.MONTH: {
      const dividedData = splitEvery(30, data);
      dataToReturn = dividedData.reduce(
        (prev: CallsTmoItem[], current: CallsTmoItem[], index: number): CallsTmoItem[] => {
          const item: CallsTmoItem = { valXAxis: index + 1, tmoValue: 0, totalValue: 0 };
          current.map(cItem => {
            item.tmoValue += cItem.tmoValue;
            item.totalValue += cItem.totalValue;
          });
          prev.push(item);
          return prev;
        },
        [],
      );
      break;
    }
    default:
      break;
  }

  if (mode === Mode.SUM) {
    const accumulated = dataToReturn.reduce(
      (prev: CallsTmoItem[], current: CallsTmoItem, index: number): CallsTmoItem[] => {
        if (index === 0) {
          prev.push(current);
        } else {
          prev.push({
            valXAxis: current.valXAxis,
            tmoValue: current.tmoValue + prev[index - 1].tmoValue,
            totalValue: current.totalValue + prev[index - 1].totalValue,
          });
        }
        return prev;
      },
      [],
    );
    return accumulated;
  } else {
    return dataToReturn;
  }
};

export const getTextXAxisEffectiveChart = (text: GroupBy) => {
  switch (text) {
    case GroupBy.DAY:
      return 'Día';
    case GroupBy.WEEK:
      return 'Semana';
    case GroupBy.MONTH:
      return 'Mes';
    default:
      return '';
  }
};
export interface cubeJSQueryProps {
  chartName: string;
  campaignId: string;
  startDate?: string;
  endDate?: string;
}

export const getTargetName = (targetName: string): TargetTypes => {
  switch (targetName) {
    case 'Datos/Dias':
      return TargetTypes.DATOS_DIAS;
    case 'Datos/Agente':
      return TargetTypes.DATOS_TMK;
    case 'Gestiones/Días':
      return TargetTypes.VENTAS_DIAS;
    case 'Gestiones/Agente':
      return TargetTypes.VENTAS_TMK;
    case 'Agente/Dia':
      return TargetTypes.TMK_DIA;
    default:
      return TargetTypes.TMK_DIA;
  }
};

export const getForecastParams = (outboundForm: OutboundForecastForm, target: string): outboundQueryParams => {
  const outboundParams: outboundQueryParams = {
    campaignId: outboundForm?.campaign?.platform_id || '',
    hours: parseInt(outboundForm.hours),
    reSwept: parseInt(outboundForm.reSwept),
    target: target,
  };

  switch (target) {
    case 'Tmk/Dia':
      outboundParams.tmk = Number(outboundForm.tmk);
      outboundParams.days = parseInt(outboundForm.daysOfCampaign);
      break;
    case 'Ventas/Dias':
      outboundParams.effectiveCalls = Number(outboundForm.effectiveManagement);
      outboundParams.days = parseInt(outboundForm.daysOfCampaign);
      break;
    case 'Ventas/Tmk':
      outboundParams.tmk = Number(outboundForm.tmk);
      outboundParams.effectiveCalls = Number(outboundForm.effectiveManagement);
      break;
    case 'Datos/Dias':
      outboundParams.days = parseInt(outboundForm.daysOfCampaign);
      outboundParams.totalData = parseInt(outboundForm.amountTotalDataPerBase);
      break;
    case 'Datos/Tmk':
      outboundParams.tmk = Number(outboundForm.tmk);
      outboundParams.totalData = parseInt(outboundForm.amountTotalDataPerBase);
      break;
    default:
      break;
  }
  return outboundParams;
};

export const getSimulationParams = (
  forecastId: number,
  firstOutboundForm: OutboundForecastForm,
  simulationDataForm: OutboundForecastConfigForm,
  target: string,
): SimulationQueryParams => {
  const simulationParams: SimulationQueryParams = {
    reducers: simulationDataForm.reducers,
    agentCalls: simulationDataForm.agentCalls,
    presenteeism: simulationDataForm.presenteeism,
    effectiveness: simulationDataForm.managementByContact,
    forecastId: forecastId,
    campaignId: firstOutboundForm.campaign.platform_id ? parseInt(firstOutboundForm.campaign.platform_id) : 1,
    target: target,
    hours: parseInt(firstOutboundForm.hours),
    reSwept: parseInt(firstOutboundForm.reSwept),
  };

  switch (target) {
    case 'Tmk/Dia':
      simulationParams.tmk = firstOutboundForm.tmk ? Number(firstOutboundForm.tmk) : 1;
      simulationParams.days = firstOutboundForm.daysOfCampaign ? parseInt(firstOutboundForm.daysOfCampaign) : 1;
      break;
    case 'Ventas/Dias':
      simulationParams.effectiveCalls = firstOutboundForm.effectiveManagement
        ? Number(firstOutboundForm.effectiveManagement)
        : 1;
      simulationParams.days = firstOutboundForm.daysOfCampaign ? parseInt(firstOutboundForm.daysOfCampaign) : 1;
      break;
    case 'Ventas/Tmk':
      simulationParams.tmk = firstOutboundForm.tmk ? Number(firstOutboundForm.tmk) : 1;
      simulationParams.effectiveCalls = firstOutboundForm.effectiveManagement
        ? Number(firstOutboundForm.effectiveManagement)
        : 1;
      break;
    case 'Datos/Dias':
      simulationParams.days = firstOutboundForm.daysOfCampaign ? parseInt(firstOutboundForm.daysOfCampaign) : 1;
      simulationParams.totalData = firstOutboundForm.amountTotalDataPerBase
        ? parseInt(firstOutboundForm.amountTotalDataPerBase)
        : 1;
      break;
    case 'Datos/Tmk':
      simulationParams.tmk = firstOutboundForm.tmk ? Number(firstOutboundForm.tmk) : 1;
      simulationParams.totalData = firstOutboundForm.amountTotalDataPerBase
        ? parseInt(firstOutboundForm.amountTotalDataPerBase)
        : 1;
      break;
    default:
      break;
  }
  return simulationParams;
};

const getCallsQuery = (campaignId: string, startDate: string, endDate: string): Query => {
  return {
    limit: 5000,
    filters: [
      {
        member: 'Calls.idCampana',
        operator: 'equals',
        values: [campaignId],
      },
      {
        member: 'Calls.dateRange',
        operator: 'inDateRange',
        values: [startDate, endDate],
      },
    ],
    measures: ['Calls.sumQuantity'],
  };
};

const getCallsQueryRelative = (campaignId: string, outboundId: string, gte: string, lte: string): Query => {
  return {
    limit: 5000,
    order: {
      'Calls.sumQuantity': 'desc',
    },
    filters: [
      {
        member: 'Outbound.campaignId',
        operator: 'equals',
        values: [campaignId],
      },
      {
        member: 'OutboundCallsPerDay.day',
        operator: 'gte',
        values: [gte],
      },
      {
        member: 'OutboundCallsPerDay.day',
        operator: 'lte',
        values: [lte],
      },
      {
        member: 'Outbound.id',
        operator: 'equals',
        values: [outboundId],
      },
    ],
    dimensions: ['OutboundCallsPerDay.calls'],
  };
};

const getCallsQueryAbsolute = (campaignId: string, outboundId: string): Query => {
  return {
    limit: 5000,
    order: {
      'Calls.sumQuantity': 'desc',
    },
    filters: [
      {
        member: 'Outbound.campaignId',
        operator: 'equals',
        values: [campaignId],
      },
      {
        member: 'Outbound.id',
        operator: 'equals',
        values: [outboundId],
      },
    ],
    dimensions: ['OutboundCallsPerDayAbsolute.calls'],
  };
};

const getEffectiveManagementQuery = (
  planData: OutboundPlan,
  startDate: string,
  endDate: string,
  granularity: TimeAgregation,
  dayNumber1: number,
  dayNumber2: number,
): Query => {
  return {
    measures: ['EffectiveManagment.sumEfectivas'],
    order: {
      'EffectiveManagment.sumEfectivas': 'desc',
    },
    dimensions: [
      'Campaign.name',
      'CampaignOutbound.campaignId',
      'OutboundSuccessCallsPerDay.calls',
      'OutboundSucessCallsPerDayAbsolute.calls',
    ],
    filters: [
      {
        member: 'Outbound.campaignId',
        operator: 'equals',
        values: [planData.campaign.id],
      },
      {
        member: 'EffectiveManagment.dateRange',
        operator: 'inDateRange',
        values: [startDate, endDate],
      },
      {
        member: 'OutboundSuccessCallsPerDay.day',
        operator: 'gte',
        values: [dayNumber1.toString()],
      },
      {
        member: 'OutboundSuccessCallsPerDay.day',
        operator: 'lte',
        values: [dayNumber2.toString()],
      },
      {
        member: 'Outbound.id',
        operator: 'equals',
        values: [planData.id],
      },
    ],
    limit: 5000,
  };
};

const getTmoQuery = (planData: OutboundPlan): Query => {
  const formattedDateStart = formatDate(planData.dateStart, FormatDateOutputDash.YYYYMMDD) || planData.dateStart;
  const formattedDateEnd = formatDate(planData.dateEnd, FormatDateOutputDash.YYYYMMDD) || planData.dateEnd;
  return {
    measures: ['TmoView.ratio'],
    filters: [
      {
        member: 'TmoView.campaignId',
        operator: 'equals',
        values: [planData.campaign.campaignId.platform_id],
      },
      {
        member: 'TmoView.date',
        operator: 'inDateRange',
        values: [formattedDateStart, formattedDateEnd],
      },
    ],
  };
};
const getTmoQueryAbsolute = (planData: OutboundPlan): Query => {
  return {
    dimensions: ['Outbound.tmo'],
    filters: [
      {
        member: 'Outbound.id',
        operator: 'equals',
        values: [planData.id],
      },
    ],
  };
};

const getAverageTimeConversationMetric = (planData: OutboundPlan): Query => {
  return {
    dimensions: ['Campaign.name', 'CampaignOutbound.campaignId', 'Outbound.callTime'],
    order: {
      'UserStats.dateRange': 'asc',
    },
    measures: ['UserStats.outboundTalkTimeSumInTime', 'UserStats.outboundTalkTimeAvgInTime'],
    filters: [
      {
        member: 'Outbound.campaignId',
        operator: 'equals',
        values: [planData.campaign.id],
      },
      {
        member: 'Outbound.id',
        operator: 'equals',
        values: [planData.fields[0].outboundId],
      },
      {
        member: 'Outbound.id',
        operator: 'equals',
        values: [planData.id],
      },
    ],
  };
};
const getRealAverageTimeConversationMetric = (platformId: string, startDate: string, endDate: string): Query => {
  return {
    measures: ['UserStats.tiempoPromedioConversacion'],
    order: {
      'UserStats.dateRange': 'asc',
    },
    filters: [
      {
        member: 'UserStats.idCampana',
        operator: 'equals',
        values: [platformId],
      },
      {
        member: 'UserStats.dateRange',
        operator: 'inDateRange',
        values: [startDate, endDate],
      },
    ],
  };
};

const getCallsChart = (
  planData: OutboundPlan,
  startDate: string,
  endDate: string,
  granularity: TimeAgregation,
): Query => {
  return {
    measures: ['Calls.asweredSum', 'Calls.abandonedSum', 'Calls.sumQuantity'],
    timeDimensions: [
      {
        dimension: 'Calls.dateRange',
        granularity: granularity,
      },
    ],
    order: {
      'Calls.asweredCount': 'desc',
    },
    dimensions: ['Campaign.name', 'CampaignOutbound.campaignId'],
    filters: [
      {
        member: 'Outbound.campaignId',
        operator: 'equals',
        values: [planData.campaign.id],
      },
      {
        member: 'Calls.dateRange',
        operator: 'inDateRange',
        values: [startDate, endDate],
      },
      {
        member: 'Outbound.id',
        operator: 'equals',
        values: [planData.id],
      },
    ],
  };
};

const getHeadcountQuery = (
  planData: OutboundPlan,
  startDate: string,
  endDate: string,
  granularity: TimeAgregation,
): Query => {
  return {
    timeDimensions: [
      {
        dimension: 'LogInOut.dateRange',
        granularity: granularity,
      },
    ],
    order: {
      'LogInOut.userCount': 'desc',
    },
    filters: [
      {
        member: 'Outbound.campaignId',
        operator: 'equals',
        values: [planData.campaign.id],
      },
      {
        member: 'LogInOut.dateRange',
        operator: 'inDateRange',
        values: [startDate, endDate],
      },
    ],
    measures: ['LogInOut.userCount', 'Outbound.tmkMax'],
    dimensions: ['LogInOut.idCampana'],
  };
};

const getOperatorsQuery = (planData: OutboundPlan, startDate: string, endDate: string): Query => {
  return {
    order: {
      'OperatorsReport.llamadasSum': 'desc',
    },
    dimensions: ['OperatorsReport.operadores', 'OperatorsReport.operador_id'],
    measures: [
      'OperatorsReport.llamadasSum',
      'OperatorsReport.llamadasHoraSum',
      'OperatorsReport.gestionesEfectivasSum',
      'OperatorsReport.tiempoLoginSumTime',
      'OperatorsReport.reductoresSumTime',
      'OperatorsReport.contactosSum',
      'OperatorsReport.talkingTimeSumTime',
    ],
    filters: [
      {
        member: 'OperatorsReport.idCampana',
        operator: 'equals',
        values: [planData.campaign.campaignId.platform_id],
      },
      {
        member: 'OperatorsReport.fecha',
        operator: 'inDateRange',
        values: [startDate, endDate],
      },
    ],
  };
};

const getEffectiveManagementChartQuery1 = (
  planData: OutboundPlan,
  startDate: string,
  endDate: string,
  granularity: TimeAgregation,
): Query => {
  return {
    measures: ['EffectiveManagment.sumEfectivas'],
    timeDimensions: [
      {
        dimension: 'EffectiveManagment.dateRange',
        granularity: granularity,
      },
    ],
    order: {
      'EffectiveManagment.dateRange': 'asc',
    },
    filters: [
      {
        member: 'Outbound.campaignId',
        operator: 'equals',
        values: [planData.campaign.id],
      },
      {
        member: 'Outbound.id',
        operator: 'equals',
        values: [planData.id],
      },
      {
        member: 'EffectiveManagment.dateRange',
        operator: 'inDateRange',
        values: [startDate, endDate],
      },
    ],
  };
};

const getEffectiveManagementChartQuery2 = (planData: OutboundPlan, dayNumber1: number, dayNumber2: number): Query => {
  return {
    dimensions: ['OutboundSuccessCallsPerDayByDay.day', 'OutboundSuccessCallsPerDayByDay.calls'],
    order: {
      'OutboundSuccessCallsPerDayByDay.day': 'asc',
    },
    filters: [
      {
        member: 'OutboundSuccessCallsPerDayByDay.id',
        operator: 'equals',
        values: [planData.id],
      },
      {
        member: 'OutboundSuccessCallsPerDayByDay.day',
        operator: 'gte',
        values: [dayNumber1.toString()],
      },
      {
        member: 'OutboundSuccessCallsPerDayByDay.day',
        operator: 'lte',
        values: [dayNumber2.toString()],
      },
    ],
  };
};

export enum TimeAgregation {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

const getDaysDifference = (date1: string, date2: string) => {
  const difference = new Date(date1).getTime() - new Date(date2).getTime();
  return difference ? Math.ceil(difference / (1000 * 3600 * 24)) : 0;
};

export const getCubeJSQuery = (
  planData: OutboundPlan,
  startDate: string,
  endDate: string,
  granularity: TimeAgregation,
  chart: Charts,
): DeeplyReadonly<Query> => {
  const campaignStartDate = planData.dateStart;
  const formattedStartDate = formatDate(startDate, FormatDateOutputDash.YYYYMMDD) || '';
  const formattedEndDate = formatDate(endDate, FormatDateOutputDash.YYYYMMDD) || '';
  //DAYNUMBER 1 Y 2 SON EL NUMERO DE DIA DE CAMPAÑA SELECCIONADO POR EL USER CONTANDO DESDE EL PRIMER DIA DE CAMPAÑA
  const dayNumber1 = getDaysDifference(startDate, campaignStartDate) || 1;
  const dayNumber2 = getDaysDifference(endDate, campaignStartDate) || 1;

  switch (chart) {
    case Charts.CALLS_METRIC:
      return getCallsQuery(planData.campaign.campaignId.platform_id, formattedStartDate, formattedEndDate);
    case Charts.CALLS_METRIC_RELATIVE:
      return getCallsQueryRelative(planData.campaign.id, planData.id, `${dayNumber1}`, `${dayNumber2}`);
    case Charts.CALLS_METRIC_ABSOLUTE:
      return getCallsQueryAbsolute(planData.campaign.id, planData.id);
    case Charts.EFFECTIVE_MANAGEMENT_METRIC:
      return getEffectiveManagementQuery(
        planData,
        formattedStartDate,
        formattedEndDate,
        granularity,
        dayNumber1,
        dayNumber2,
      );
    case Charts.TMO_METRIC:
      return getTmoQuery(planData);

    case Charts.TMO_METRIC_ABSOLUTE:
      return getTmoQueryAbsolute(planData);

    case Charts.AVERAGE_TIME_CONVERSATION_METRIC:
      return getAverageTimeConversationMetric(planData);
    case Charts.REAL_AVERAGE_TIME_CONVERSATION_METRIC:
      return getRealAverageTimeConversationMetric(
        planData.campaign.campaignId.platform_id,
        formattedStartDate,
        formattedEndDate,
      );
    case Charts.CALLS_CHART:
      return getCallsChart(planData, formattedStartDate, formattedEndDate, granularity);

    case Charts.EFFECTIVE_MANAGEMENT_CHART1:
      return getEffectiveManagementChartQuery1(planData, formattedStartDate, formattedEndDate, granularity);
    case Charts.EFFECTIVE_MANAGEMENT_CHART2:
      return getEffectiveManagementChartQuery2(planData, dayNumber1, dayNumber2);
    case Charts.HEADCOUNT:
      return getHeadcountQuery(planData, formattedStartDate, formattedEndDate, granularity);
    case Charts.OPERATORS_TABLE:
      return getOperatorsQuery(planData, formattedStartDate, formattedEndDate);
    default:
      return {};
  }
};

export const accumulatedata = (data: any[], valuesToAdd: string[]) => {
  const accumulated = data.reduce((prev: any[], current: any, index: number): any[] => {
    if (index === 0) {
      prev.push(current);
    } else {
      const objToReturn: any = { ...current };
      valuesToAdd.map(valueToAdd => {
        objToReturn[valueToAdd] = current[valueToAdd] + prev[index - 1][valueToAdd];
      });
      prev.push(objToReturn);
    }
    return prev;
  }, []);
  return accumulated;
};

export const callCubeJS = async (
  query: DeeplyReadonly<Query>,
  cubeApi: any,
  chart: Charts,
  setter: (data: any) => void,
  errorFunction: (chart: Charts) => void,
  setOperatorDetailQuery?: (query: any) => void,
  setOpenDetailModal?: Dispatch<SetStateAction<boolean>>,
  setOperatorDetailData?: Dispatch<SetStateAction<any>>,
) => {
  try {
    const loadedQuery = await cubeApi.load(query);
    const parsedData = getCubeJSData(
      loadedQuery.rawData(),
      chart,
      setOperatorDetailQuery,
      setOpenDetailModal,
      setOperatorDetailData,
    );
    setter(parsedData);
  } catch (e) {
    errorFunction(chart);
  }
};

export const getLoading = (settings: any, errors: any, chartsData: any): boolean => {
  const loadingCallsMetric = settings.numberOfCalls && !errors[Charts.CALLS_CHART] && !chartsData.callsData;
  const loadingEffectiveMetric =
    settings.numberOfProcedures && !errors[Charts.EFFECTIVE_MANAGEMENT_METRIC] && !chartsData.effectiveManagementData;
  const loadingTMO = settings.tmoCompliance && !errors[Charts.TMO_METRIC] && !chartsData.tmoData;
  const loadingAverageTime =
    settings.talkingTime && !errors[Charts.AVERAGE_TIME_CONVERSATION_METRIC] && !chartsData.averageTimeConversationData;
  const loadinCallsChart = settings.callDetails && !errors[Charts.CALLS_CHART] && !chartsData.callsChartData;
  const loadingEffectiveChart =
    settings.effectiveManagement &&
    !errors[Charts.EFFECTIVE_MANAGEMENT_CHART1] &&
    !errors[Charts.EFFECTIVE_MANAGEMENT_CHART2] &&
    !chartsData.effectiveManagementChartData;
  const loadingHeadcount = settings.numberOfAgents && !errors[Charts.HEADCOUNT] && !chartsData.headcountData;

  return (
    loadingCallsMetric ||
    loadingEffectiveMetric ||
    loadingTMO ||
    loadingAverageTime ||
    loadinCallsChart ||
    loadingEffectiveChart ||
    loadingHeadcount
  );
};

export const getNoResults = (settings: OutboundPlan['settings'], data: any) => {
  const emptyCalls = !settings.numberOfCalls || data.callsData === NO_RESULTS;
  const emptyEffective = !settings.numberOfProcedures || data.effectiveManagementData === NO_RESULTS;
  const emptyTMO = !settings.tmoCompliance || data.tmoData === NO_RESULTS;
  const emptyTalkingTime = !settings.talkingTime || data.averageTimeConversationData === NO_RESULTS;
  const emptyCallsChart = !settings.callDetails || data.callsChartData === NO_RESULTS;
  const emptyEffectiveChart = !settings.effectiveManagement || data.effectiveManagementChartData === NO_RESULTS;
  const emptyHeadCount = !settings.numberOfAgents || data.headcountData === NO_RESULTS;
  return (
    emptyCalls &&
    emptyEffective &&
    emptyTMO &&
    emptyTalkingTime &&
    emptyCallsChart &&
    emptyEffectiveChart &&
    emptyHeadCount
  );
};

export const getFloatString = (number: number, decimalDigits: number) => {
  // .toFixed() rounds values of 99.99% to 100% so it won't work for deviation and other porpuses
  const stringNumber = number.toString();
  if (stringNumber.includes('.') && decimalDigits > 0) {
    const trimmedNumberInt = stringNumber.split('.')[0];
    const trimmedNumberDecimals = stringNumber.split('.')[1].slice(0, decimalDigits);
    return trimmedNumberInt + '.' + trimmedNumberDecimals;
  } else {
    return stringNumber;
  }
};

export const operatorsHeadCells = [
  {
    id: 'operadores',
    label: 'Operadores',
    numeric: false,
    sortable: false,
  },
  {
    id: 'llamadas',
    label: 'Llamadas',
    numeric: false,
    sortable: false,
  },
  {
    id: 'llamadas/hora',
    label: 'Llamadas/Hora',
    numeric: false,
    sortable: false,
  },
  {
    id: 'gestionesEfectivas',
    label: 'Gestiones efectivas',
    numeric: false,
    sortable: false,
  },
  {
    id: 'tiempoLogin',
    label: 'Tiempo Login',
    numeric: false,
    sortable: false,
  },
  {
    id: 'reductores',
    label: 'Reductores',
    numeric: false,
    sortable: false,
  },
  {
    id: 'contactos',
    label: 'Contactos',
    numeric: false,
    sortable: false,
  },
  {
    id: 'talkingTime',
    label: 'Talking Time',
    numeric: false,
    sortable: false,
  },
  // {
  //   id: 'actions',
  //   label: '',
  //   numeric: false,
  //   sortable: false,
  // },
];

export const operatorDetailHeadCells = [
  {
    id: 'semana',
    label: '',
    numeric: false,
    sortable: false,
  },
  {
    id: 'llamadas',
    label: 'Llamadas',
    numeric: false,
    sortable: false,
  },
  {
    id: 'llamadas/hora',
    label: 'Llamadas/Hora',
    numeric: false,
    sortable: false,
  },
  {
    id: 'gestionesEfectivas',
    label: 'Gestiones Efectivas',
    numeric: false,
    sortable: false,
  },
  {
    id: 'tiempoLogin',
    label: 'Tiempo de Login',
    numeric: false,
    sortable: false,
  },
  {
    id: 'contactos',
    label: 'Contactos',
    numeric: false,
    sortable: false,
  },
  {
    id: 'talkingTime',
    label: 'Talking Time',
    numeric: false,
    sortable: false,
  },
  {
    id: 'breakTime',
    label: 'Descanso',
    numeric: false,
    sortable: false,
  },
  {
    id: 'adminTime',
    label: 'Tiempo Administrativo',
    numeric: false,
    sortable: false,
  },
  {
    id: 'bathroom',
    label: 'Baño',
    numeric: false,
    sortable: false,
  },
  {
    id: 'coaching',
    label: 'Coaching',
    numeric: false,
    sortable: false,
  },
  {
    id: 'ACW',
    label: 'ACW',
    numeric: false,
    sortable: false,
  },
  {
    id: 'RRSS',
    label: 'RRSS',
    numeric: false,
    sortable: false,
  },
  {
    id: 'RRSS2',
    label: 'RRSS2',
    numeric: false,
    sortable: false,
  },
  {
    id: 'dialTime',
    label: 'Tiempo de marcado',
    numeric: false,
    sortable: false,
  },
  {
    id: 'atentionTime',
    label: 'Tiempo de atención',
    numeric: false,
    sortable: false,
  },
  {
    id: 'previewCRMTime',
    label: 'Tiempo de preview CRM',
    numeric: false,
    sortable: false,
  },
  {
    id: 'postviewCRMTime',
    label: 'Tiempo de postview CRM',
    numeric: false,
    sortable: false,
  },
  {
    id: 'otherCRMTime',
    label: 'Tiempo Otros CRM',
    numeric: false,
    sortable: false,
  },
  {
    id: 'freeTime',
    label: 'Tiempo Ocioso',
    numeric: false,
    sortable: false,
  },
];
