import { RequestInterface } from 'modules/requests/interfaces/request.interface';
import { Member, Meta } from 'modules/shared/interfaces';
import { UserInterface } from 'modules/users/interfaces/users.interface';

export interface Dashboard {
  supervised_user_requests: {
    data: RequestInterface[];
  };
  requestUser: {
    data: RequestInterface[];
  };
  user: UserDashboardInterface;
}

export interface UserDashboardInterface extends UserInterface {
  team?: TeamDashboardInterface;
}

export interface TeamDashboardInterface {
  id: string;
  name: string;
  members: Member[];
  status: boolean;
  member_count: number;
  manager?: { id: string; names: string; surenames: string };
  campaigns?: campaignInDashboardInterface[];
}

export interface ActionsButtonPills {
  type: dashboardType;
  view: dashboardOptions | string;
  action: (view: dashboardOptions) => void;
}

export interface countLoginSynergyInterface {
  fecha: string;
  usuarios: number;
}

export interface DashboardSliceInterface {
  loading: boolean;
  data: Dashboard;
  selectedCampaign: campaignInDashboardInterface;
  campaigns: campaignInDashboardInterface[];
  hardtarget: HardTarget | string;
  birthdays: {
    data: BirthdayInterface[];
    meta: Meta;
  };
}

export enum dashboardType {
  OPERATOR = 'dashboard_operador',
  FULL = 'dashboard_operador_full',
  SUPERVISOR = 'dashboard_supervisor',
  RRHH = 'dashboard_rrhh',
}
export enum permissionsType {
  DASHBOARD = 'dashboard',
}

export type dashboardOptions = 'rrhh' | 'full' | 'supervisor' | 'operador' | 'personal';

export interface campaignInDashboardInterface {
  id: string;
  name: string;
  type: string;
  platform_id: string;
  hardtarget: HardTarget;
  members_list: MemberInDashboard[];
}

export interface MemberInDashboard extends Member {
  userId: string;
  platform_id: string;
  username: string;
}

export interface HardTarget {
  dashboard_start_date: string;
  dashboard_target_effective_calls: number;
  current_effective_calls?: number;
  dashboard_target_effective_calls_manager?: number;
  updated_at?: string;
}

export interface OperatorDashboardInterface {
  full: boolean;
  isSupervisorOrRRHH: boolean;
}

export const emptyHardTargets: HardTarget = {
  dashboard_start_date: '',
  dashboard_target_effective_calls: 0,
  dashboard_target_effective_calls_manager: 0,
  updated_at: '',
};

export const emptySelectedCampaign: campaignInDashboardInterface = {
  id: '',
  name: '',
  type: '',
  platform_id: '',
  hardtarget: emptyHardTargets,
  members_list: [],
};

type item = {
  valXAxis: number;
  abandonedValue: number;
  successValue: number;
  totalValue: number;
};
export interface CallsChartProps {
  data: item[] | string;
  sx?: object;
  rootName: string;
  totalOperatorCalls: number;
  EffectiveOperatorCalls: number;
  averageTimeConversation: number;
}

export interface CallsChartOperatorsProps {
  data: item[] | string;
  sx?: object;
  rootName: string;
  totalOperatorsCalls: number;
  EffectiveOperatorsCalls: number;
  averageTimeConversation: number;
}

export interface BirthdayInterface {
  id: string;
  names: string;
  surenames: string;
  dateOfBirth: string;
  photo_name: string;
  photo_buffer: string;
}
