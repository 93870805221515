export interface ShiftEntity {
  id?: string;
  name: string;
  // SHIFT
  shift_type: ShiftsTypes | '';
  periods: Period[];
  period_rotate_each?: RotationTypes | '';
  period_start: string;
  period_end?: string | null;
  status: boolean;
  period_count: number;
  // DESCANSO
  resting_type: IntervalsTypes | '';
  resting_period_rotate_each?: RotationTypes;
  resting_period_amount?: string;
  resting_days: string;

  // BREAKS
  breaks: Break[];

  // HOURS
  hourly_rate?: number;
  percentage_hourly_rate?: number;
  total_days_periods: number;
  total_hours_periods: number;
  total_minutes_periods: number;
  start_date_period?: number;
}

export type ShiftInterface = Omit<ShiftEntity, 'total_days_periods' | 'total_hours_periods' | 'total_minutes_periods'>;

export enum ShiftsTypes {
  REGULAR = 'regular',
  ROTATIVO = 'rotativo',
  PARTIDO = 'partido',
}

export enum IntervalsTypes {
  REGULAR = 'regular',
  ROTATIVO = 'rotativo',
}

export interface Day {
  id: number;
  name: string;
  work: boolean;
  ingress: Date | string | null;
  egress: Date | string | null;
  work_hours?: number;
  work_minutes?: number;
}

export interface Period {
  id: number;
  days: Day[];
  work_days?: number;
  work_hours?: number;
  work_minutes?: number;
}

export interface Interval {
  id: string;
  name: string;
  category: string;
  duration: string;
}

interface Break {
  id: string;
  name: string;
  category: string;
  duration: string;
}

export enum RotationTypes {
  WEEK = 'week',
  FORTNIGHT = 'fortnight',
  MONTH = 'month',
}
