import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import ToolTipFormCalculator from './ToolTipFormCalculator';

interface PropsTextFieldInformation {
  labelTextInformation?: string;
  textAfterInput?: string;
  textToolTip?: string;
  children?: React.ReactNode;
}

const TextFieldInformationCalculator = ({
  labelTextInformation,
  textAfterInput = '',
  textToolTip = '',
  children,
}: PropsTextFieldInformation): JSX.Element => {
  return (
    <>
      <Container>
        <Information>
          <Title>{labelTextInformation}</Title>
          <ToolTipFormCalculator text={textToolTip} />
        </Information>
        <GroupTextField>
          {children}
          <TextAfterInput>{textAfterInput}</TextAfterInput>
        </GroupTextField>
      </Container>
    </>
  );
};

export default TextFieldInformationCalculator;

const Container = styled('div')(() => ({
  display: 'flex',
  gap: '54px',
  alignItems: 'center',
}));

const Information = styled('div')(() => ({
  display: 'flex',
  gap: 2,
  alignItems: 'center',
  width: '182px',
}));

const GroupTextField = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
}));

const TextAfterInput = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));

const Title = styled(Typography)(() => ({
  fontWeight: 'bold',
}));
