import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CampaignInterface,
  Meta,
  SelectedCampaignInterface,
  emptyCampaignModel,
  Skill,
  Tag,
} from '../../modules/campaigns/interfaces/campaigns.interface';

interface CampaignsInterface {
  loading: boolean;
  campaigns: CampaignInterface[];
  selectedCampaign: SelectedCampaignInterface;
  listSkills: Skill[];
  listTags: Tag[];
  meta: Meta;
}

const initialState: CampaignsInterface = {
  loading: false,
  campaigns: [],
  selectedCampaign: emptyCampaignModel,
  listSkills: [],
  listTags: [],
  meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
};

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState: initialState,
  reducers: {
    setCampaings(state, action: PayloadAction<CampaignInterface[]>) {
      state.campaigns = action.payload;
    },
    setSelectedCampaign(state, action: PayloadAction<SelectedCampaignInterface>) {
      state.selectedCampaign = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
    setListSkills(state, action: PayloadAction<Skill[]>) {
      state.listSkills = action.payload;
    },
    setListTags(state, action: PayloadAction<Tag[]>) {
      state.listTags = action.payload;
    },
    resetMeta(state) {
      state.meta = initialState.meta;
    },
  },
});

export const campaingActions = campaignsSlice.actions;

export default campaignsSlice;
