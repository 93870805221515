export const accumulatedata = (data: any[], valuesToAdd: string[]) => {
  const accumulated = data.reduce((prev: any[], current: any, index: number): any[] => {
    if (index === 0) {
      prev.push(current);
    } else {
      const objToReturn: any = { ...current };
      valuesToAdd.map(valueToAdd => {
        objToReturn[valueToAdd] = current[valueToAdd] + prev[index - 1][valueToAdd];
      });
      prev.push(objToReturn);
    }
    return prev;
  }, []);

  return accumulated;
};
