import { useState } from 'react';
import { styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

export const AboutErlangCalculator = () => {
  const [openAccordion, setOpenAccordion] = useState<boolean>(false);

  const handleOpenAccordion = () => setOpenAccordion(!openAccordion);

  return (
    <AccordionContainer disableGutters expanded={openAccordion} onChange={handleOpenAccordion}>
      <AccordionSummary
        onClick={handleOpenAccordion}
        expandIcon={<KeyboardArrowUpOutlinedIcon />}
        sx={{ width: '100%', alignItems: 'baseline' }}
      >
        <InformationAccordion>
          <WrapperInformation>
            <IconInformation />
            <Title variant="body2">Sobre la Calculadora Erlang</Title>
          </WrapperInformation>
        </InformationAccordion>
      </AccordionSummary>
      <AccordionDetails>
        <TextInformationCalc>
          La calculadora Erlang es una herramienta para gerentes de call centers y otros profesionales que necesitan{' '}
          <span>calcular el volumen de llamadas y los requisitos de personal.</span> La fórmula tiene en cuenta el
          número de llamadas, la duración media de las llamadas y el tiempo medio entre llamadas. También tiene en
          cuenta el número de agentes disponibles para atender las llamadas.
          <br />
          Se puede <span>utilizar para calcular el número de agentes</span> necesarios para gestionar un volumen de
          llamadas determinado o{' '}
          <span> para calcular el volumen de llamadas que puede gestionar un número determinado de agentes.</span> De
          igual manera,{' '}
          <span>se puede utilizar para determinar el número óptimo de agentes para un centro de llamadas,</span>{' '}
          teniendo en cuenta factores como la duración media de las llamadas y el número de llamadas por hora.
        </TextInformationCalc>
      </AccordionDetails>
    </AccordionContainer>
  );
};

const AccordionContainer = styled(Accordion)(() => ({
  '&.MuiPaper-root': {
    borderRadius: 16,
  },
  '&:before': {
    display: 'none',
  },
}));

const TextInformationCalc = styled('p')(() => ({
  padding: '10px 30px',
  lineHeight: '28px',
  maxWidth: '1378px',
  span: {
    fontWeight: 'bold',
  },
}));

const WrapperInformation = styled('div')({
  display: 'flex',
  alignSelf: 'flex-start',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 10,
});

const InformationAccordion = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  gap: theme.spacing(2),
}));

const Title = styled(Typography)(() => ({}));

const IconInformation = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
