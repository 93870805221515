import { httpService, HttpError } from 'modules/core/services/http.service';
import { ServiceQueryStringParamsType } from 'modules/shared/interfaces/shared.interface';
import { getUsersAdapter, getUserByIdAdapter, createUserAdapter, updateUserAdapter } from '../adapters';

export type Error = HttpError;

export const getUsers = async (queryStringsParams?: ServiceQueryStringParamsType) => {
  const res = await httpService.get({
    url: 'users',
    params: queryStringsParams,
  });
  return getUsersAdapter(res.data);
};

export const getUserById = async (userId: string) => {
  const res = await httpService.get({
    url: `users/${userId}`,
  });
  return getUserByIdAdapter(res.data);
};

export const createUser = async (data: any) => {
  const res = await httpService.post({
    url: 'users',
    payload: data,
  });

  return createUserAdapter(res.data);
};

export const updateUser = async (data: any, userId: string) => {
  const res = await httpService.put({
    url: `users/${userId}`,
    payload: data,
  });

  return updateUserAdapter(res.data);
};

export const uploadFile = async (fileList: FileList) => {
  const formData = new FormData();
  formData.append('file', fileList[0]);
  const res = await httpService.post({
    url: '/users/import',
    payload: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });

  const { data } = res;
  return data;
};
