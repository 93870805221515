import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from 'modules/shared/interfaces/shared.interface';
import { Client, emptySelectedClientModel } from 'modules/clients/interfaces/clients.interface';

interface UsersStoreInterface {
  loading: boolean;
  clients: Client[];
  selectedClient: Client;
  meta: Meta;
}

const initialState: UsersStoreInterface = {
  loading: false,
  clients: [],
  selectedClient: emptySelectedClientModel,
  meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClients(state, action: PayloadAction<Client[]>) {
      state.clients = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
    setSelectedClient(state, action: PayloadAction<Client>) {
      state.selectedClient = action.payload;
    },
  },
});

export const clientActions = clientsSlice.actions;

export default clientsSlice;
