import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from 'modules/shared/interfaces/shared.interface';
import { RequestInterface, emptyRequestModel } from 'modules/requests/interfaces/request.interface';

interface RequestsStoreInterface {
  loading: boolean;
  requests: RequestInterface[];
  selectedRequest: RequestInterface;
  meta: Meta;
}

const initialState: RequestsStoreInterface = {
  loading: false,
  requests: [],
  selectedRequest: emptyRequestModel,
  meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
};

export const requestSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    setRequests(state, action: PayloadAction<RequestInterface[]>) {
      state.requests = action.payload;
    },
    setSelectedRequest(state, action: PayloadAction<RequestInterface>) {
      state.selectedRequest = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
  },
});

export const requestActions = requestSlice.actions;

export default requestSlice;
