import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LicenseInterface } from 'modules/requests/interfaces/request.interface';
import { queryParamsConfiguration, AcceptedTypes } from 'modules/setup-config/interfaces';

interface LicensesStoreInterface {
  loading: boolean;
  configuration: any[];
  licenses: LicenseInterface[];
  selectedLicenses: LicenseInterface[];
  selectedLicense: LicenseInterface;
  dataToSend: AcceptedTypes;
}

const initialState: LicensesStoreInterface = {
  loading: false,
  configuration: [],
  licenses: [],
  selectedLicenses: [],
  selectedLicense: {
    name: '',
    id: '',
    active: true,
    configuratorlicense: queryParamsConfiguration,
  },
  dataToSend: queryParamsConfiguration,
};

export const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    setLicenses(state, action: PayloadAction<LicenseInterface[]>) {
      state.licenses = action.payload;
    },
    patchLicenses(state, action: PayloadAction<LicenseInterface[]>) {
      state.selectedLicenses = action.payload;
    },
    setLicense(state, action: PayloadAction<LicenseInterface>) {
      state.selectedLicense = action.payload;
    },
    setConfiguration(state, action: PayloadAction<any[]>) {
      state.configuration = action.payload;
    },
    setLicenseConfiguration(state, action: PayloadAction<AcceptedTypes>) {
      state.dataToSend = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const licensesActions = licensesSlice.actions;

export default licensesSlice;
