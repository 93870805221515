import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TrainingEntity, TableTraining, emptyTrainingModel } from 'modules/trainings/interfaces';
import { Meta } from 'modules/shared/interfaces/shared.interface';

interface TrainingsStoreInterface {
  loading: boolean;
  trainings: TableTraining[];
  selectedTraining: TrainingEntity;
  meta: Meta;
}

const initialState: TrainingsStoreInterface = {
  loading: false,
  trainings: [],
  selectedTraining: emptyTrainingModel,
  meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
};

export const trainingsSlice = createSlice({
  name: 'trainings',
  initialState,
  reducers: {
    setTrainings(state, action: PayloadAction<TableTraining[]>) {
      state.trainings = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
    setSelectedTraining(state, action: PayloadAction<TrainingEntity>) {
      state.selectedTraining = action.payload;
    },
    resetSelectedTraining(state) {
      state.selectedTraining = emptyTrainingModel;
    },
  },
});

export const trainingsActions = trainingsSlice.actions;

export default trainingsSlice;
