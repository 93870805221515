import axios, { AxiosResponse, AxiosError } from 'axios';
import { appConfig } from '../../config/config';

// Create as many instances as needed
export const baseAxiosInstance = axios.create({
  baseURL: appConfig.API_URL,
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
  },
});

// interceptors
baseAxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.data.message.message === 'Usuario inactivo' && location.pathname !== '/sign-in') {
      window.location.replace(`${window.location.origin}/sign-in`);
    }
    return Promise.reject(error);
  },
);

baseAxiosInstance.interceptors.request.use(config => {
  if (config.url === 'auth/refresh') {
    const refresh_token = localStorage.getItem('SYNERGY_API_REFRESH_KEY');
    if (config.headers) config.headers.Authorization = `Bearer ${refresh_token}`;
  }
  return config;
});
