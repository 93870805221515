import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from 'modules/shared/interfaces';
import { TagEntity } from 'modules/tags/interfaces';

interface UsersStoreInterface {
  loading: boolean;
  tags: TagEntity[];
  meta: Meta;
}

const initialState: UsersStoreInterface = {
  loading: false,
  tags: [],
  meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTags(state, action: PayloadAction<TagEntity[]>) {
      state.tags = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
  },
});

export const tagsActions = tagsSlice.actions;

export default tagsSlice;
