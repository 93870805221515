import { Components } from '@mui/material';

// --------------------------------------

export const MuiPopover: Components['MuiPopover'] = {
  styleOverrides: {
    paper: {
      boxShadow: '-40px 40px 80px -8px rgba(145, 158, 171, 0.24)',
      '.MuiList-root.MuiMenu-list': {
        maxHeight: 'calc(48px * 4.5 + 8px)',
      },
    },
  },
};
