import Tooltip from '@mui/material/Tooltip';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';

interface PropsToolTipForm {
  text: string;
}

const ToolTipFormCalculator = ({ text }: PropsToolTipForm): JSX.Element => {
  return (
    <Tooltip title={text} arrow>
      <div>{text !== '' && <InfoOutlinedIcon sx={{ color: '#5f5f5f' }} />}</div>
    </Tooltip>
  );
};

export default ToolTipFormCalculator;
