import { ConfigTabDisplay } from 'modules/shared/interfaces';
import { RoleInterface } from 'modules/users/interfaces/users.interface';
import { TrainingEntity } from '../../trainings/interfaces/index';

import { ReactNode } from 'react';
import { ShiftInterface } from 'modules/shifts/interfaces/shifts.interface';

export interface EmployeeEntity {
  id: string;
  names: string;
  platformId?: string;
  additionalData: string;
  cuilCuit: string;
  dateOfBirth: string;
  dni: string;
  file: string;
  gender: string;
  maritalStatus: string;
  nationality: string;
  status: boolean;
  surenames: string;
  profileimage: string | null;
  user: { id: string; password: string; username: string; platform_id: string; role: RoleInterface; status: boolean };
  contact: { alternativeTelephone: string; email: string; id: string; phone: string };
  trainings: TrainingEntity[];
  address: {
    apartment: string;
    betweenStreets: string;
    floor: number;
    height: number;
    id: string;
    location: string;
    postalCode: string;
    street: string;
    country: {
      id: string;
      name: string;
    };
    province: { id: string; name: string };
  };
  additional: {
    adherentRelationships: string;
    adherents: string;
    dependentFamily: number;
    disabledChildren: number;
    id: string;
    qualification: number;
    sons: number;
  };
  labor?: {
    art: string;
    businessNameId?: string;
    businessName?: { name: string; id: string };
    created_at?: string;
    dateOfAdmission: string;
    egressDate: string | null;
    id?: string;
    positionOrTitle: string;
    reasonForLeaving: string;
    seniorityComputationDate: string;
    updated_at?: string;
    workModality: string;
    workplace: string;
  } | null;
  additionalInformation: {
    additionalOne: string;
    additionalTwo: string;
    additionalThree: string;
    additionalFour: string;
    additionalFive: string;
    additionalSix: string;
    additionalSeven: string;
    additionalEight: string;
    additionalNine: string;
    additionalTen: string;
    id: string;
  };
  assigned_shift?: {
    shiftId: string;
    start_date: string;
    shift: ShiftInterface;
  };
}
export type Employee = Pick<EmployeeEntity, 'id' | 'names' | 'surenames' | 'platformId' | 'user' | 'status'>;

export type User = Pick<EmployeeEntity, 'user'>;

export const emptyEmployeeEntityModel: EmployeeEntity = {
  id: '',
  names: '',
  platformId: '',
  additionalData: '',
  cuilCuit: '',
  dateOfBirth: '',
  dni: '',
  file: '',
  gender: '',
  maritalStatus: '',
  nationality: '',
  status: false,
  surenames: '',
  profileimage: null,
  trainings: [],
  user: {
    id: '',
    username: '',
    platform_id: '',
    password: '',
    role: {
      name: '',
    },
    status: true,
  },
  contact: { alternativeTelephone: '', email: '', id: '', phone: '' },
  address: {
    apartment: '',
    betweenStreets: '',
    floor: 0,
    height: 0,
    id: '',
    location: '',
    postalCode: '',
    street: '',
    country: { id: '', name: '' },
    province: { id: '', name: '' },
  },
  additional: {
    adherentRelationships: '',
    adherents: '',
    dependentFamily: 0,
    disabledChildren: 0,
    id: '',
    qualification: 0,
    sons: 0,
  },
  labor: {
    art: '',
    businessNameId: '',
    created_at: '',
    dateOfAdmission: '',
    egressDate: '',
    id: '',
    positionOrTitle: '',
    reasonForLeaving: '',
    seniorityComputationDate: '',
    updated_at: '',
    workModality: '',
    workplace: '',
  },
  additionalInformation: {
    additionalOne: '',
    additionalTwo: '',
    additionalThree: '',
    additionalFour: '',
    additionalFive: '',
    additionalSix: '',
    additionalSeven: '',
    additionalEight: '',
    additionalNine: '',
    additionalTen: '',
    id: '',
  },
};

export enum EmployeeFormTabs {
  USER = 'user',
  SHEET = 'sheet',
  JOB_DATA = 'jobData',
  HISTORY = 'history',
  SHIFT = 'shift',
  ADITIONAL = 'aditional',
}

export enum EmployeeViewTabs {
  SHEET = 'sheet',
  JOB_DATA = 'jobData',
  ADITIONAL = 'aditional',
  HISTORY = 'history',
  SHIFT = 'shift',
  FORMATION = 'formation',
  USER = 'user',
}

export interface IUserSeeingEmployee {
  canEdit: boolean;
  canDelete: boolean;
  configTabDisplay: ConfigTabDisplay;
  handleAction: (action: string) => void;
}
export interface EmployeeAditionalFormInterface {
  additionalOne: string;
  additionalTwo: string;
  additionalThree: string;
  additionalFour: string;
  additionalFive: string;
  additionalSix: string;
  additionalSeven: string;
  additionalEight: string;
  additionalNine: string;
  additionalTen: string;
}
export const emptyEmployeeAditionalModel: EmployeeAditionalFormInterface = {
  additionalOne: '',
  additionalTwo: '',
  additionalThree: '',
  additionalFour: '',
  additionalFive: '',
  additionalSix: '',
  additionalSeven: '',
  additionalEight: '',
  additionalNine: '',
  additionalTen: '',
};

export interface UserInsideEmployeeInterface {
  username: string;
  password: string;
  platform_id: string;
  role: string | RoleInterface;
  status: boolean;
}

export interface UpdateUserInsideEmployeeInterface {
  id: string;
  username?: string;
  password?: string;
  platform_id?: string;
  role?: {
    id?: string;
    name?: string;
  };
  status?: boolean;
}

export const emptyUserInsideEmployeeModel: UserInsideEmployeeInterface = {
  username: '',
  password: '',
  platform_id: '',
  role: {
    id: '',
    name: '',
  },
  status: true,
};

export interface EmployeeSheetFormInterface {
  name: string;
  lastName: string;
  file: string;
  dni: string;
  email: string;
  phone: string;
  status: string;
  id?: string;
  cuilCuit?: string;
  gender?: string;
  birthDate?: string;
  nationality?: string;
  maritalStatus?: string;
  altPhone?: string;
  street?: string;
  streetNumber?: string;
  floor?: string;
  apartment?: string;
  betweenStreets?: string;
  city?: string;
  province?: string;
  country?: string;
  zipCode?: string;
  sons?: number;
  disabledSons?: number;
  dependantRelatives?: string;
  adherent?: string;
  adherentRelation?: string;
  rate?: number;
  user?: {
    id: string;
    username: string;
    password: string;
    email: string;
    platform_id: string;
    role: {
      id: string;
      name: string;
    };
    status: boolean;
  };
}

export const emptyEmployeeSheetModel: EmployeeSheetFormInterface = {
  name: '',
  lastName: '',
  file: '',
  dni: '',
  phone: '',
  email: '',
  status: 'true',
  cuilCuit: '',
  gender: '',
  birthDate: '',
  nationality: '',
  maritalStatus: '',
  altPhone: '',
  street: '',
  streetNumber: '',
  floor: '',
  apartment: '',
  betweenStreets: '',
  city: '',
  province: '',
  country: '',
  zipCode: '',
  sons: 0,
  disabledSons: 0,
  dependantRelatives: '',
  adherent: '',
  adherentRelation: '',
  rate: 0,
};

export interface EmployeeSheetInterface {
  names: string;
  surenames: string;
  file: string;
  dni: string;
  id?: string;
  userId?: string | null;
  cuilCuit?: string;
  gender?: string;
  dateOfBirth?: string;
  nationality?: string;
  maritalStatus?: string;
  status?: boolean;
  contact?: {
    email?: string;
    phone?: string;
    alternativeTelephone?: string;
  };
  address?: {
    street?: string;
    height?: number;
    floor?: number;
    apartment?: string;
    betweenStreets?: string;
    location?: string;
    provinceId?: string;
    countryId?: string;
    postalCode?: string;
  };
  additional?: {
    sons?: number;
    disabledChildren?: number;
    dependentFamily?: number;
    adherents?: string;
    adherentRelationships?: string;
    qualification?: number;
  };
  user?: {
    id: string;
    username: string;
    password: string;
    email: string;
    platform_id: string;
    role: {
      id: string;
      name: string;
    };
    status: boolean;
  };
}

export interface EmployeeLaborDataFormInterface {
  id?: string;
  businessName: string;
  inDate: string;
  seniorityDate: string;
  workPlace: string;
  jobTitle?: string;
  workMode: string;
  exitDate: string;
  exitMotive: string;
  art: string;
}

export interface EmployeeShiftInterface {
  shiftId: string;
  start_date: string;
  end_date?: string;
  shift: ShiftInterface;
}

export type EmployeeShiftFormInterface = Omit<EmployeeShiftInterface, 'shift'>;

export interface IUserCard {
  names: string;
  status: boolean;
  userImage: File | null;
  children?: ReactNode;
  sx?: object;
}

export const emptyEmployeeLaborDataModel = {
  businessName: '',
  inDate: '',
  seniorityDate: '',
  workPlace: '',
  jobTitle: '',
  workMode: '',
  exitDate: '',
  exitMotive: '',
  art: '',
};
