import { ThemeOptions } from '@mui/material';
import foundations from './foundations';

const { typography: typo } = foundations;

export const typography: ThemeOptions['typography'] = {
  fontFamily: typo.fonts.body,

  h1: {
    fontFamily: typo.fonts.heading,
    fontSize: typo.fontSizes['8xl'],
    lineHeight: typo.lineHeights.shorter,
    fontWeight: typo.fontWeights.medium,
  },
  h2: {
    fontFamily: typo.fonts.heading,
    fontSize: typo.fontSizes['7xl'],
    lineHeight: typo.lineHeights.shorter,
    fontWeight: typo.fontWeights.light,
  },
  h3: {
    fontFamily: typo.fonts.heading,
    fontSize: typo.fontSizes['5xl'],
    lineHeight: typo.lineHeights.shorter,
    fontWeight: typo.fontWeights.bold,
  },
  h4: {
    fontFamily: typo.fonts.heading,
    fontSize: typo.fontSizes['4xl'],
    lineHeight: typo.lineHeights.shorter,
    fontWeight: typo.fontWeights.normal,
  },
  h5: {
    fontFamily: typo.fonts.body,
    fontSize: typo.fontSizes['2xl'],
    lineHeight: typo.lineHeights.shorter,
    fontWeight: typo.fontWeights.normal,
  },
  h6: {
    fontFamily: typo.fonts.heading,
    fontSize: typo.fontSizes['xl'],
    lineHeight: typo.lineHeights.shorter,
    fontWeight: typo.fontWeights.bold,
  },
  subtitle1: {
    fontFamily: typo.fonts.heading,
    fontSize: typo.fontSizes['xl'],
    lineHeight: typo.lineHeights.shorter,
    fontWeight: typo.fontWeights.normal,
  },
  body1: {
    fontFamily: typo.fonts.body,
    fontSize: typo.fontSizes.md,
    lineHeight: typo.lineHeights.normal,
    fontWeight: typo.fontWeights.normal,
  },
  body2: {
    fontFamily: typo.fonts.body,
    fontSize: typo.fontSizes.sm,
    lineHeight: typo.lineHeights.normal,
    fontWeight: typo.fontWeights.medium,
  },
  button: {
    fontFamily: typo.fonts.heading,
    fontWeight: typo.fontWeights.medium,
  },
};
