import React from 'react';

type LogoProps = {
  size?: number;
  color?: string;
  className?: string;
};

const SynergyLogo = ({ size = 100, className = 'Hola' }: LogoProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="currentColor"
      filter="invert(100%) sepia(100%) saturate(2%) hue-rotate(124deg) brightness(1040%)"
    >
      <path
        d="M22.0367 27.033C20.6396 27.033 19.2832 26.4816 18.2306 25.4291L13.9702 21.1687L9.80073 25.3382C8.2125 26.9265 5.93822 27.3776 3.86443 26.5192C1.79064 25.6609 0.5 23.7312 0.5 21.4851V6.48302C0.503133 4.23694 1.79377 2.30725 3.86756 1.44578C5.94448 0.587441 8.22189 1.03854 9.81013 2.62991L13.9828 6.80255L18.2118 2.57352C19.8 0.988416 22.0712 0.537319 24.1418 1.39566C26.2125 2.25399 27.5 4.18055 27.5 6.42037V21.5885C27.5 23.8252 26.2156 25.7486 24.1481 26.6038C23.4558 26.892 22.7416 27.0298 22.0367 27.0298V27.033ZM16.4983 18.6438L20.7586 22.9042C21.5731 23.7187 22.516 23.4179 22.7823 23.3083C23.0517 23.1955 23.9288 22.7444 23.9288 21.5916V6.42037C23.9288 5.2613 23.0454 4.80707 22.776 4.6943C22.5066 4.58152 21.5574 4.28079 20.7398 5.0984L13.5849 12.2502H18.2838C19.0576 12.2502 19.7499 12.7138 20.0475 13.428C20.3451 14.1423 20.1822 14.9599 19.634 15.5081L16.5014 18.6407L16.4983 18.6438ZM5.98834 4.59719C5.64375 4.59719 5.36182 4.6943 5.23338 4.74442C4.96084 4.85719 4.07118 5.31456 4.07118 6.48302V21.4851C4.07118 22.6473 4.95771 23.1078 5.23025 23.2206C5.50278 23.3334 6.45197 23.6372 7.27584 22.8133L14.2647 15.8245H9.82579C9.00818 15.8245 8.27515 15.3358 7.96189 14.5808C7.64862 13.8259 7.82092 12.9613 8.40045 12.3849L11.4579 9.33057L7.28524 5.15792C6.84981 4.72249 6.37678 4.60032 5.98834 4.60032V4.59719Z"
        fill="#4A90FF"
      />
      <path
        d="M9.81036 2.62811L7.28516 5.15332L11.4584 9.32655L13.9836 6.80135L9.81036 2.62811Z"
        fill="url(#paint0_linear_9557_80559)"
      />
      <path
        d="M16.4988 18.6418L13.9736 21.167L18.2333 25.4266L20.7585 22.9014L16.4988 18.6418Z"
        fill="url(#paint1_linear_9557_80559)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9557_80559"
          x1="8.48758"
          y1="3.90519"
          x2="12.7793"
          y2="8.04964"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.12" stopColor="white" stopOpacity="0.02" />
          <stop offset="0.24" stopColor="white" stopOpacity="0.07" />
          <stop offset="0.37" stopColor="white" stopOpacity="0.15" />
          <stop offset="0.5" stopColor="white" stopOpacity="0.26" />
          <stop offset="0.63" stopColor="white" stopOpacity="0.41" />
          <stop offset="0.76" stopColor="white" stopOpacity="0.6" />
          <stop offset="0.89" stopColor="white" stopOpacity="0.81" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9557_80559"
          x1="19.5533"
          y1="24.1237"
          x2="15.1771"
          y2="19.8946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.12" stopColor="white" stopOpacity="0.02" />
          <stop offset="0.24" stopColor="white" stopOpacity="0.07" />
          <stop offset="0.37" stopColor="white" stopOpacity="0.15" />
          <stop offset="0.5" stopColor="white" stopOpacity="0.26" />
          <stop offset="0.63" stopColor="white" stopOpacity="0.41" />
          <stop offset="0.76" stopColor="white" stopOpacity="0.6" />
          <stop offset="0.89" stopColor="white" stopOpacity="0.81" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SynergyLogo;
