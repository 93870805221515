import { httpService } from 'modules/core/services/http.service';
import { getHistoryPlanFlightAdapter } from '../adapters';
type Pagination = {
  page: number;
  itemCount: number;
  take: number;
};
export const getHistoryPlanFlight = async (
  idCampaign: string,
  queryType: 'current' | 'history',
  pagination: Pagination,
) => {
  const res = await httpService.get({
    url: `campaigns/${idCampaign}/flightPlans`,
    params: { queryType, page: pagination.page, take: pagination.take },
  });

  return getHistoryPlanFlightAdapter(res.data);
};
