import SelectMui from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material';
import { Register } from '../interface';

interface PropsFormSelectCalculator {
  hideBlockSelect?: boolean;
  textLabelInformation: string;
  register: Register;
  disabled: boolean;
}

type OptionsForm = {
  id: number;
  value: number;
  label: string;
};

const optionsForm = [
  {
    id: 1,
    value: 15,
    label: '15 minutos',
  },
  {
    id: 2,
    value: 30,
    label: '30 minutos',
  },
  {
    id: 3,
    value: 60,
    label: '1 hora',
  },
];

const FormSelectCalculator = ({
  hideBlockSelect,
  textLabelInformation,
  register,
  disabled,
}: PropsFormSelectCalculator) => {
  return (
    <>
      {!hideBlockSelect && (
        <ContainerBlockSelect>
          <SecondaryLabelText>{textLabelInformation}</SecondaryLabelText>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={'30'}
            type="number"
            disabled={disabled}
            {...register('periodContacts', { required: `El campo es obligatorio`, valueAsNumber: true })}
          >
            {optionsForm.map(({ id, label, value }: OptionsForm) => (
              <Option key={id} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </ContainerBlockSelect>
      )}
    </>
  );
};

export default FormSelectCalculator;

const ContainerBlockSelect = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '37%',
}));

const Option = styled(MenuItem)(() => ({}));

const Select = styled(SelectMui)(() => ({
  width: '40%',
}));

const SecondaryLabelText = styled('span')(() => ({
  maxWidth: '159px',
  fontWeight: 'bold',
}));
