import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Meta } from 'modules/shared/interfaces';
import { TeamInterface, emptyTeam } from 'modules/shared/interfaces/teams.interface';

interface TeamsSlicesInterface {
  loading: boolean;
  teams: TeamInterface[];
  selectedTeam: TeamInterface;
  meta: Meta;
  availableEmployees: AvailableEmployee[];
}

interface AvailableEmployee {
  dni: string;
  user_id: string;
  id: string;
  names: string;
  surenames: string;
  teams: TeamInterface[];
}

const initialState: TeamsSlicesInterface = {
  loading: false,
  teams: [],
  selectedTeam: emptyTeam,
  availableEmployees: [],
  meta: { page: 1, take: 5, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
};

export const teamsSlices = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams(state, action: PayloadAction<TeamInterface[]>) {
      state.teams = action.payload;
    },
    setSelectedTeam(state, action: PayloadAction<TeamInterface>) {
      state.selectedTeam = action.payload;
    },
    setAvailableEmployees(state, action: PayloadAction<AvailableEmployee[]>) {
      state.availableEmployees = action.payload;
    },
    resetAvailableEmployees(state) {
      state.availableEmployees = [];  
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
    resetTeam(state) {
      state.selectedTeam = emptyTeam;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const teamsActions = teamsSlices.actions;

export default teamsSlices;
