import { Components } from '@mui/material';

export const MuiDialog: Components['MuiDialog'] = {
  styleOverrides: {
    paper: {
      boxShadow: '-40px 40px 80px -8px rgba(145, 158, 171, 0.24)',
      '&.MuiPaper-rounded': {
        borderRadius: 16,
      },
      '&.MuiDialog-paperFullScreen': {
        borderRadius: 0,
      },
      '&.MuiDialog-paper .MuiDialogActions-root': {
        padding: `0.75rem`,
      },
      '@media (max-width: 600px)': {
        margin: `0.5rem`,
      },
      '@media (max-width: 663.95px)': {
        '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
          maxWidth: '100%',
        },
      },
    },
    paperFullWidth: {
      width: '100%',
    },
    root: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: `0.75rem`,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: `0.75rem`,
          },
          dividers: {
            borderTop: 0,
            borderBottomStyle: 'dashed',
            paddingBottom: `0.75rem`,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            '& > :not(:first-of-type)': {
              marginLeft: `0.375rem`,
            },
          },
        },
      },
    },
  },
};
