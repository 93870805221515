import { FormHelperText, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit,
  Controller,
  UseFormRegister,
  Control,
  UseFormReset,
} from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import TextFieldInformationCalculator from './TextFieldInformationCalculator';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Box from '@mui/material/Box';
import DividerMui from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import ButtonMui from '@mui/material/Button';
import AccordionMui from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import FormSelectCalculator from './FormSelectCalculator';
import TextFieldController from './TextFieldController';
import FormInputCalculator from './FormInputCalculator';
import { rulesContacts, rulesPercentage, rulesPercentage99, rulesTime } from '../rules';
import { useInboundCalculator } from 'modules/core/providers/InboundCalculator.provider';
import { useSnackbar } from 'notistack';
import { getInboundCalculation, InboundCalculationData } from '../services/inboundCalculator.service';

// services
import { HttpError } from 'modules/core/services/http.service';
interface Option {
  label: string;
  value: string;
}
interface ControlledAutocompletePorps {
  options: Option[];
  name: string;
  disabled: boolean;
  control: any;
  defaultValue: string;
  error: string;
  rules: any;
}

const ControlledAutocomplete = ({
  options,
  name,
  control,
  defaultValue,
  error,
  rules,
  disabled,
}: ControlledAutocompletePorps) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field }) => (
      <Autocomplete
        disabled={disabled}
        disablePortal
        options={options}
        fullWidth
        getOptionLabel={option => option?.label ?? options.find(({ value }) => value === option)?.label ?? ''}
        {...field}
        renderInput={params => <TextField {...params} variant="outlined" error={Boolean(error)} helperText={error} />}
        onChange={(_event, data) => field.onChange(data?.value ?? '')}
      />
    )}
  />
);

interface FormCalculatorProps {
  expand: boolean;
  showExpandButton: boolean;
  campaignsInbound: any;
  control: Control<any, any>;
  onExpand: (value: boolean) => void;
  onStartCalculation: () => void;
  onCompleteCalculation: (data?: InboundCalculationData[]) => void;
  handleSubmit: UseFormHandleSubmit<any>;
  register: UseFormRegister<any>;
  reset: UseFormReset<any>;
}

const FormCalculator = ({
  showExpandButton,
  expand,
  campaignsInbound,
  onCompleteCalculation,
  onStartCalculation,
  onExpand,
  control,
  handleSubmit,
  register,
  reset,
}: FormCalculatorProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    calculatorData,
    setCalculatorFields,
    resetCalculatorFields,
    setSuggestedRow,
    setSelectedRow,
  } = useInboundCalculator();

  const [disabled, setDisabled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState('');

  const onSubmit: SubmitHandler<FieldValues | any> = async data => {
    setDisabled(true);

    try {
      if (data.campaign == '') {
        enqueueSnackbar('El campo campaña es requerido', { variant: 'error' });
        onCompleteCalculation();
      } else {
        setCalculatorFields(data);
        onStartCalculation();
        const calculation = await getInboundCalculation(
          data.incomingContacts,
          data.periodContacts,
          data.serviceLevelRequired,
          data.responseTime,
          data.averageInteractionTime,
          data.maxOccupation,
          data.reducers,
          data.weeklyHours,
          data.averagePatience,
        );
        onCompleteCalculation(calculation);
        setSuggestedRow(calculation.find(row => row.highlighted) || null);
        setSelectedRow(null);
      }
    } catch (calcError) {
      const err = calcError as HttpError;
      const errorMessage = err.response?.data.message.message || 'Ocurrio un error al obtener el cálculo';

      enqueueSnackbar(errorMessage, { variant: 'error' });
      onCompleteCalculation();
    } finally {
      setDisabled(false);
    }
  };

  const handleChange = () => {
    setIsExpanded(prevState => !prevState);
    onExpand(!isExpanded);
  };

  useEffect(() => {
    setIsExpanded(expand);
  }, [expand]);

  useEffect(() => {
    () => resetCalculatorFields();
  }, []);

  return (
    <Accordion expanded={isExpanded} onChange={handleChange} disableGutters elevation={1}>
      <Fade in={showExpandButton}>
        <AccordionSummary>
          <Button
            sx={{ px: theme => theme.spacing(8) }}
            disableRipple
            endIcon={
              isExpanded ? <CloseIcon fontSize="large" color="action" /> : <EditIcon fontSize="large" color="action" />
            }
          >
            Calculadora
          </Button>
        </AccordionSummary>
      </Fade>
      <AccordionDetails>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          {/* <CampaignComponent register={register} disabled={disabled} /> */}

          <ContainerGroup>
            <Label>Campaña</Label>
            <AutocompleteContainer>
              <ControlledAutocomplete
                options={campaignsInbound.map(({ name, id }: { name: string; id: string }) => ({
                  label: name,
                  value: id,
                }))}
                name={'campaign'}
                control={control}
                defaultValue={''}
                error={''}
                rules={{}}
                disabled={campaignsInbound.length === 0}
              />
              <FormHelperText hidden={campaignsInbound.length ? true : false}>
                No hay campañas Inbound asignadas al usuario actual
              </FormHelperText>
            </AutocompleteContainer>
          </ContainerGroup>
          <Divider />

          <ContainerTextField>
            <TextFieldInformationCalculator
              labelTextInformation="Contactos entrantes"
              textAfterInput="Llamadas"
              textToolTip="Números de llamadas recibidas en el intervalo de una hora"
            >
              <TextFieldController
                name={'incomingContacts'}
                control={control}
                setError={setError}
                disabled={disabled}
                rules={rulesContacts}
                defaultValue={calculatorData.incomingContacts}
                helperText=""
                type="number"
              />
            </TextFieldInformationCalculator>
            <FormSelectCalculator textLabelInformation="En un período de" register={register} disabled={disabled} />
          </ContainerTextField>

          <Divider />

          <ContainerTextField>
            <TextFieldInformationCalculator
              labelTextInformation="Tiempo promedio de interacción (AHT)"
              textAfterInput="Segundos"
              textToolTip="(TMO) Duración promedio utilizado para atender un contacto telefónico, en segundos."
            >
              <TextFieldController
                name={'averageInteractionTime'}
                control={control}
                setError={setError}
                disabled={disabled}
                rules={rulesTime}
                defaultValue={calculatorData.averageInteractionTime}
                helperText=""
                type="number"
              />
            </TextFieldInformationCalculator>
          </ContainerTextField>

          <Divider />

          <ContainerTextField>
            <TextFieldInformationCalculator
              labelTextInformation="Nivel de servicio requerido"
              textAfterInput="% de respuesta"
              textToolTip="Nivel de servicio objetivo. El promedio de la indistria es 80%, en porcentaje"
            >
              <TextFieldController
                name={'serviceLevelRequired'}
                control={control}
                setError={setError}
                disabled={disabled}
                rules={rulesPercentage}
                defaultValue={calculatorData.serviceLevelRequired}
                helperText=""
                type="number"
              />
            </TextFieldInformationCalculator>
            {/* Another input */}
            <FormInputCalculator
              labelTextInformation="Tiempo de respuesta"
              textAfterInput="Segundos"
              textToolTip="Tiempo de respuesta promedio de espera limite, en segundos"
            >
              {
                <TextFieldController
                  name={'responseTime'}
                  control={control}
                  setError={setError}
                  disabled={disabled}
                  rules={rulesTime}
                  defaultValue={calculatorData.responseTime}
                  helperText=""
                  type="number"
                />
              }
            </FormInputCalculator>
          </ContainerTextField>

          <Divider />

          <Box>
            <Accordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandLessIcon />}>
                <Title variant="body2">
                  OPCIONES AVANZADAS <ButtonMui>MOSTRAR</ButtonMui>
                </Title>
              </AccordionSummary>

              <AccordionDetails>
                <GroupTextField>
                  <ContainerTextField>
                    <TextFieldInformationCalculator
                      labelTextInformation="Máxima ocupación"
                      textAfterInput="% "
                      textToolTip="Límite máximo de ocupación, en porcentaje."
                    >
                      <TextFieldController
                        name={'maxOccupation'}
                        control={control}
                        setError={setError}
                        disabled={disabled}
                        rules={rulesPercentage}
                        defaultValue={calculatorData.maxOccupation}
                        helperText=""
                        type="number"
                      />
                    </TextFieldInformationCalculator>
                    {/* Another input */}
                    <FormInputCalculator
                      labelTextInformation="Reductores"
                      textAfterInput="%"
                      textToolTip="Actividades que prohíban a los empleadores realizar sus funciones, en porcentaje."
                    >
                      {
                        <TextFieldController
                          name={'reducers'}
                          control={control}
                          setError={setError}
                          disabled={disabled}
                          rules={rulesPercentage99}
                          defaultValue={calculatorData.reducers}
                          helperText=""
                          type="number"
                        />
                      }
                    </FormInputCalculator>
                  </ContainerTextField>
                </GroupTextField>

                <GroupTextField medium>
                  <ContainerTextField>
                    <TextFieldInformationCalculator
                      labelTextInformation="Paciencia promedio"
                      textAfterInput="Segundos"
                      textToolTip="Tiempo promedio de abandono (ATA). Tiempo que una persona aparecerá en cola antes de abandonar la llamada"
                    >
                      <TextFieldController
                        name={'averagePatience'}
                        control={control}
                        setError={setError}
                        disabled={disabled}
                        rules={rulesTime}
                        defaultValue={calculatorData.averagePatience}
                        helperText=""
                        type="number"
                      />
                    </TextFieldInformationCalculator>
                    {/* Another input */}
                    <FormInputCalculator
                      labelTextInformation="Horas semanales V(1FTE)"
                      textAfterInput="Horas"
                      textToolTip=""
                    >
                      {
                        <TextFieldController
                          name={'weeklyHours'}
                          control={control}
                          setError={setError}
                          disabled={disabled}
                          rules={rulesTime}
                          defaultValue={calculatorData.weeklyHours}
                          helperText=""
                          type="number"
                        />
                      }
                    </FormInputCalculator>
                  </ContainerTextField>
                </GroupTextField>
              </AccordionDetails>
            </Accordion>
          </Box>

          <ContainerBottons>
            <ButtonReset type="reset" disabled={disabled ? true : false} onClick={() => reset()}>
              REINICIAR
            </ButtonReset>
            <ButtonCalculator type="submit" variant="contained">
              {disabled ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'CALCULAR'}
            </ButtonCalculator>
          </ContainerBottons>
        </FormContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default FormCalculator;

interface PropsGroupTextField {
  medium?: boolean;
}

const ContainerTextField = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const ContainerBottons = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const GroupTextField = styled('div', {
  shouldForwardProp: props => props !== 'medium',
})(({ medium }: PropsGroupTextField) => ({
  margin: medium ? '20px 0' : 0,
}));

const Title = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 3,
}));

const Label = styled(InputLabel)(({ theme }) => ({
  marginRight: theme.spacing(4),
  color: theme.palette.common.black,
  fontWeight: 'bold',
}));

const Accordion = styled(AccordionMui)(() => ({
  '&.MuiPaper-root': {
    borderRadius: 16,
  },
  '&:before': {
    display: 'none',
  },
}));

const Divider = styled(DividerMui)(({ theme }) => ({
  margin: theme.spacing(10, 0),
}));

const ButtonReset = styled(ButtonMui)(({ theme }) => ({
  width: 127,
  border: `1px solid ${theme.palette.primary.main}`,
  marginRight: theme.spacing(4),
  color: theme.palette.primary.main,
}));

const ButtonCalculator = styled(ButtonMui)(({ theme }) => ({
  background: theme.palette.primary.main,
  width: 127,
}));

const ContainerGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(15),
  maxWidth: 450,
}));

const AutocompleteContainer = styled('div')(() => ({
  width: 280,
}));

const FormContainer = styled('form')(({ theme }) => ({
  padding: theme.spacing(6, 12),
}));
