import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface IOverlayProps {
  isLoading: boolean;
  isDisabled: boolean;
  opacity?: number;
  children?: JSX.Element;
}
const Overlay = ({ isLoading, isDisabled, opacity, children }: IOverlayProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {isDisabled && (
        <Box
          sx={{
            backgroundColor: '#ffffff',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            opacity: opacity ? opacity : 0.9,
          }}
        ></Box>
      )}

      {children ? children : <span style={{ display: 'block', height: '500px' }}></span>}
    </Box>
  );
};

export default Overlay;
