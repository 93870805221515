import { alpha } from '@mui/material/styles';
import { Components } from '@mui/material';

export const MuiSwitch: Components['MuiSwitch'] = {
  styleOverrides: {
    root: {
      padding: '9px 13px 9px 12px',
      width: 58,
      height: 38,
      '& .MuiSwitch-thumb': {
        width: 14,
        height: 14,
        boxShadow: 'none',
        color: 'white !important',
      },
      '& .MuiSwitch-track': {
        opacity: 1,
        borderRadius: 14,
        backgroundColor: alpha('#bdbdbd', 0.48),
      },
      '& .MuiSwitch-switchBase': {
        left: 3,
        padding: 12,
        '&.Mui-checked': {
          transform: 'translateX(13px)',
          '&+.MuiSwitch-track': { opacity: 1 },
        },
        '&.Mui-disabled': {
          '& .MuiSwitch-thumb': { opacity: 0.48 },
          '&+.MuiSwitch-track': { opacity: 0.48 },
        },
      },
    },
  },
};
