import { ThemeOptions } from '@mui/material';
import foundations from './foundations';

const { colors } = foundations;

export const palette: ThemeOptions['palette'] = {
  primary: {
    main: colors.brandColors.blueberry,
    light: colors.brandColors.blueberryLight,
    dark: colors.brandColors.blueberryDark,
  },
  secondary: {
    main: colors.brandColors.caribbeanGreen,
    light: colors.brandColors.caribbeanGreenLight,
  },
  warning: {
    main: colors.brandColors.warningPrimary,
    light: colors.brandColors.warningHover,
  },
  info: {
    main: colors.brandColors.egyptianBlue,
    light: colors.brandColors.aliceBlue,
    dark: colors.brandColors.sapphireBlue,
  },
  grey: {
    50: colors.brandColors.ghostWhite,
    200: colors.brandColors.simpleGrey,
    100: colors.brandColors.antiFlashWithe,
    300: colors.brandColors.greySelectedTab,
    400: colors.brandColors.silverSand,
    500: colors.brandColors.mediumGrey,
    700: colors.brandColors.spanishGray,
    800: colors.brandColors.washedBlue,
  },
  background: {
    default: colors.gray[50],
    paper: colors.white,
  },
};
