import { CubeProvider } from '@cubejs-client/react';
import UseCubeJSApi from 'modules/shared/hooks/useCubeApi';

interface CubeProviderProps {
  children: React.ReactNode;
}

const CubeProviderComponent = ({ children }: CubeProviderProps) => {
  const { cubeJSApi } = UseCubeJSApi();
  return <CubeProvider cubejsApi={cubeJSApi}>{children}</CubeProvider>;
};

export default CubeProviderComponent;
