import { HeadCell } from 'modules/shared/components/table/components/EnhancedTableHead.component';
import { BodyCell } from 'modules/shared/components/table/Table.component';
import { Dispatch, SetStateAction } from 'react';

import { Employee, EmployeeEntity } from './interfaces/employees.interface';
import Label from 'modules/shared/components/label';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export const getHeadCells = (): HeadCell[] => {
  const headCells: HeadCell[] = [
    {
      id: 'space',
      label: '',
      numeric: false,
      sortable: false,
    },
    {
      id: 'fullname',
      label: 'Nombre completo',
      numeric: false,
      sortable: false,
    },
    {
      id: 'user',
      label: 'Usuario',
      numeric: false,
      sortable: false,
    },
    {
      id: 'platform_id',
      label: 'ID Plataforma',
      numeric: false,
      sortable: false,
    },
    {
      id: 'state',
      label: 'Estado',
      numeric: false,
      sortable: false,
    },
    {
      id: 'actions',
      label: '',
      numeric: false,
      sortable: false,
    },
    {
      id: 'space2',
      label: '',
      numeric: false,
      sortable: false,
    },
  ];
  return headCells;
};

export const employeesToBodyCell: (
  employees: Employee[],
  deleteEmployee: Dispatch<SetStateAction<Employee | undefined>>,
  canDeleteEmployee?: boolean,
) => BodyCell[][] = (
  employees: Employee[],
  deleteEmployee: Dispatch<SetStateAction<Employee | undefined>>,
  canDeleteEmployee?: boolean,
) => {
  const response: BodyCell[][] = employees.map((employee: Employee) => {
    const parsedClients: BodyCell[] = [
      { displayType: 'space', space: true },
      { displayType: 'text', text: `${employee.names} ${employee.surenames} ` },
      { displayType: 'text', text: employee.user ? employee.user?.username : '' },
      { displayType: 'text', text: employee.user?.platform_id ? employee.user?.platform_id : '-' },
      {
        displayType: 'icon',
        icon: (
          <Label
            variant="soft"
            sx={{
              textTransform: 'capitalize',
              bgcolor: !employee.status ? '#FEBB0FB2' : '#4ED14EB2',
              width: '92px',
              borderRadius: '50px',
            }}
          >
            {!employee.status ? 'Inactivo' : 'Activo'}
          </Label>
        ),
      },
      {
        displayType: 'anchor',
        icon: <VisibilityOutlinedIcon sx={{ color: theme => theme.palette.primary.main }} />,
        href: `/employees/${employee.id}`,
        size: 'small',
      },
      { displayType: 'space', space: true },
    ];
    if (canDeleteEmployee) {
      // parsedClients.push({
      //   displayType: 'iconButton',
      //   icon: <Iconify icon="eva:trash-2-outline" color="error.main" />,
      //   action: () => deleteEmployee(employee),
      //   size: 'small',
      // });
    }
    return parsedClients;
  });
  return response;
};

export const genderOptions = [
  {
    id: 'masculino',
    name: 'Masculino',
  },
  {
    id: 'femenino',
    name: 'Femenino',
  },
  {
    id: 'otro',
    name: 'Otro',
  },
];

export const statusOptions = [
  {
    id: 'true',
    name: 'Activo',
  },
  {
    id: 'false',
    name: 'Inactivo',
  },
];

export const maritalStatusOptions = [
  {
    id: 'casado',
    name: 'Casado/a',
  },
  {
    id: 'soltero',
    name: 'Soltero/a',
  },
  {
    id: 'conviviente',
    name: 'Conviviente',
  },
  {
    id: 'separado',
    name: 'Separado/a',
  },
  {
    id: 'divorciado',
    name: 'Divorciado/a',
  },
  {
    id: 'viudo',
    name: 'Viudo/a',
  },
];

export const adherentRelationOptions = [
  {
    id: 'conyuge',
    name: 'Cónyuge',
  },
  {
    id: 'hijo',
    name: 'Hijo/a',
  },
  {
    id: 'padre',
    name: 'Padre/Madre',
  },
  {
    id: 'otro',
    name: 'Otro',
  },
];

export const workModeOptions = [
  {
    id: 'presencial',
    name: 'Presencial',
  },
  {
    id: 'teletrabajo',
    name: 'Teletrabajo',
  },
  {
    id: 'hibrido',
    name: 'Híbrido',
  },
];

export const submitMock = async (data: any) => {
  const response = await setTimeout(() => {
    return 'response';
  }, 1000);
  console.log(data);
  return response;
};

export const isCuitValid = (cuit: string): boolean => {
  let rv = false;
  let resultado = 0;
  const cuit_nro = cuit.replace('-', '');
  const codes = '6789456789';
  const verificador = parseInt(cuit_nro[cuit_nro.length - 1]);
  let x = 0;

  while (x < 10) {
    let digitoValidador = parseInt(codes.substring(x, x + 1));
    if (isNaN(digitoValidador)) digitoValidador = 0;
    let digito = parseInt(cuit_nro.substring(x, x + 1));
    if (isNaN(digito)) digito = 0;
    const digitoValidacion = digitoValidador * digito;
    resultado += digitoValidacion;
    x++;
  }
  resultado = resultado % 11;
  rv = resultado == verificador;

  const numerosIniciales = ['20', '23', '24', '25', '26', '27', '30'];
  if (!numerosIniciales.some(numero => numero === cuit.slice(0, 2))) {
    return false;
  } else {
    return rv;
  }
};

export const isDNIValid = (dni: string): boolean => {
  const dniRegex = /^[0-9]{7,8}$/g;
  return dniRegex.test(dni);
};

export const formatHour = (hour: string | undefined): string => {
  if (hour) {
    const splittedHour = hour.split(':');
    if (splittedHour[0] && splittedHour[1]) {
      return `${splittedHour[0]}:${splittedHour[1]}`;
    }
  }
  return hour || '';
};

export const laborHistory: EmployeeEntity['labor'][] = [
  {
    dateOfAdmission: '2022-07-01',
    seniorityComputationDate: '2022-07-02',
    workplace: 'mi casita',
    positionOrTitle: 'hipermegajefe',
    workModality: 'presencial',
    egressDate: '2022-09-23',
    reasonForLeaving: 'se me dio la gana',
    art: 'porrazo sa',
    created_at: '2022-09-23T20:28:33.608Z',
    updated_at: '2022-09-23T20:28:33.608Z',
    id: 'b6b9eaf2-56dc-4746-892e-bca96946de24',
    businessName: {
      id: 'a8e6fced-0033-475c-9078-cda82c438874',
      name: 'nombre cualquiera',
    },
  },
  {
    dateOfAdmission: '2022-07-01',
    seniorityComputationDate: '2022-07-02',
    workplace: 'otro lado',
    positionOrTitle: 'servidumbre',
    workModality: 'hibrido',
    egressDate: '2022-09-23',
    reasonForLeaving: 'otro trabajo',
    art: 'art diferente sa',
    created_at: '2022-09-23T20:28:33.608Z',
    updated_at: '2022-09-23T20:28:33.608Z',
    id: 'b6b9eaf2-56dc-4746-892e-bca96946de25',
    businessName: {
      id: 'a8e6fced-0033-475c-9078-cda82c438874',
      name: 'nombre cualquiera',
    },
  },
  {
    dateOfAdmission: '2022-07-01',
    seniorityComputationDate: '2022-07-02',
    workplace: 'co work',
    positionOrTitle: 'encargado',
    workModality: 'presencial',
    egressDate: '2022-09-23',
    reasonForLeaving: 'otro empleo',
    art: 'art3 sa',
    created_at: '2022-09-23T20:28:33.608Z',
    updated_at: '2022-09-23T20:28:33.608Z',
    id: 'b6b9eaf2-56dc-4746-892e-bca96946de26',
    businessName: {
      id: 'a8e6fced-0033-475c-9078-cda82c438874',
      name: 'nombre cualquiera',
    },
  },
];

export const nationalityOptions = [
  {
    id: 'Afganistán',
    name: 'Afganistán',
  },
  {
    id: 'Albania',
    name: 'Albania',
  },
  {
    id: 'Argelia',
    name: 'Argelia',
  },
  {
    id: 'Samoa Americana',
    name: 'Samoa Americana',
  },
  {
    id: 'Andorra',
    name: 'Andorra',
  },
  {
    id: 'Angola',
    name: 'Angola',
  },
  {
    id: 'Anguilla',
    name: 'Anguilla',
  },
  {
    id: 'Antártida',
    name: 'Antártida',
  },
  {
    id: 'Antigua y Barbuda',
    name: 'Antigua y Barbuda',
  },
  {
    id: 'Argentina',
    name: 'Argentina',
  },
  {
    id: 'Armenia',
    name: 'Armenia',
  },
  {
    id: 'Aruba',
    name: 'Aruba',
  },
  {
    id: 'Australia',
    name: 'Australia',
  },
  {
    id: 'Austria',
    name: 'Austria',
  },
  {
    id: 'Azerbaiyán',
    name: 'Azerbaiyán',
  },
  {
    id: 'Bahamas',
    name: 'Bahamas',
  },
  {
    id: 'Baréin',
    name: 'Baréin',
  },
  {
    id: 'Banglades',
    name: 'Banglades',
  },
  {
    id: 'Barbados',
    name: 'Barbados',
  },
  {
    id: 'Bielorrusia',
    name: 'Bielorrusia',
  },
  {
    id: 'Bélgica',
    name: 'Bélgica',
  },
  {
    id: 'Belice',
    name: 'Belice',
  },
  {
    id: 'Benin',
    name: 'Benin',
  },
  {
    id: 'Bermudas',
    name: 'Bermudas',
  },
  {
    id: 'Butan',
    name: 'Butan',
  },
  {
    id: 'Bolivia',
    name: 'Bolivia',
  },
  {
    id: 'Bosnia-Herzegovina',
    name: 'Bosnia-Herzegovina',
  },
  {
    id: 'Botsuana',
    name: 'Botsuana',
  },
  {
    id: 'Brasil',
    name: 'Brasil',
  },
  {
    id: 'Territorio Británico del Océano Índico',
    name: 'Territorio Británico del Océano Índico',
  },
  {
    id: 'Brunei',
    name: 'Brunei',
  },
  {
    id: 'Bulgaria',
    name: 'Bulgaria',
  },
  {
    id: 'Burkina Faso',
    name: 'Burkina Faso',
  },
  {
    id: 'Burundi',
    name: 'Burundi',
  },
  {
    id: 'Camboya',
    name: 'Camboya',
  },
  {
    id: 'Camerún',
    name: 'Camerún',
  },
  {
    id: 'Canadá',
    name: 'Canadá',
  },
  {
    id: 'Cabo Verde',
    name: 'Cabo Verde',
  },
  {
    id: 'Islas Caimán',
    name: 'Islas Caimán',
  },
  {
    id: 'República Centroafricana',
    name: 'República Centroafricana',
  },
  {
    id: 'Chad',
    name: 'Chad',
  },
  {
    id: 'Chile',
    name: 'Chile',
  },
  {
    id: 'China',
    name: 'China',
  },
  {
    id: 'Isla de Navidad',
    name: 'Isla de Navidad',
  },
  {
    id: 'Islas Cocos',
    name: 'Islas Cocos',
  },
  {
    id: 'Colombia',
    name: 'Colombia',
  },
  {
    id: 'Comoras',
    name: 'Comoras',
  },
  {
    id: 'Congo',
    name: 'Congo',
  },
  {
    id: 'República Democrática del Congo',
    name: 'República Democrática del Congo',
  },
  {
    id: 'Islas Cook',
    name: 'Islas Cook',
  },
  {
    id: 'Costa Rica',
    name: 'Costa Rica',
  },
  {
    id: 'Costa de Marfil',
    name: 'Costa de Marfil',
  },
  {
    id: 'Croacia',
    name: 'Croacia',
  },
  {
    id: 'Cuba',
    name: 'Cuba',
  },
  {
    id: 'Chipre',
    name: 'Chipre',
  },
  {
    id: 'Chequia',
    name: 'Chequia',
  },
  {
    id: 'Dinamarca',
    name: 'Dinamarca',
  },
  {
    id: 'Yibuti',
    name: 'Yibuti',
  },
  {
    id: 'Dominica',
    name: 'Dominica',
  },
  {
    id: 'República Dominicana',
    name: 'República Dominicana',
  },
  {
    id: 'Ecuador',
    name: 'Ecuador',
  },
  {
    id: 'Egipto',
    name: 'Egipto',
  },
  {
    id: 'El Salvador',
    name: 'El Salvador',
  },
  {
    id: 'Guinea Ecuatorial',
    name: 'Guinea Ecuatorial',
  },
  {
    id: 'Eritrea',
    name: 'Eritrea',
  },
  {
    id: 'Estonia',
    name: 'Estonia',
  },
  {
    id: 'Etiopía',
    name: 'Etiopía',
  },
  {
    id: 'Islas Malvinas',
    name: 'Islas Malvinas',
  },
  {
    id: 'Islas Feroe',
    name: 'Islas Feroe',
  },
  {
    id: 'Fiyi',
    name: 'Fiyi',
  },
  {
    id: 'Finlandia',
    name: 'Finlandia',
  },
  {
    id: 'Francia',
    name: 'Francia',
  },
  {
    id: 'Guayana Francesa',
    name: 'Guayana Francesa',
  },
  {
    id: 'Polinesia Francesa',
    name: 'Polinesia Francesa',
  },
  {
    id: 'Gabón',
    name: 'Gabón',
  },
  {
    id: 'Gambia',
    name: 'Gambia',
  },
  {
    id: 'Georgia',
    name: 'Georgia',
  },
  {
    id: 'Alemania',
    name: 'Alemania',
  },
  {
    id: 'Ghana',
    name: 'Ghana',
  },
  {
    id: 'Gibraltar',
    name: 'Gibraltar',
  },
  {
    id: 'Grecia',
    name: 'Grecia',
  },
  {
    id: 'Groenlandia',
    name: 'Groenlandia',
  },
  {
    id: 'Granada',
    name: 'Granada',
  },
  {
    id: 'Guadalupe',
    name: 'Guadalupe',
  },
  {
    id: 'Guam',
    name: 'Guam',
  },
  {
    id: 'Guatemala',
    name: 'Guatemala',
  },
  {
    id: 'Guernsey',
    name: 'Guernsey',
  },
  {
    id: 'Guinea',
    name: 'Guinea',
  },
  {
    id: 'Guinea-Bisau',
    name: 'Guinea-Bisau',
  },
  {
    id: 'Guyana',
    name: 'Guyana',
  },
  {
    id: 'Haití',
    name: 'Haití',
  },
  {
    id: 'Ciudad del Vaticano',
    name: 'Ciudad del Vaticano',
  },
  {
    id: 'Honduras',
    name: 'Honduras',
  },
  {
    id: 'Hong Kong',
    name: 'Hong Kong',
  },
  {
    id: 'Hungría',
    name: 'Hungría',
  },
  {
    id: 'Islandia',
    name: 'Islandia',
  },
  {
    id: 'India',
    name: 'India',
  },
  {
    id: 'Indonesia',
    name: 'Indonesia',
  },
  {
    id: 'Irán',
    name: 'Irán',
  },
  {
    id: 'Iraq',
    name: 'Iraq',
  },
  {
    id: 'Irlanda',
    name: 'Irlanda',
  },
  {
    id: 'Isla de Man',
    name: 'Isla de Man',
  },
  {
    id: 'Israel',
    name: 'Israel',
  },
  {
    id: 'Italia',
    name: 'Italia',
  },
  {
    id: 'Jamaica',
    name: 'Jamaica',
  },
  {
    id: 'Japón',
    name: 'Japón',
  },
  {
    id: 'Jersey',
    name: 'Jersey',
  },
  {
    id: 'Jordania',
    name: 'Jordania',
  },
  {
    id: 'Kazajistán',
    name: 'Kazajistán',
  },
  {
    id: 'Kenia',
    name: 'Kenia',
  },
  {
    id: 'Kiribati',
    name: 'Kiribati',
  },
  {
    id: 'Corea del Norte',
    name: 'Corea del Norte',
  },
  {
    id: 'Corea del Sur',
    name: 'Corea del Sur',
  },
  {
    id: 'Kosovo',
    name: 'Kosovo',
  },
  {
    id: 'Kuwait',
    name: 'Kuwait',
  },
  {
    id: 'Kirguistán',
    name: 'Kirguistán',
  },
  {
    id: 'Laos',
    name: 'Laos',
  },
  {
    id: 'Letonia',
    name: 'Letonia',
  },
  {
    id: 'Líbano',
    name: 'Líbano',
  },
  {
    id: 'Lesoto',
    name: 'Lesoto',
  },
  {
    id: 'Liberia',
    name: 'Liberia',
  },
  {
    id: 'Libia',
    name: 'Libia',
  },
  {
    id: 'Liechtenstein',
    name: 'Liechtenstein',
  },
  {
    id: 'Lituania',
    name: 'Lituania',
  },
  {
    id: 'Luxemburgo',
    name: 'Luxemburgo',
  },
  {
    id: 'Macao',
    name: 'Macao',
  },
  {
    id: 'República de Macedonia',
    name: 'República de Macedonia',
  },
  {
    id: 'Madagascar',
    name: 'Madagascar',
  },
  {
    id: 'Malaui',
    name: 'Malaui',
  },
  {
    id: 'Malasia',
    name: 'Malasia',
  },
  {
    id: 'Maldivas',
    name: 'Maldivas',
  },
  {
    id: 'Malí',
    name: 'Malí',
  },
  {
    id: 'Malta',
    name: 'Malta',
  },
  {
    id: 'Islas Marshall',
    name: 'Islas Marshall',
  },
  {
    id: 'Martinica',
    name: 'Martinica',
  },
  {
    id: 'Mauritania',
    name: 'Mauritania',
  },
  {
    id: 'Mauricio',
    name: 'Mauricio',
  },
  {
    id: 'Mayotte',
    name: 'Mayotte',
  },
  {
    id: 'México',
    name: 'México',
  },
  {
    id: 'Estados Federados de Micronesia',
    name: 'Estados Federados de Micronesia',
  },
  {
    id: 'Moldavia',
    name: 'Moldavia',
  },
  {
    id: 'Monaco',
    name: 'Monaco',
  },
  {
    id: 'Mongolia',
    name: 'Mongolia',
  },
  {
    id: 'Montenegro',
    name: 'Montenegro',
  },
  {
    id: 'Montserrat',
    name: 'Montserrat',
  },
  {
    id: 'Marruecos',
    name: 'Marruecos',
  },
  {
    id: 'Mozambique',
    name: 'Mozambique',
  },
  {
    id: 'Birmania',
    name: 'Birmania',
  },
  {
    id: 'Namibia',
    name: 'Namibia',
  },
  {
    id: 'Nauru',
    name: 'Nauru',
  },
  {
    id: 'Nepal',
    name: 'Nepal',
  },
  {
    id: 'Holanda',
    name: 'Holanda',
  },
  {
    id: 'Antillas Holandesas',
    name: 'Antillas Holandesas',
  },
  {
    id: 'Nueva Caledonia',
    name: 'Nueva Caledonia',
  },
  {
    id: 'Nueva Zelanda',
    name: 'Nueva Zelanda',
  },
  {
    id: 'Nicaragua',
    name: 'Nicaragua',
  },
  {
    id: 'Niger',
    name: 'Niger',
  },
  {
    id: 'Nigeria',
    name: 'Nigeria',
  },
  {
    id: 'Niue',
    name: 'Niue',
  },
  {
    id: 'IslaNorfolk',
    name: 'IslaNorfolk',
  },
  {
    id: 'IslasMarianasdelNorte',
    name: 'IslasMarianasdelNorte',
  },
  {
    id: 'Noruega',
    name: 'Noruega',
  },
  {
    id: 'Omán',
    name: 'Omán',
  },
  {
    id: 'Pakistán',
    name: 'Pakistán',
  },
  {
    id: 'Palaos',
    name: 'Palaos',
  },
  {
    id: 'Panamá',
    name: 'Panamá',
  },
  {
    id: 'Papúa Nueva Guinea',
    name: 'Papúa Nueva Guinea',
  },
  {
    id: 'Paraguay',
    name: 'Paraguay',
  },
  {
    id: 'Perú',
    name: 'Perú',
  },
  {
    id: 'Filipinas',
    name: 'Filipinas',
  },
  {
    id: 'Islas Pitcairn',
    name: 'Islas Pitcairn',
  },
  {
    id: 'Polonia',
    name: 'Polonia',
  },
  {
    id: 'Portugal',
    name: 'Portugal',
  },
  {
    id: 'Puerto Rico',
    name: 'Puerto Rico',
  },
  {
    id: 'Qatar',
    name: 'Qatar',
  },
  {
    id: 'Rumania',
    name: 'Rumania',
  },
  {
    id: 'Rusia',
    name: 'Rusia',
  },
  {
    id: 'Ruanda',
    name: 'Ruanda',
  },
  {
    id: 'Reunion',
    name: 'Reunion',
  },
  {
    id: 'San Bartolome',
    name: 'San Bartolome',
  },
  {
    id: 'Santa Elena, Ascensión y Tristán de Acuña',
    name: 'Santa Elena, Ascensión y Tristán de Acuña',
  },
  {
    id: 'San Cristóbal y Nieves',
    name: 'San Cristóbal y Nieves',
  },
  {
    id: 'Santa Lucía',
    name: 'Santa Lucía',
  },
  {
    id: 'Isla de San Martín',
    name: 'Isla de San Martín',
  },
  {
    id: 'San Pedro y Miquelon',
    name: 'San Pedro y Miquelon',
  },
  {
    id: 'San Vicente y las Granadinas',
    name: 'San Vicente y las Granadinas',
  },
  {
    id: 'Samoa',
    name: 'Samoa',
  },
  {
    id: 'San Marino',
    name: 'San Marino',
  },
  {
    id: ' Santo Tomé y Príncipe',
    name: ' Santo Tomé y Príncipe',
  },
  {
    id: 'Arabia Saudita',
    name: 'Arabia Saudita',
  },
  {
    id: 'Senegal',
    name: 'Senegal',
  },
  {
    id: 'Serbia',
    name: 'Serbia',
  },
  {
    id: 'Seychelles',
    name: 'Seychelles',
  },
  {
    id: 'Sierra Leona',
    name: 'Sierra Leona',
  },
  {
    id: 'Singapur',
    name: 'Singapur',
  },
  {
    id: 'Eslovaquia',
    name: 'Eslovaquia',
  },
  {
    id: 'Eslovenia',
    name: 'Eslovenia',
  },
  {
    id: 'Islas Salomón',
    name: 'Islas Salomón',
  },
  {
    id: 'Somalia',
    name: 'Somalia',
  },
  {
    id: 'Sudáfrica',
    name: 'Sudáfrica',
  },
  {
    id: 'Sudán del Sur',
    name: 'Sudán del Sur',
  },
  {
    id: 'España',
    name: 'España',
  },
  {
    id: 'Sri Lanka',
    name: 'Sri Lanka',
  },
  {
    id: 'Estado de Palestina',
    name: 'Estado de Palestina',
  },
  {
    id: 'Sudán',
    name: 'Sudán',
  },
  {
    id: 'Surinam',
    name: 'Surinam',
  },
  {
    id: 'Svalbard y Jan Mayen',
    name: 'Svalbard y Jan Mayen',
  },
  {
    id: 'Suazilandia',
    name: 'Suazilandia',
  },
  {
    id: 'Suecia',
    name: 'Suecia',
  },
  {
    id: 'Suiza',
    name: 'Suiza',
  },
  {
    id: 'Siria',
    name: 'Siria',
  },
  {
    id: 'Taiwán',
    name: 'Taiwán',
  },
  {
    id: 'Tayikistán',
    name: 'Tayikistán',
  },
  {
    id: 'Tanzania',
    name: 'Tanzania',
  },
  {
    id: 'Tailandia',
    name: 'Tailandia',
  },
  {
    id: 'Timor Oriental',
    name: 'Timor Oriental',
  },
  {
    id: 'Togo',
    name: 'Togo',
  },
  {
    id: 'Tokelau',
    name: 'Tokelau',
  },
  {
    id: 'Tonga',
    name: 'Tonga',
  },
  {
    id: 'Trinidad y Tobago',
    name: 'Trinidad y Tobago',
  },
  {
    id: 'Túnez',
    name: 'Túnez',
  },
  {
    id: 'Turquía',
    name: 'Turquía',
  },
  {
    id: 'Turkmenistán',
    name: 'Turkmenistán',
  },
  {
    id: 'Islas Turcas y Caicos',
    name: 'Islas Turcas y Caicos',
  },
  {
    id: 'Tuvalu',
    name: 'Tuvalu',
  },
  {
    id: 'Uganda',
    name: 'Uganda',
  },
  {
    id: 'Ucrania',
    name: 'Ucrania',
  },
  {
    id: 'Emiratos Árabes Unidos',
    name: 'Emiratos Árabes Unidos',
  },
  {
    id: 'Reino Unido',
    name: 'Reino Unido',
  },
  {
    id: 'Estados Unidos',
    name: 'Estados Unidos',
  },
  {
    id: 'Uruguay',
    name: 'Uruguay',
  },
  {
    id: 'Uzbekistán',
    name: 'Uzbekistán',
  },
  {
    id: 'Vanuatu',
    name: 'Vanuatu',
  },
  {
    id: 'Venezuela',
    name: 'Venezuela',
  },
  {
    id: 'Vietnam',
    name: 'Vietnam',
  },
  {
    id: 'Islas Vírgenes Británicas',
    name: 'Islas Vírgenes Británicas',
  },
  {
    id: 'Islas Vírgenes de los Estados Unidos',
    name: 'Islas Vírgenes de los Estados Unidos',
  },
  {
    id: 'Wallis y Futuna',
    name: 'Wallis y Futuna',
  },
  {
    id: 'Yemen',
    name: 'Yemen',
  },
  {
    id: 'Zambia',
    name: 'Zambia',
  },
  {
    id: 'Zimbabue',
    name: 'Zimbabue',
  },
  {
    id: 'Åland',
    name: 'Åland',
  },
];
