export interface TrainingEntity {
  name: string;
  date: string;
  time: string;
  trainer: string;
  numberOfHours: number;
  url: string;
  tags: Tag[];
  employees: Employee[];
}

export interface ITrainingView {
  headerTitle: string;
}

type Tag = { id: string; name: string };

export type TagToSend = Pick<Tag, 'id'>;
export type EmployeesToSend = Pick<Employee, 'id'>;

export type CreateTrainingDataToSend = Pick<TrainingEntity, 'name' | 'date' | 'time'> & {
  tags: TagToSend[];
  numberOfHours?: number;
  trainer?: string;
  url?: string;
  employees: EmployeesToSend[];
};

export type Employee = {
  id: string;
  names: string;
  surenames: string;
  username: string;
  platform_id: string;
};

type CategoryTag = {
  id: string;
  name: string;
};

type TableTag = {
  id: string;
  name: string;
  category: CategoryTag;
};

export type TableTraining = Pick<TrainingEntity, 'name' | 'date' | 'time'> & {
  id: string;
  numberOfHours: number | null;
  trainer: string | null;
  tags: TableTag[];
};

export const emptyTrainingModel: TrainingEntity = {
  name: '',
  date: '',
  time: '',
  trainer: '',
  numberOfHours: 0,
  url: '',
  tags: [],
  employees: [],
};
