export enum ColumnTypes {
  REQUEST_TYPE = 'Tipo de novedad',
  EMPLOYEE = 'Empleado',
  DATE = 'Fecha',
  REQUEST_STATUS = 'Estado de la novedad',
  CERTIFICATE_STATUS = 'Estado del certificado',
  MY_REQUESTS = 'Mis novedades',
  ALL_CHARGED = 'Todas las cargadas',
  ALL_REVISIONS = 'Todas las revisiones',
}

export interface FilterInterface {
  isSupervisor: boolean;
}
