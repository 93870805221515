import SynergyLogo from '../../../../../../shared/components/svg/SynergyLogo.component';
import { Fade, styled, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'modules/mui/theme';

interface LogoTransitionProps {
  expand: boolean;
}

const LogoTransition = ({ expand }: LogoTransitionProps) => {
  const matchSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <LogoContainer>
      <LogoWrapper>
        <SynergyLogo size={matchSizeSmall ? 20 : 32} />
        <Fade in={expand} unmountOnExit>
          <Typography fontSize={matchSizeSmall ? 18 : 22} color={'white'}>
            Synergy WFM
          </Typography>
        </Fade>
      </LogoWrapper>
    </LogoContainer>
  );
};

const LogoContainer = styled('div')(matchSizeSmall => ({
  minWidth: matchSizeSmall ? 180 : 256,
  height: 80,
  '&:before': {
    display: 'none',
  },
}));

const LogoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  paddingLeft: theme.spacing(4),
  gap: theme.spacing(3),
  '&:before': {
    display: 'none',
  },
}));

export default LogoTransition;
