/* eslint-disable @typescript-eslint/no-empty-function */
import { startOfMonth } from 'date-fns';
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface HomeDashboardsInterface {
  startDate: Date | undefined;
  endDate: Date | undefined;
  callsChartOperatorData: any;
  setCallsChartOperatorData: Dispatch<SetStateAction<any>>;
  operatorTotalAndEffectivenessData: any;
  setOperatorTotalAndEffectivenessData: Dispatch<SetStateAction<any>>;
  averageTimeConversationData: any;
  setAverageTimeConversationData: Dispatch<SetStateAction<any>>;
  //SUPERVISOR STATES
  callsChartOperatorsData: any;
  setCallsChartOperatorsData: Dispatch<SetStateAction<any>>;
  operatorsTotalCallsAndEffectivenessData: any;
  setOperatorsTotalCallsAndEffectivenessData: Dispatch<SetStateAction<any>>;
  totalCallsOperatorsData: any;
  setTotalCallsOperatorsData: Dispatch<SetStateAction<any>>;
  currentEffectiveCallsPerUserInCampaign: any;
  setCurrentEffectiveCallsPerUserInCampaign: Dispatch<SetStateAction<any>>;
  currentEffectiveCallsTodayPerUserInCampaign: any;
  setCurrentEffectiveCallsTodayPerUserInCampaign: Dispatch<SetStateAction<any>>;
  totalEffectivenessPerDayData: any;
  setTotalEffectivenessPerDayData: Dispatch<SetStateAction<any>>;
  clearOperatorData: () => void;
}

const initialState: HomeDashboardsInterface = {
  startDate: undefined,
  endDate: undefined,
  //OPERATOR STATES
  callsChartOperatorData: undefined,
  setCallsChartOperatorData: () => {},
  operatorTotalAndEffectivenessData: undefined,
  setOperatorTotalAndEffectivenessData: () => {},
  averageTimeConversationData: undefined,
  setAverageTimeConversationData: () => {},
  //SUPERVISOR STATES
  callsChartOperatorsData: undefined,
  setCallsChartOperatorsData: () => {},
  operatorsTotalCallsAndEffectivenessData: undefined,
  setOperatorsTotalCallsAndEffectivenessData: () => {},
  totalCallsOperatorsData: () => {},
  setTotalCallsOperatorsData: () => {},
  currentEffectiveCallsPerUserInCampaign: undefined,
  setCurrentEffectiveCallsPerUserInCampaign: () => {},
  currentEffectiveCallsTodayPerUserInCampaign: undefined,
  setCurrentEffectiveCallsTodayPerUserInCampaign: () => {},
  totalEffectivenessPerDayData: undefined,
  setTotalEffectivenessPerDayData: () => {},
  // CLEAR
  clearOperatorData: () => {},
};

export const HomeDashboardsContext = createContext(initialState);

export const useHomeDashboardsContext = () => {
  const context = useContext(HomeDashboardsContext);

  if (!context) throw new Error('useHomeDashboardsContext must be use inside HomeDashboardsProvider');

  return context;
};

type HomeDashboardsProviderProps = {
  children: React.ReactNode;
};

export function HomeDashboardsProvider({ children }: HomeDashboardsProviderProps) {
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [callsChartOperatorData, setCallsChartOperatorData] = useState<any>(undefined);
  const [operatorTotalAndEffectivenessData, setOperatorTotalAndEffectivenessData] = useState<any>(undefined);
  const [averageTimeConversationData, setAverageTimeConversationData] = useState<any>(undefined);
  const [callsChartOperatorsData, setCallsChartOperatorsData] = useState<any>(undefined);
  const [operatorsTotalCallsAndEffectivenessData, setOperatorsTotalCallsAndEffectivenessData] = useState<any>(
    undefined,
  );
  const [totalCallsOperatorsData, setTotalCallsOperatorsData] = useState<any>(undefined);
  const [currentEffectiveCallsPerUserInCampaign, setCurrentEffectiveCallsPerUserInCampaign] = useState<any>(undefined);
  const [currentEffectiveCallsTodayPerUserInCampaign, setCurrentEffectiveCallsTodayPerUserInCampaign] = useState<any>(
    undefined,
  );
  const [totalEffectivenessPerDayData, setTotalEffectivenessPerDayData] = useState<any>(undefined);

  const clearOperatorData = useCallback(() => {
    setCallsChartOperatorData(undefined);
    setOperatorTotalAndEffectivenessData(undefined);
    setAverageTimeConversationData(undefined);
    setCallsChartOperatorsData(undefined);
    setOperatorsTotalCallsAndEffectivenessData(undefined);
    setTotalCallsOperatorsData(undefined);
    setCurrentEffectiveCallsPerUserInCampaign(undefined);
    setCurrentEffectiveCallsTodayPerUserInCampaign(undefined);
    setTotalEffectivenessPerDayData(undefined);
  }, []);

  useEffect(() => {
    const firstDay = startOfMonth(new Date());
    setStartDate(firstDay);

    const today = new Date();
    setEndDate(today);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      //OPERATOR
      callsChartOperatorData,
      setCallsChartOperatorData,
      operatorTotalAndEffectivenessData,
      setOperatorTotalAndEffectivenessData,
      averageTimeConversationData,
      setAverageTimeConversationData,
      clearOperatorData,
      //SUPERVISOR
      callsChartOperatorsData,
      setCallsChartOperatorsData,
      operatorsTotalCallsAndEffectivenessData,
      setOperatorsTotalCallsAndEffectivenessData,
      totalCallsOperatorsData,
      setTotalCallsOperatorsData,
      currentEffectiveCallsPerUserInCampaign,
      setCurrentEffectiveCallsPerUserInCampaign,
      currentEffectiveCallsTodayPerUserInCampaign,
      setCurrentEffectiveCallsTodayPerUserInCampaign,
      totalEffectivenessPerDayData,
      setTotalEffectivenessPerDayData,
    }),
    [
      startDate,
      endDate,
      //OPERATOR
      setStartDate,
      setEndDate,
      callsChartOperatorData,
      operatorTotalAndEffectivenessData,
      averageTimeConversationData,
      setAverageTimeConversationData,
      setCallsChartOperatorData,
      setOperatorTotalAndEffectivenessData,
      clearOperatorData,
      //SUPERVISOR
      callsChartOperatorsData,
      setCallsChartOperatorsData,
      operatorsTotalCallsAndEffectivenessData,
      setOperatorsTotalCallsAndEffectivenessData,
      totalCallsOperatorsData,
      setTotalCallsOperatorsData,
      currentEffectiveCallsPerUserInCampaign,
      setCurrentEffectiveCallsPerUserInCampaign,
      currentEffectiveCallsTodayPerUserInCampaign,
      setCurrentEffectiveCallsTodayPerUserInCampaign,
      totalEffectivenessPerDayData,
      setTotalEffectivenessPerDayData,
    ],
  );

  return <HomeDashboardsContext.Provider value={memoizedValue}>{children}</HomeDashboardsContext.Provider>;
}
