import { httpService } from 'modules/core/services/http.service';
import { getClientsAdapter } from '../adapters';

const getClients = async () => {
  const res = await httpService.get({
    url: 'clients/all',
  });

  return getClientsAdapter(res.data);
};

export default getClients;
