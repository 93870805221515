import { Components } from '@mui/material';

export const MuiFormControl: Components['MuiFormControl'] = {
  styleOverrides: {
    root: {
      '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
        borderRadius: '5px',
      },
    },
  },
};
