import { httpService } from 'modules/core/services/http.service';
import {
  getCampaignsAdapter,
  updateCampaignByIdAdapter,
  createCampaignAdapter,
  payloadCreateCampaignAdapter,
} from '../adapters';

export type QueryStringsParamsType = {
  field?: string;
  value?: string;
  page?: number;
};

export const getCampaigns = async (queryStringsParams?: QueryStringsParamsType) => {
  const res = await httpService.get({
    url: 'campaigns',
    params: queryStringsParams,
  });

  return getCampaignsAdapter(res.data);
};

export const getCampaignsForSelect = async (queryStringsParams?: QueryStringsParamsType) => {
  const res = await httpService.get({
    url: 'campaigns/all',
    params: queryStringsParams,
  });

  return res.data;
};

export const updateCampaignById = async (id: string, data: any) => {
  const res = await httpService.put({
    url: `campaigns/${id}`,
    payload: data,
  });

  return updateCampaignByIdAdapter(res.data);
};

export const createCampaign = async (data: any) => {
  const res = await httpService.post({
    url: 'campaigns',
    payload: payloadCreateCampaignAdapter(data),
  });

  return createCampaignAdapter(res.data);
};

export const deleteCampaign = async (id: string) => {
  const res = await httpService.delete({
    url: `campaigns/${id}`,
  });

  return res.data;
};
