import { Components } from '@mui/material';

export const MuiAlert: Components['MuiAlert'] = {
  styleOverrides: {
    root: {},
  },
  variants: [
    {
      props: { severity: 'error' },
      style: {
        height: 37,
        borderRadius: 8,
        '& .MuiAlert-icon': {
          padding: 0,
        },
        '& .MuiAlert-message': {
          padding: 4,
        },
      },
    },
    {
      props: { variant: 'filled', severity: 'warning' },
      style: {
        backgroundColor: '#FA9C10',
        color: '#FFF',
        height: 37,
      },
    },
  ],
};
