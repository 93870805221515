import { ChipProps } from '@mui/material';
import { kinshipTypes } from 'modules/requests/interfaces/request.interface';
import { BirthDefault, ExamRulesDefault, extraHourRulesDefault, PositionReservationDefault } from './utils';
export interface LicenseConfigurationInterface {
  id: string;
  name: string;
  active?: boolean;
  allowsconfiguration?: boolean;
}

export interface QueryParamsConfigurationEntity {
  justification_required: number;
  days_quantity: number;
  consecutive_days: boolean;
  discount_days: boolean;
  notification_require: boolean;
  remunerative: boolean;
  remunerative_percentage: number;
  position_reservation: boolean;
  min_days: number;
  max_days: number;
  license_paid_by_company: boolean;
  surplus_paid_by_company: boolean;
  formal_notification: boolean;
  exam_rules: ExamRules;
  notification: Notification;
  excedents: Excedents;
  position_reservation_rules: PositionReservation[];
  kinship: {
    name: kinshipTypes | undefined;
    days: number;
  }[];
  seniority: Seniority[];
  vacation_rules: VacationRules[];
  extra_hour_rules: ExtraHourRules;
  birth: Birth;
}

export type PositionReservation = {
  from: number;
  from_unit: string;
  to: number;
  to_unit: string;
  quantity: number;
  quantity_unit: string;
};

export type Excedents = {
  available: boolean;
  maximum_period: number;
  value_unit: string;
  renovate_every_value: number;
  renovate_every_unit: string;
};

export type Seniority = {
  from: number;
  from_unit: string;
  to: number;
  to_unit: string;
  vacation_days: number;
  vacation_days_range: number;
  vacation_days_unit: string;
};

export type VacationRules = {
  days_quantity: number;
  range_value: number;
  range_unit: string;
  maximum_days: number;
};

export type ExtraHourRules = {
  percent_extra_hour: number;
  percent_extra_hour_sunday: number;
};

export type ExamRules = {
  days_quantity: number;
  range_value: number;
  range_unit: string;
  maximum_days: number;
};

export type Notification = {
  days_value: number;
  interval_days: number;
  summons: number;
};

export type Birth = {
  id: number;
  prenatal_days: number;
  postnatal_days: number;
};

export type LJobReservationQuery = Pick<QueryParamsConfigurationEntity, 'position_reservation_rules'>;

export type LExamQuery = Pick<
  QueryParamsConfigurationEntity,
  'consecutive_days' | 'exam_rules' | 'justification_required'
>;

export type LMovingQuery = Pick<
  QueryParamsConfigurationEntity,
  'days_quantity' | 'consecutive_days' | 'remunerative' | 'remunerative_percentage' | 'justification_required'
>;

export type LMourningQuery = Pick<
  QueryParamsConfigurationEntity,
  'consecutive_days' | 'kinship' | 'remunerative' | 'remunerative_percentage' | 'justification_required'
>;

export type LBloodDonationQuery = Pick<
  QueryParamsConfigurationEntity,
  'consecutive_days' | 'days_quantity' | 'justification_required'
>;

export type LGremialQuery = Pick<
  QueryParamsConfigurationEntity,
  'justification_required' | 'days_quantity' | 'remunerative' | 'remunerative_percentage'
>;

export type LSuspensionQuery = Pick<
  QueryParamsConfigurationEntity,
  'consecutive_days' | 'discount_days' | 'notification_require'
>;

export type LDiseaseQuery = Pick<
  QueryParamsConfigurationEntity,
  'consecutive_days' | 'remunerative' | 'remunerative_percentage' | 'justification_required' | 'position_reservation'
>;

export type LExtraHoursQuery = Pick<QueryParamsConfigurationEntity, 'extra_hour_rules'>;

export type LFamilyIllnessQuery = Pick<
  QueryParamsConfigurationEntity,
  'consecutive_days' | 'remunerative' | 'remunerative_percentage' | 'justification_required' | 'min_days' | 'max_days'
>;

export type LProceduresQuery = Pick<QueryParamsConfigurationEntity, 'days_quantity' | 'justification_required'>;

export type LAbsenceQuery = Pick<
  QueryParamsConfigurationEntity,
  | 'consecutive_days'
  | 'min_days'
  | 'max_days'
  | 'remunerative'
  | 'remunerative_percentage'
  | 'formal_notification'
  | 'notification'
>;

export type LPaternityQuery = Pick<
  QueryParamsConfigurationEntity,
  'consecutive_days' | 'days_quantity' | 'remunerative' | 'remunerative_percentage' | 'surplus_paid_by_company'
>;

export type LMaternityQuery = Pick<
  QueryParamsConfigurationEntity,
  | 'consecutive_days'
  | 'birth'
  | 'remunerative'
  | 'remunerative_percentage'
  | 'license_paid_by_company'
  | 'excedents'
  | 'justification_required'
>;

export type LVacationQuery = Pick<QueryParamsConfigurationEntity, 'consecutive_days' | 'seniority' | 'vacation_rules'>;

export type LMarriage = Pick<
  QueryParamsConfigurationEntity,
  'consecutive_days' | 'remunerative' | 'remunerative_percentage' | 'days_quantity' | 'surplus_paid_by_company'
>;

export type AcceptedTypes =
  | LGremialQuery
  | LSuspensionQuery
  | LDiseaseQuery
  | LFamilyIllnessQuery
  | LExtraHoursQuery
  | LMourningQuery
  | LVacationQuery
  | LExamQuery
  | LMovingQuery
  | LAbsenceQuery
  | LBloodDonationQuery
  | LPaternityQuery
  | LJobReservationQuery
  | LMaternityQuery
  | LMarriage
  | LProceduresQuery;

export type DependenciesTypes = Seniority | ExamRules | Notification | Excedents | Birth | PositionReservation;

export const queryParamsConfiguration: QueryParamsConfigurationEntity = {
  justification_required: 0,
  days_quantity: 0,
  consecutive_days: false,
  discount_days: false,
  notification_require: false,
  remunerative: false,
  remunerative_percentage: 0,
  position_reservation: false,
  min_days: 0,
  max_days: 0,
  license_paid_by_company: false,
  surplus_paid_by_company: false,
  formal_notification: false,
  notification: {
    days_value: 0,
    interval_days: 0,
    summons: 0,
  },
  excedents: {
    available: false,
    maximum_period: 0,
    value_unit: '',
    renovate_every_value: 0,
    renovate_every_unit: '',
  },
  position_reservation_rules: PositionReservationDefault,
  kinship: [
    {
      name: undefined,
      days: 0,
    },
  ],
  seniority: [],
  vacation_rules: [],
  extra_hour_rules: extraHourRulesDefault,
  birth: BirthDefault,
  exam_rules: ExamRulesDefault,
};

export interface SelectedLicensesInterface {
  licenses: LicenseConfigurationInterface[];
  index: number;
}

export interface ActionButtonsInterface {
  action: () => void;
  secondaryAction: () => void;
  settingsAction?: () => void;
}

export interface LicenseSelectionInterface {
  licenses: LicenseConfigurationInterface[];
  onSelect: (id: string, state: boolean) => void;
}
export interface LicenseToBeSelectedInterface extends ChipProps {
  active: boolean;
}

export enum ConfigDisplayView {
  FIRST_STEP = 'select requests',
  SECOND_STEP = 'configure requests',
}

export const remunerativeSchemeValues = [
  {
    label: 'No remunerativo',
    value: false,
  },
  {
    label: 'Remunerativo',
    value: true,
  },
];

export const availableDaysSchemeValues = [
  {
    label: 'Dias corridos',
    value: true,
  },
  {
    label: 'Dias hábiles (Lun a Vie)',
    value: false,
  },
];

export const booleanSchemeValues = [
  {
    label: 'Si',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const defaultSchemeValue = [
  { label: 'Sí', value: 2 },
  { label: 'No', value: 3 },
];
