import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getMenuContent, MenuContent } from '../menuList/utils';
import { useAuth } from 'modules/auth/AuthProvider.component';
import { useAppDispatch } from 'store/hooks/store.hooks';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import List from '@mui/material/List';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Fade,
  styled,
  Box,
  alpha,
  Theme,
  useMediaQuery,
  Stack,
} from '@mui/material';
import { theme } from 'modules/mui/theme';
import { resetMeta } from 'store/actions/campaigns.actions';
import Scrollbar from 'modules/shared/components/scrollbar/Scrollbar';

import HelpIcon from '@mui/icons-material/Help';
import Logout from 'assets/icons/navbar/Logout.svg';

interface MenuListProps {
  expand: boolean;
  onSelection: () => void;
  setExpand?: Dispatch<SetStateAction<boolean>>;
}

const ControlledAccordions = ({ expand, setExpand, onSelection }: MenuListProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [activeNavButton, setActiveNavButton] = useState<string | null>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user, signOut } = useAuth();

  const CONFLUENCE_LINK = 'https://synergywfm.atlassian.net/wiki/spaces/SYNERGYWFM/overview';
  const abilities = user ? user.ability : [];
  const menuContent = getMenuContent(abilities);
  const matchSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    if (!expand && !matchSizeSmall && setExpand) onSelection();
  };

  const handleNavigate = (to: string) => {
    navigate(to);
  };

  const goToConfluence = () => {
    window.open(CONFLUENCE_LINK, '_blank');
  };

  useEffect(() => {
    if (!expand && expanded) setExpanded(false);
  }, [expand]);

  const handleResetMeta = () => {
    dispatch(resetMeta());
  };

  const handleSignOut = () => {
    signOut();
    navigate('/sign-in', { replace: true });
  };
  return (
    <Stack sx={{ height: '100%' }}>
      <Scrollbar sx={{ maxHeight: '100%' }}>
        <Box sx={{ marginTop: '6rem' }}>
          {menuContent.map(
            ({ label, icon, to, items, hidden }: MenuContent, index: number) =>
              !hidden && (
                <StyledAccordion
                  expand={expand}
                  disableGutters
                  key={index}
                  expanded={expanded === label}
                  onChange={handleChange(label)}
                  sx={{ boxShadow: 'none' }}
                  style={{ backgroundColor: '#4A90FF' }}
                >
                  <AccordionSummary
                    sx={{ padding: 0, paddingRight: '1rem', margin: 0, backgroundColor: 'transparent' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <StyledListItemButton
                      expand={expand}
                      color="secondary"
                      disableRipple
                      sx={
                        matchSizeSmall
                          ? { maxWidth: '180px', padding: '0', margin: 0, backgroundColor: 'transparent' }
                          : {}
                      }
                      hidden={hidden}
                      active={expanded === label}
                      onClick={() => {
                        handleNavigate(to);
                      }}
                    >
                      <StyledListItemIcon sx={matchSizeSmall ? { minWidth: 30 } : {}}>{icon}</StyledListItemIcon>
                      <Fade in={expand}>
                        <ListItemText sx={{ color: 'white', textTransform: 'capitalize' }} primary={label} />
                      </Fade>
                    </StyledListItemButton>
                  </AccordionSummary>

                  <AccordionDetails sx={{ padding: '0' }}>
                    <List component="div" disablePadding>
                      {items.map((item, index) => (
                        <React.Fragment key={index}>
                          <StyledBox active={activeNavButton === item.to} display={item.hidden ? 'none' : 'auto'}>
                            <StyledListSubItemButton
                              centerRipple
                              sx={matchSizeSmall ? { maxWidth: '180px' } : { height: '68px' }}
                              onClick={() => {
                                handleNavigate(item.to);
                                handleResetMeta();
                                setActiveNavButton(item.to);
                                matchSizeSmall ? onSelection() : null;
                              }}
                            >
                              <ListItemText style={{ color: 'white' }} primary={item.label} />
                            </StyledListSubItemButton>
                          </StyledBox>
                        </React.Fragment>
                      ))}
                    </List>
                  </AccordionDetails>
                </StyledAccordion>
              ),
          )}
        </Box>
        <Box sx={{ marginTop: '38px' }}>
          <StyledAccordionNoLine
            disableGutters
            sx={{ boxShadow: 'none' }}
            style={{ backgroundColor: '#4A90FF', border: 'none' }}
          >
            <AccordionSummary
              sx={{ padding: 0, paddingRight: '1rem', margin: 0, backgroundColor: 'transparent' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <StyledListItemButton
                expand={expand}
                color="secondary"
                disableRipple
                hidden={false}
                active={expanded === false}
                sx={
                  matchSizeSmall ? { maxWidth: '180px', padding: '0', margin: 0, backgroundColor: 'transparent' } : {}
                }
                onClick={() => {
                  handleNavigate('/');
                }}
              >
                <StyledListItemIcon sx={matchSizeSmall ? { minWidth: 3 } : {}}>
                  <HelpIcon />
                </StyledListItemIcon>
                <Fade in={expand}>
                  <ListItemText
                    style={{ color: 'white', textTransform: 'capitalize' }}
                    primary="FAQ"
                    onClick={() => goToConfluence()}
                  />
                </Fade>
              </StyledListItemButton>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: '0' }}>
              <List component="div" disablePadding></List>
            </AccordionDetails>
          </StyledAccordionNoLine>
          <StyledAccordionNoLine
            disableGutters
            sx={{ boxShadow: 'none' }}
            style={{ backgroundColor: '#4A90FF', border: 'none' }}
          >
            <AccordionSummary
              sx={{ padding: 0, paddingRight: '1rem', margin: 0, backgroundColor: 'transparent' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <StyledListItemButton
                expand={expand}
                color="secondary"
                disableRipple
                hidden={false}
                active={expanded === false}
                sx={
                  matchSizeSmall ? { maxWidth: '180px', padding: '0', margin: 0, backgroundColor: 'transparent' } : {}
                }
                onClick={handleSignOut}
              >
                <StyledListItemIcon sx={matchSizeSmall ? { minWidth: 3 } : {}}>
                  <img src={Logout} style={{ width: '30px' }} />
                </StyledListItemIcon>
                <Fade in={expand}>
                  <ListItemText style={{ color: 'white', textTransform: 'capitalize' }} primary="Salir" />
                </Fade>
              </StyledListItemButton>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: '0' }}>
              <List component="div" disablePadding></List>
            </AccordionDetails>
          </StyledAccordionNoLine>
        </Box>
      </Scrollbar>
    </Stack>
  );
};

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'hidden' && prop !== 'expand',
})(({ theme, hidden, expand }: { theme?: Theme; active: boolean; hidden: boolean | undefined; expand: boolean }) => ({
  paddingRight: theme?.spacing(5),
  paddingLeft: '13px',
  marginLeft: expand ? theme?.spacing(5) : 0,
  backgroundColor: 'transparent',
  display: hidden ? 'none' : 'auto',
  borderRadius: '10px',
  width: expand ? '' : '64px',
  transition: 'background-color 0.5s ease',
  '&:hover': {
    backgroundColor: expand ? '#0E48A2' : 'transparent',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(() => ({
  borderRadius: '40px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    display: 'none',
  },
}));

const StyledListSubItemButton = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiTouchRipple-child': {
    backgroundColor: `${theme?.palette.primary.main} !important`,
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  color: 'white',
  minWidth: 'auto',
  marginRight: '1.5rem',
  borderRadius: '8px',
  padding: '0.3rem',
  '& svg': {
    minWidth: '25px',
    minHeight: '25px',
  },
  '&:hover': {
    backgroundColor: '#0E48A2',
  },
}));

const StyledAccordion = styled(Accordion, { shouldForwardProp: props => props !== 'expand' })(
  ({ expand }: { expand: boolean }) => ({
    '&:before': {
      backgroundColor: '#A7A8A9',
      padding: '0 1rem',
      top: '-1px',
      left: expand ? '20px' : 0,
      right: expand ? '20px' : 0,
    },
  }),
);

const StyledAccordionNoLine = styled(Accordion)(() => ({
  '&:before': {
    display: 'none',
  },
}));

const StyledBox = styled(Box, { shouldForwardProp: prop => prop !== 'active' })(
  ({ theme, active }: { theme?: Theme; active: boolean }) => ({
    backgroundColor: active ? alpha(`${theme?.palette.primary.main}`, 0.08) : 'transparent',

    '&:hover': {
      backgroundColor: alpha(`${theme?.palette.primary.main}`, 0.08),
    },
  }),
);

export default ControlledAccordions;
