import { ThemeOptions } from '@mui/material';
import { MuiButton } from './MuiButton';
import { MuiCssBaseline } from './MuiCssBaseline';
import { MuiTextField } from './MuiTextField';
import { MuiTooltip } from './MuiTooltip';
import { MuiCard } from './MuiCard';
import { MuiContainer } from './MuiContainer';
import { MuiTab } from './MuiTab';
import { MuiPopover } from './MuiPopover';
import { MuiDialog } from './MuiDialog';
import { MuiSwitch } from './MuiSwitch';
import { MuiCheckbox } from './MuiCheckbox';
import { MuiFormControl } from './MuiFormControl';
import { MuiPaper } from './MuiPaper';
import { MuiAlert } from './MuiAlert';

export const components: ThemeOptions['components'] = {
  MuiButton,
  MuiCssBaseline,
  MuiTextField,
  MuiTooltip,
  MuiCard,
  MuiContainer,
  MuiTab,
  MuiPopover,
  MuiDialog,
  MuiSwitch,
  MuiCheckbox,
  MuiFormControl,
  MuiAlert,
  MuiPaper,
};
