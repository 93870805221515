import { Components } from '@mui/material';

export const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    inputProps: {
      autoComplete: 'off',
    },
  },
  styleOverrides: {
    root: {
      '& fieldset': {
        borderRadius: '5px',
      },
    },
  },
  variants: [
    {
      props: { variant: 'standard', disabled: true },
      style: {
        '& .MuiInputBase-root.Mui-disabled:before': {
          borderBottomStyle: 'solid',
        },
      },
    },
  ],
};
