import { alpha, Components } from '@mui/material';
import foundations from '../foundations';

export const MuiTooltip: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: {
      backgroundColor: alpha(foundations.colors.brandColors.mediumGrey, 0.9),
    },
  },
};
