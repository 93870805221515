import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from 'modules/shared/interfaces/shared.interface';
import { RoleInterface, RoleSubItemsInterface } from 'modules/users/interfaces/users.interface';
import { Ability, AbilityConfig } from 'modules/auth/AuthProvider.component';
import { emptyRoleModel } from 'modules/users/interfaces/users.interface';
const configPermisos = [
  {
    id: 'only_clients_who_belongs',
    name: 'Solo clientes de los que forma parte',
  },
  {
    id: 'only_campaigns_who_belongs',
    name: 'Solo campañas de las que forma parte',
  },
  {
    id: 'only_users_from_teams_who_belongs',
    name: 'Solo usuarios en equipos de los que forma parte',
  },
  {
    id: 'see_all_charged_licenses',
    name: 'Puede ver todas las licencias cargadas',
  },
  {
    id: 'only_own_metrics',
    name: 'Solo metricas propias',
  },
  {
    id: 'only_own_licenses',
    name: 'Solo licencias propias',
  },
  {
    id: 'charge_license_on_behalf_of_someone_else',
    name: 'Puede cargar en representación de otro',
  },
];
export interface RolesStoreInterface {
  loading: boolean;
  roles: RoleInterface[];
  selectedRole: RoleInterface;
  createdRole: RoleInterface;
  createdRoleSubItems: RoleSubItemsInterface[];
  meta: Meta;
  abilities: Ability[];
  abilitiesConfigOptions: AbilityConfig[];
}

const initialState: RolesStoreInterface = {
  loading: false,
  roles: [],
  selectedRole: emptyRoleModel,
  createdRole: emptyRoleModel,
  createdRoleSubItems: [],
  meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
  abilities: [],
  abilitiesConfigOptions: configPermisos,
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles(state, action: PayloadAction<RoleInterface[]>) {
      state.roles = action.payload;
    },
    setSelectedRole(state, action: PayloadAction<RoleInterface>) {
      state.selectedRole = action.payload;
    },
    setCreatedRoleSubItems(state, action: PayloadAction<RoleSubItemsInterface[]>) {
      state.createdRoleSubItems = action.payload;
    },
    setCreatedRole(state, action: PayloadAction<RoleInterface>) {
      state.createdRole = action.payload;
    },
    resetSelectedRole(state) {
      state.selectedRole = emptyRoleModel;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
    setPermissions(state, action: PayloadAction<Ability[]>) {
      state.abilities = action.payload;
    },
  },
});

export const rolesActions = rolesSlice.actions;

export default rolesSlice;
