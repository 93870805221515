import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, styled, TextField, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useInboundCalculator } from 'modules/core/providers/InboundCalculator.provider';
import { useEffect, useState } from 'react';

interface BoxTextFieldProps {
  text: string;
  textStrong: string;
  medium?: boolean | undefined;
}

interface SaveResultsInboundProps {
  modal: boolean;
  isSaving: boolean;
  errorMessage: string;
  onCloseModal: () => void;
  onConfirm: (name: string) => void;
}

const BoxTextField = ({ text, textStrong, medium }: BoxTextFieldProps) => {
  return (
    <>
      <Text>
        {text} <TextBold medium={medium}>{textStrong}</TextBold>
      </Text>
    </>
  );
};

const SaveResultsInbound = ({ onCloseModal, modal, errorMessage, isSaving, onConfirm }: SaveResultsInboundProps) => {
  const [name, setName] = useState('');
  const [isNameValid, setIsNameValid] = useState(true);
  const { selectedRowData } = useInboundCalculator();

  const normalizePercentage = (value = 0, decimals = 1) => {
    return parseFloat(Number(value * 100).toFixed(decimals));
  };

  useEffect(() => {
    name.length >= 100 ? setIsNameValid(false) : setIsNameValid(true);
  }, [name]);

  return (
    <Modal sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} open={modal} onClose={onCloseModal}>
      <Container>
        <IconClose onClick={onCloseModal} />
        <Title variant="h6">Guardar resultados de Calculadora Inbound</Title>
        <Box sx={{ margin: '32px 0px' }}>
          <TextField
            variant="outlined"
            type="text"
            label="Nombre"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
            helperText={
              !isNameValid ? 'El nombre no debe superar los 100 caracteres' : errorMessage ? errorMessage : ' '
            }
            error={!!errorMessage || !isNameValid}
          />
        </Box>
        <Box sx={{ margin: '20px 0', fontSize: '14px' }}>
          <Typography sx={{ color: '#4A90FF' }}>RESULTADO SELECCIONADO:</Typography>
        </Box>

        <ContainerTextFields>
          <BoxTextField text={'Agentes: '} textStrong={String(selectedRowData?.raw_positions)} medium={true} />
          <BoxTextField text={'S/contracción: '} textStrong={String(selectedRowData?.agents)} medium={true} />
          <BoxTextField
            text={'Nivel Servicio: '}
            textStrong={`${normalizePercentage(selectedRowData?.service_level_percentage)}%`}
            medium={true}
          />
        </ContainerTextFields>

        <ContainerTextFields>
          <BoxTextField text={'Ocupación: '} textStrong={`${normalizePercentage(selectedRowData?.occupancy)}%`} />
          <BoxTextField
            text={'Tasa Abandono: '}
            textStrong={`${normalizePercentage(selectedRowData?.abandon_rate_percentage, 2)}%`}
          />
        </ContainerTextFields>

        <ContainerTextFields>
          <BoxTextField
            text={'Respuesta inmediata: '}
            textStrong={`${normalizePercentage(selectedRowData?.answered_immediately_percentage)}%`}
          />
          <BoxTextField
            text={'ASA (S): '}
            textStrong={String(parseFloat(Number(selectedRowData?.asa || 0).toFixed(2)))}
          />
        </ContainerTextFields>

        <ContainerTextFields>
          <BoxTextField text={'Campaña: '} textStrong={String(selectedRowData?.name)} />
        </ContainerTextFields>

        <ContainerButton>
          <Button onClick={onCloseModal}>CANCELAR</Button>
          <Button variant="contained" color="primary" onClick={() => onConfirm(name)} disabled={isSaving || !name}>
            CONFIRMAR
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default SaveResultsInbound;

interface PropsText {
  medium: boolean | undefined;
  theme?: Theme;
}

const ContainerButton = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 7,
  margin: '20px 0',
});

const Title = styled(Typography)(() => ({
  fontSize: '24px',
}));

const Container = styled(Box)({
  borderRadius: '16px',
  background: 'white',
  padding: '15px 20px',
  position: 'relative',
  width: 678,
});

const IconClose = styled(CloseIcon)(() => ({
  cursor: 'pointer',
  position: 'absolute',
  top: 10,
  right: 10,
}));

const ContainerTextFields = styled('div')(() => ({
  background: '#E9F2FF',
  borderRadius: '4px',
  margin: '16px 0',
  padding: '8px',
  display: 'flex',
  justifyContent: 'space-around',
}));

const TextBold = styled(Typography, {
  shouldForwardProp: prop => prop !== 'medium',
})(({ theme, medium }: PropsText) => ({
  fontWeight: 'bold',
  fontSize: medium ? '24px' : '16px',
  color: theme?.palette.grey[900],
}));

const Text = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 5,
}));
