import { ReactNode } from 'react';
import { styled } from '@mui/material';
import { CSVLink } from 'react-csv';

interface DownloadCSVLinkProps {
  data: any;
  headers: any;
  children: ReactNode;
  filename?: string;
  disabled?: boolean;
  hidden?: boolean;
}

export const DownloadCSVLink = ({ data, headers, disabled, children, filename, hidden }: DownloadCSVLinkProps) => {
  return (
    <StyledCSVLink
      sx={{ display: hidden ? 'none' : 'auto' }}
      data={data}
      headers={headers}
      onClick={(e: any) => {
        if (disabled) e.preventDefault();
      }}
      filename={filename}
    >
      {children}
    </StyledCSVLink>
  );
};

const StyledCSVLink = styled(CSVLink)({
  textDecoration: 'none',
});
