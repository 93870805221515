import { Dispatch, SetStateAction } from 'react';
import List from '@mui/material/List';
import { styled } from '@mui/material';

//import { theme } from 'modules/mui/theme';
import ControlledAccordions from '../accordion/Accordion.component';
interface MenuListProps {
  expand: boolean;
  onSelection: () => void;
  setExpand?: Dispatch<SetStateAction<boolean>>;
}

const MenuList = ({ expand, onSelection, setExpand }: MenuListProps) => {
  //const matchSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledMenuList>
      <ControlledAccordions setExpand={setExpand} expand={expand} onSelection={onSelection} />
    </StyledMenuList>
  );
};

const StyledMenuList = styled(List)({
  padding: 0,
  minWidth: 256,
  height: 'calc(100vh - 80px)',
});

export default MenuList;
