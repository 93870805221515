export const parseSessionTime = (time: string) => {
  if (time && typeof time === 'string') {
    const timeValue = Number(time.substring(0, time.length - 1));
    const timeUnit = time[time.length - 1];
    let millisecondsValue = 0;
    switch (timeUnit) {
      case 's':
        millisecondsValue = timeValue * 1000;
        break;
      case 'm':
        millisecondsValue = timeValue * 1000 * 60;
        break;
      case 'h':
        millisecondsValue = timeValue * 1000 * 60 * 60;
        break;
      default:
        break;
    }
    return millisecondsValue;
  }
  return 0;
};
