import { format } from 'date-fns';
import { dateOnMMDDYYYY } from 'utils';

export const createCampaignAdapter = (data: any) => {
  return data;
};

export const payloadCreateCampaignAdapter = (data: any) => {
  const parsedData = { ...data, start_date: format(new Date(dateOnMMDDYYYY(data.start_date)), 'yyyy-MM-dd') };
  return parsedData;
};
