import { styled, TextField } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

const TextFieldController = ({
  name = '',
  control,
  rules,
  //   helperText,
  // label,
  type,
  required,
  autocomplete,
  defaultValue,
  disabled,
  setError,
}: Partial<
  ControllerProps & {
    label: string;
    helperText?: string;
    type: string;
    required?: boolean;
    autocomplete: string;
    defaultValue: string;
    disabled: boolean;
    setError: Dispatch<SetStateAction<{ error: { message: string } }>> | any;
  }
>) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        if (error) {
          setError(error?.message);
        }

        return (
          <Input
            variant="outlined"
            onChange={e => onChange(Number(e.target.value))}
            value={value === undefined ? defaultValue : value}
            required={required}
            defaultValue={defaultValue}
            // label={label}
            helperText={error?.message}
            error={Boolean(error)}
            type={type}
            autoComplete={autocomplete}
            fullWidth
            disabled={disabled}
            autoFocus
          />
        );
      }}
    />
  );
};

export default TextFieldController;

const Input = styled(TextField)(() => ({
  width: '80px',
  '& .MuiFormHelperText-root': {
    margin: 0,
  },
}));
