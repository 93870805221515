import { Components } from '@mui/material';

export const MuiCard: Components['MuiCard'] = {
  styleOverrides: {
    root: {
      position: 'relative',
      boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)',
      borderRadius: '16px',
      zIndex: 0, // Fix Safari overflow: hidden with border radius
    },
  },
};
