import { Ability, Permissions } from 'modules/auth/AuthProvider.component';
import { UserDashboardInterface } from 'modules/home/interfaces';

export interface UserInterface {
  created_at: string;
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  platform_id: string | null;
  status: boolean;
  role: RoleInterface;
}
export interface CampaignUser {
  id: string;
  name: string;
}
export interface IOptions {
  label: string;
  value: string;
  type: string;
}
export interface NewUserInterface extends Pick<UserInterface, 'username' | 'platform_id'> {
  password: string;
  role: string | UserInterface['role'];
}

export interface UpdateUserInterface extends Pick<NewUserInterface, 'username' | 'platform_id' | 'role'> {
  password?: string;
}

export interface UserSelectedInterface extends UserInterface {
  status: boolean;
  campaigns: CampaignUser[];
  email: string;
}

export enum RoleStates {
  ACTIVE = 'Activado',
  INACTIVE = 'Desactivado',
}
export interface RoleInterface {
  id?: string;
  name: string;
  description?: string;
  created_at?: string;
  created_by?: string;
  deleted_at?: string;
  deleted_by?: string;
  configurator_access?: boolean;
  only_campaigns_who_belongs?: boolean;
  only_clients_who_belongs?: boolean;
  see_all_charged_licenses?: boolean;
  only_own_metrics?: boolean;
  only_users_from_teams_who_belongs?: boolean;
  charge_license_on_behalf_of_someone_else?: boolean;
  only_own_licenses?: boolean;
  status?: boolean;
  updated_at?: string;
  updated_by?: string;
  permissions?: Ability[];
}

export interface RoleSubItemsInterface {
  name: string;
  length: number;
}

export const emptyRoleModel: RoleInterface = {
  id: '',
  name: '',
  description: '',
  permissions: [],
  only_campaigns_who_belongs: false,
  only_clients_who_belongs: false,
  see_all_charged_licenses: false,
  only_own_metrics: false,
  only_users_from_teams_who_belongs: false,
  charge_license_on_behalf_of_someone_else: false,
  status: false,
};

export const emptyUserModel: UserInterface = {
  created_at: '',
  id: '',
  username: '',
  firstname: '',
  lastname: '',
  platform_id: '',
  status: false,
  role: emptyRoleModel,
};

export const emptyDashboardUserModel: UserDashboardInterface = {
  created_at: '',
  id: '',
  username: '',
  firstname: '',
  lastname: '',
  platform_id: '',
  status: false,
  role: emptyRoleModel,
  team: undefined,
};

export const emptyNewUserModel: NewUserInterface = {
  username: '',
  platform_id: '',
  password: '',
  role: '',
};

export const emptyUserSelectedModel: UserSelectedInterface = {
  created_at: '',
  id: '',
  username: '',
  email: '',
  firstname: '',
  lastname: '',
  platform_id: '',
  role: emptyRoleModel,
  status: false,
  campaigns: [],
};

export enum ConfigTabDisplay {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum TypeOfSaves {
  CREATE = 'create',
  EDIT = 'edit',
}

export interface Campaign {
  name: string;
  id: string;
}
export interface TimelineViewInterface {
  name: string;
  subItems: Permissions[];
}

export interface UserFormProps {
  sendForm: boolean;
  configTabDisplay: ConfigTabDisplay;
  handleAction: (action: string) => void;
  saveForm: (form: NewUserInterface | UpdateUserInterface, type: TypeOfSaves) => void;
  setFormWithoutSatusChanged: (formWithoudStatusChanged: boolean) => void;
  setSendForm: (save: boolean) => void;
}
