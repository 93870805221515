import React, { RefObject, createContext, useContext } from 'react';

interface ConfigurationContextType {
  LicenseformRef: RefObject<HTMLButtonElement>;
  validateForm: () => void;
}

export const useConfigurationContext = () => {
  const context = useContext(ConfigurationContext);
  if (!context) throw new Error('useConfigurationContext must be use inside ConfigurationContextProvider');

  return context;
};

export const ConfigurationContext = createContext<ConfigurationContextType>({
  LicenseformRef: React.createRef<HTMLButtonElement>(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  validateForm: () => {},
});

export const ConfigurationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const LicenseformRef = React.useRef<HTMLButtonElement>(null);

  const validateForm = () => {
    LicenseformRef.current?.click();
  };

  return (
    <ConfigurationContext.Provider value={{ LicenseformRef, validateForm }}>{children}</ConfigurationContext.Provider>
  );
};
